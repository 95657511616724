import React from 'react';
import { withRouter } from 'react-router-dom';
import _get from 'lodash.get';
class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    const oldPath = _get(prevProps, 'location.path', '');
    const newPath = _get(this.props, 'location.path', '');
    if (oldPath !== newPath) {
      //console.log('scrolling to top', this.props.location, prevProps.location);
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
