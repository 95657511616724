import axios from 'axios';
import { history } from './history';
import { AUTH_LOGOUT } from '../redux/Auth';
//import { ERROR } from '../redux/Error';
import {
  logout,
  getAuthToken,
  getXTag,
  getXRole,
} from '../components/connect-react-lib';
import { setupKeyApi } from './utils';
import { xAuthMode } from '../components/connect-react-lib/AppRunMode';
export let LivionPublicKeyApi = axios.create({
  baseURL: setupKeyApi(),
});
export let LivionKeyApi = axios.create({
  baseURL: setupKeyApi(),
});

const networkService = {
  setupInterceptors: (store) => {
    LivionKeyApi.interceptors.request.use(async (config) => {
      config.headers.authorization = `Bearer ${await getAuthToken()}` || null;
      config.headers['x-tag'] = getXTag();
      config.headers['x-role'] = getXRole();
      config.headers['x-local-auth'] =
        xAuthMode === 'localAuth' ? '1' : undefined;

      return config;
    });
    LivionKeyApi.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          console.log('NetworkService - Session expired');
          logout();
          store.dispatch({ type: AUTH_LOGOUT });
          history.push('/login');
        }
        // if (error.response && error.response.status === 500) {
        //   console.log('NetworkService - Internal Error');
        //   store.dispatch({ type: ERROR, data: { code: '500', message: 'Internal Key Api Error' } });
        //   history.push('/error');
        // }

        return Promise.reject(error);
      }
    );
  },
};
export default networkService;
