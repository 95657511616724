import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const setAuthTokenToCookie = (idToken: string | undefined) => {
  const now = new Date();
  const exp = new Date(now.getTime() + 3600 * 1000); // expire in one hour
  if (idToken) {
    cookies.set('auth', idToken, {
      domain: document.domain,
      expires: exp,
      path: '/',
    });
  } else {
    cookies.remove('auth', { path: '/', domain: document.domain });
  }
};
