import gql from 'graphql-tag';

export const alarmInfoFragment = gql`
  fragment alarmInfo on Alarm {
    id
    ruleId
    start
    end
    ack {
      user
      comment
      timestamp
    }
    source
    sourceId
    name
    tag
    priority
    deviceName
  }
`;

export const alarmEventHistoryFragment = gql`
  fragment alarmEventHistory on Alarm {
    eventHistory {
      time
      event {
        id
        source
        sourceId
        timestamp
        action
        tag
        schedule
        data {
          text
          value
          sourceTime
          before
          after
        }
      }
    }
  }
`;

export const alarmRoutingHistoryFragment = gql`
  fragment alarmRoutingHistory on Alarm {
    routingHistory {
      routingId
      type
      state
      time
      from
      to
      text
      subject
      lang
      status
      errorMessage
      errorCode
    }
  }
`;
