import gql from 'graphql-tag';

const TIMELINES_FETCHED = 'timelines/FETCHED';
const TIMELINES_LOADING = 'timelines/LOADING';
const TIMELINES_FAILED = 'timelines/FAILED';
const TIMELINES_RESET = 'timelines/RESERVATIONS_RESET';

export const reset = () => (dispatch) => dispatch({ type: TIMELINES_RESET });

export const getTimeline = (client, docId) => async (dispatch) => {
  dispatch({ type: TIMELINES_LOADING });
  try {
    const res = await client.query({
      query: gql`
        query timeline($id: ID!) {
          timeline(id: $id) {
            title
            anonymized
            uuid
            id
            deviceId
            start
            events {
              text
              sourceTime
              attachment {
                url
                mimetype
              }
              after
            }
          }
        }
      `,
      variables: { id: docId },
    });

    dispatch({ type: TIMELINES_FETCHED, data: res.data.timeline });
    return res.data.timeline;
  } catch (err) {
    dispatch({ type: TIMELINES_FAILED, data: err.message });
    throw err;
  }
};
export const getTimelineByUuid = (client, uuid) => async (dispatch) => {
  dispatch({ type: TIMELINES_LOADING });
  try {
    const res = await client.query({
      query: gql`
        query timelineByUuid($uuid: String!) {
          timelineByUuid(uuid: $uuid) {
            title
            anonymized
            uuid
            id
            deviceId
            start
            events {
              text
              sourceTime
              attachment {
                url
                mimetype
              }
              after
            }
          }
        }
      `,
      variables: { uuid },
    });

    dispatch({ type: TIMELINES_FETCHED, data: res.data.timelineByUuid });
    return res.data.timelineByUuid;
  } catch (err) {
    dispatch({ type: TIMELINES_FAILED, data: err.message });
    throw err;
  }
};

const initialState = {
  device: {},
  timeline: {},
  loading: false,
  error: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case TIMELINES_LOADING:
      return {
        ...state,
        loading: true,
      };
    case TIMELINES_FETCHED:
      return {
        ...state,
        loading: false,
        timeline: action.data,
      };
    case TIMELINES_RESET:
      return {
        ...state,
        timeline: {},
        device: {},
        loading: false,
      };
    case TIMELINES_FAILED:
      return {
        ...state,
        error: action.data,
        loading: false,
      };
    default:
      return state;
  }
}
