import gql from 'graphql-tag';
import {
  alarmInfoFragment,
  alarmEventHistoryFragment,
  alarmRoutingHistoryFragment,
} from '../fragments/alarms';
import { pageInfoFragment } from '../fragments/common';

export const GET_ALARM_RULES = gql`
  query publicAlarmRules($filter: AlarmRuleFilter) {
    publicAlarmRules(filter: $filter, limit: 0) {
      edges {
        id
        alarmName
      }
    }
  }
`;
export const GET_ALARMS = gql`
  query alarms($after: String, $limit: Int, $filter: AlarmFilter, $sort: JSON) {
    alarms(after: $after, limit: $limit, filter: $filter, sort: $sort) {
      edges {
        ...alarmInfo
        ...alarmEventHistory
        ...alarmRoutingHistory
      }
      pageInfo {
        ...pageInfo
      }
    }
  }
  ${alarmInfoFragment}
  ${alarmEventHistoryFragment}
  ${alarmRoutingHistoryFragment}
  ${pageInfoFragment}
`;
