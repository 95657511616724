import { LivionPublicKeyApi } from '../utils/networkService';

export const BUSINESS_FOUND = 'UI/BUSINESS';
export const MAINTENANCE_MESSAGE_FETCHED = 'ui/MAINTENANCE_MESSAGE_FETCHED';
export const MAINTENANCE_MESSAGE_LOADING = 'ui/MAINTENANCE_MESSAGE_LOADING';
export const MAINTENANCE_MESSAGE_FAILED = 'ui/MAINTENANCE_MESSAGE_FAILED';

export const getMaintenanceMessage = () => (dispatch) => {
  dispatch({ type: MAINTENANCE_MESSAGE_LOADING });
  return LivionPublicKeyApi.get(`/maintenance`)
    .then((res) => {
      dispatch({
        type: MAINTENANCE_MESSAGE_FETCHED,
        data: res.data,
      });
      return res.data;
    })
    .catch((err) => {
      dispatch({ type: MAINTENANCE_MESSAGE_FAILED, data: err.response });
      throw err;
    });
};
const initialState = {
  message: null,
  business: 'key',
};
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case MAINTENANCE_MESSAGE_FETCHED:
      return {
        ...state,
        message: action.data,
      };
    case MAINTENANCE_MESSAGE_FAILED:
      return {
        ...state,
        error: action.data,
      };
    case BUSINESS_FOUND:
      return {
        ...state,
        business: action.data,
      };

    default:
      return state;
  }
}
