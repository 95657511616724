import moment from 'moment-timezone';
import has from 'lodash/has';
import set from 'lodash/set';

const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
export function convertContractsToDeviceLocalTime(settings) {
  if (settings) {
    let keys;
    const timezone = (settings && settings.tz) || 'Europe/Helsinki';

    keys = settings.keys || [];

    keys.forEach((k) => {
      const contracts = k.contracts || [];
      contracts.forEach((c) => {
        c.start = moment.tz(c.start, timezone);

        c.end = moment.tz(c.end, timezone);
      });
    });
    settings.keys = keys;
  }

  return settings;
}

export function templateReplace(template, data) {
  return template.replace(/\{\{(.*?)\}\}/g, (m, key) => {
    if (key.indexOf('$') > 0) {
      const s = key.split('$');

      return data.hasOwnProperty(s[0])
        ? s[0] === 'end' && data[s[0]] === null
          ? ''
          : data[s[0]].format(s[1])
        : '';
    }
    return data.hasOwnProperty(key) ? data[key] : '';
  });
}

export function contractFormat(keyId, keyName, values, timezone, t) {
  console.log('contractFormat', values);
  return {
    keyName,
    contractId: values.contractId,
    start: moment
      .tz(values.daterange.startDate, timezone)
      .startOf('Day')
      .add(values.startHour.value || values.startHour, 'hours'),
    keyId,
    pincode: 'XXXXXX',
    person: 'XXXXXX',
    end:
      values.daterange.endDate && !values.perm
        ? moment
            .tz(values.daterange.endDate, timezone)
            .startOf('Day')
            .add(values.endHour.value || values.endHour, 'hours')
        : null,
    freetext: values.freeText || '',
  };
}
const getRecurrentReadable = (recurrent, lang) => {
  return recurrent
    .map(
      (r) =>
        `${r.startTime && r.startTime.value}-${
          r.endTime && r.endTime.value
        }: ${Object.keys(r.days)
          .map((k) => (r.days[k] === true ? dayList[lang][k] : null))
          .filter((d) => d)
          .join('/')}`
    )

    .join(' & ');
};
export function contractAccessFormat(
  locationName,
  values,
  timezone,
  t,
  useBasicAccessRight = false
) {
  const startDate =
    (values.daterange && values.daterange.startDate) || values.start;
  const startHour = values?.startHour?.value || values?.startHour;
  const endDate = (values.daterange && values.daterange.endDate) || values.end;
  const endHour = values?.endHour?.value || values?.endHour;
  return {
    locationName,
    contractId: values.contractId,
    start: useBasicAccessRight
      ? moment.tz(startDate, timezone).startOf('Day').add(startHour, 'hours')
      : moment.tz(startDate, timezone).startOf('Day'),
    pincode: 'XXXXXX',
    person: 'XXXXXX',
    end:
      endDate && !values.perm
        ? useBasicAccessRight
          ? moment.tz(endDate, timezone).startOf('Day').add(endHour, 'hours')
          : moment.tz(endDate, timezone).endOf('Day')
        : null,
    freetext: values.freeText || '',
    recurrent:
      values.recurrent &&
      getRecurrentReadable(
        values.recurrent,
        values.contacts[0].language.value || 'en-gb'
      ),
  };
}

export function getSmsLength(sms) {
  let count = 0;
  for (let i = 0; i < sms.length; i++) {
    if (sms[i] === '\\') {
      count += 2;
    } else {
      count += 1;
    }
  }
  return count;
}

export function validateNumber(phoneNumber, t) {
  let err = '';
  let number;
  if (phoneNumber && phoneNumber.replace(/[^\d]/g, '').length <= 5) {
    err = t('tooShortError');
  } else if (phoneNumber && phoneNumber.replace(/[^\d]/g, '').length >= 17) {
    err = t('tooLongError');
  } else if (phoneNumber) {
    try {
      number = phoneUtil.parse(phoneNumber);
      if (number && !phoneUtil.isValidNumber(number)) {
        err = t('Invalid phonenumber');
      } else {
        if (
          number &&
          !(
            phoneUtil.getNumberType(number) === 1 ||
            phoneUtil.getNumberType(number) === 2
          )
        ) {
          err = t('NoMobileError');
        }
      }
    } catch (e) {
      err = t('Invalid phonenumber');
    }
  }
  return err;
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function exportDeviceToCsv(filename, deviceData) {
  let csv = '';
  //creating the headers
  Object.keys(deviceData).forEach((action) => {
    csv += action.concat(',');
  });
  csv += '\n';
  //Adding data
  Object.keys(deviceData).forEach((action) => {
    csv += deviceData[action]
      ? deviceData[action].toString().concat(',')
      : '0'.concat(',');
  });
  //Adding csv header
  if (!csv.match(/^data:text\/csv/i)) {
    csv = 'data:text/csv;charset=utf-8,' + csv;
  }
  let link = document.createElement('a');
  link.setAttribute('href', encodeURI(csv));
  link.setAttribute('download', filename);
  document.body.append(link);
  link.click();
  document.body.removeChild(link);
}
export function exportDevicesToCsv(filename, devicesData) {
  let csv = '';
  //creating the headers
  csv += 'name,id'.concat(',');
  Object.keys(devicesData[0]).forEach((column) => {
    if (column !== 'devices') csv += column.concat(',');
  });
  csv += '\n';
  //Adding data
  devicesData.forEach((row) => {
    if (row.devices && row.devices.length > 0)
      row.devices.forEach((device) => {
        csv += device.name.replace(/,/g, '-').concat(',');
        csv += device.id.concat(',');
        csv += row.tag.concat(',');
        Object.keys(device).forEach((key) => {
          if (key !== 'name' && key !== 'id')
            csv += device[key]
              ? device[key].toString().concat(',')
              : '0'.concat(',');
        });
        csv += '\n';
      });
    //adding Total
    csv += 'Total'.concat(',');
    csv += 'Total'.concat(',');
    csv += row.tag.concat(',');
    Object.keys(row).forEach((key) => {
      if (key !== 'devices' && key !== 'tag')
        csv += row[key] ? row[key].toString().concat(',') : '0'.concat(',');
    });
    csv += '\n';
  });
  //Adding csv header
  if (!csv.match(/^data:text\/csv/i)) {
    csv = 'data:text/csv;charset=utf-8,' + csv;
  }

  let url = encodeURI(csv);
  console.log('url:', url);
  let link = document.createElement('a');
  document.body.appendChild(link);
  link.setAttribute('href', url);
  link.setAttribute('download', filename);
  link.click();
}

export const decodeQueryString = (queryString) => {
  const splitedQueryString = queryString.split('&');
  const decodedQueryString = {};

  for (const param of splitedQueryString) {
    let [key, value] = param.split('=');
    value = value ? (value === 'null' ? null : value) : '';
    if (key === 'limit' && value) value = Number(value);
    if (has(decodedQueryString, key)) {
      const currentValueForKey = decodedQueryString[key];
      if (!Array.isArray(currentValueForKey)) {
        set(decodedQueryString, key, [currentValueForKey, value]);
      } else {
        currentValueForKey.push(value);
      }
    } else {
      set(decodedQueryString, key, value);
    }
  }

  return decodedQueryString[''] === '' ? null : decodedQueryString;
};

export const encodeQueryString = function encode(queryObj, nesting = '') {
  const pairs = Object.entries(queryObj).map(([key, val]) => {
    if (!val) {
      return [nesting + key, 'null'].map(escape).join('=');
    } else if (Array.isArray(val)) {
      return val
        .map((subVal) => [nesting + key, subVal].map(escape).join('='))
        .join('&');
    } else if (typeof val === 'object') {
      return encode(val, nesting + `${key}.`);
    } else {
      return [nesting + key, val].map(escape).join('=');
    }
  });
  return pairs.join('&');
};

export const setQueryString = (history, queryString) => {
  history.replace(`${history.location.pathname}?${queryString}`);
};

export let ruleList = [
  { value: '5b9b7a1b995fc900077a17f0', label: 'Device error' },
  { value: '5b8cf706c9521a0006f9664c', label: 'Device disconnected' },
];
export let notificationList = [];

export const setupConnectApi = () => {
  if (process.env.REACT_APP_CONNECTAPI) {
    return process.env.REACT_APP_CONNECTAPI;
  } else {
    return `${window.location.protocol}//${window.location.host}/api`;
  }
};
export const setupKeyApi = () => {
  if (window.Cypress) {
    console.log(
      'Cypress env',
      window.Cypress.env().REACT_APP_LIVIONKEY_API_URI
    );
    return window.Cypress.env().REACT_APP_LIVIONKEY_API_URI;
  }
  if (process.env.REACT_APP_LIVIONKEY_API_URI) {
    return process.env.REACT_APP_LIVIONKEY_API_URI;
  } else {
    return `${window.location.protocol}//${window.location.host}/keyapi`;
  }
};

export let languageList = (t) => ({
  en: { value: 'en-gb', label: t('en-gb') },
  'en-gb': { value: 'en-gb', label: t('en-gb') },
  fi: { value: 'fi-fi', label: t('fi-fi') },
  'fi-fi': { value: 'fi-fi', label: t('fi-fi') },
  se: { value: 'swe-swe', label: t('swe-swe') },
  'swe-swe': { value: 'swe-swe', label: t('swe-swe') },
  da: { value: 'da-da', label: t('da-da') },
  'da-da': { value: 'da-da', label: t('da-da') },
  no: { value: 'no-no', label: t('no-no') },
  nb: { value: 'no-no', label: t('no-no') },
  'no-no': { value: 'no-no', label: t('no-no') },
  de: { value: 'de', label: t('de') },
});

export let volumeList = (t) => [
  { value: 1, label: t('very low') },
  { value: 2, label: t('low') },
  { value: 3, label: t('normal') },
  { value: 4, label: t('high') },
  { value: 5, label: t('very high') },
];
export let movementSensitivitiesList = (t) => [
  { value: 'z030025', label: t('very low') },
  { value: 'z025020', label: t('low') },
  { value: 'z020015', label: t('normal') },
  { value: 'z015010', label: t('high') },
  { value: 'z010005', label: t('very high') },
];

export const times = [
  { value: 0, label: '00:00' },
  { value: 0.25, label: '00:15' },
  { value: 0.5, label: '00:30' },
  { value: 0.75, label: '00:45' },
  { value: 1, label: '01:00' },
  { value: 1.25, label: '01:15' },
  { value: 1.5, label: '01:30' },
  { value: 1.75, label: '01:45' },
  { value: 2, label: '02:00' },
  { value: 2.25, label: '02:15' },
  { value: 2.5, label: '02:30' },
  { value: 2.75, label: '02:45' },
  { value: 3, label: '03:00' },
  { value: 3.25, label: '03:15' },
  { value: 3.5, label: '03:30' },
  { value: 3.75, label: '03:45' },
  { value: 4, label: '04:00' },
  { value: 4.25, label: '04:15' },
  { value: 4.5, label: '04:30' },
  { value: 4.75, label: '04:45' },
  { value: 5, label: '05:00' },
  { value: 5.25, label: '05:15' },
  { value: 5.5, label: '05:30' },
  { value: 5.75, label: '05:45' },
  { value: 6, label: '06:00' },
  { value: 6.25, label: '06:15' },
  { value: 6.5, label: '06:30' },
  { value: 6.75, label: '06:45' },
  { value: 7, label: '07:00' },
  { value: 7.25, label: '07:15' },
  { value: 7.5, label: '07:30' },
  { value: 7.75, label: '07:45' },
  { value: 8, label: '08:00' },
  { value: 8.25, label: '08:15' },
  { value: 8.5, label: '08:30' },
  { value: 8.75, label: '08:45' },
  { value: 9, label: '09:00' },
  { value: 9.25, label: '09:15' },
  { value: 9.5, label: '09:30' },
  { value: 9.75, label: '09:45' },
  { value: 10, label: '10:00' },
  { value: 10.25, label: '10:15' },
  { value: 10.5, label: '10:30' },
  { value: 10.75, label: '10:45' },
  { value: 11, label: '11:00' },
  { value: 11.25, label: '11:15' },
  { value: 11.5, label: '11:30' },
  { value: 11.75, label: '11:45' },
  { value: 12, label: '12:00' },
  { value: 12.25, label: '12:15' },
  { value: 12.5, label: '12:30' },
  { value: 12.75, label: '12:45' },
  { value: 13, label: '13:00' },
  { value: 13.25, label: '13:15' },
  { value: 13.5, label: '13:30' },
  { value: 13.75, label: '13:45' },
  { value: 14, label: '14:00' },
  { value: 14.25, label: '14:15' },
  { value: 14.5, label: '14:30' },
  { value: 14.75, label: '14:45' },
  { value: 15, label: '15:00' },
  { value: 15.25, label: '15:15' },
  { value: 15.5, label: '15:30' },
  { value: 15.75, label: '15:45' },
  { value: 16, label: '16:00' },
  { value: 16.25, label: '16:15' },
  { value: 16.5, label: '16:30' },
  { value: 16.75, label: '16:45' },
  { value: 17, label: '17:00' },
  { value: 17.25, label: '17:15' },
  { value: 17.5, label: '17:30' },
  { value: 17.75, label: '17:45' },
  { value: 18, label: '18:00' },
  { value: 18.25, label: '18:15' },
  { value: 18.5, label: '18:30' },
  { value: 18.75, label: '18:45' },
  { value: 19, label: '19:00' },
  { value: 19.25, label: '19:15' },
  { value: 19.5, label: '19:30' },
  { value: 19.75, label: '19:45' },
  { value: 20, label: '20:00' },
  { value: 20.25, label: '20:15' },
  { value: 20.5, label: '20:30' },
  { value: 20.75, label: '20:45' },
  { value: 21, label: '21:00' },
  { value: 21.25, label: '21:15' },
  { value: 21.5, label: '21:30' },
  { value: 21.75, label: '21:45' },
  { value: 22, label: '22:00' },
  { value: 22.25, label: '22:15' },
  { value: 22.5, label: '22:30' },
  { value: 22.75, label: '22:45' },
  { value: 23, label: '23:00' },
  { value: 23.25, label: '23:15' },
  { value: 23.5, label: '23:30' },
  { value: 23.75, label: '23:45' },
];
export const stringTime = [
  { value: '00:00', label: '00:00' },
  { value: '00:15', label: '00:15' },
  { value: '00:30', label: '00:30' },
  { value: '00:45', label: '00:45' },
  { value: '01:00', label: '01:00' },
  { value: '01:15', label: '01:15' },
  { value: '01:30', label: '01:30' },
  { value: '01:45', label: '01:45' },
  { value: '02:00', label: '02:00' },
  { value: '02:15', label: '02:15' },
  { value: '02:30', label: '02:30' },
  { value: '02:45', label: '02:45' },
  { value: '03:00', label: '03:00' },
  { value: '03:15', label: '03:15' },
  { value: '03:30', label: '03:30' },
  { value: '03:45', label: '03:45' },
  { value: '04:00', label: '04:00' },
  { value: '04:15', label: '04:15' },
  { value: '04:30', label: '04:30' },
  { value: '04:45', label: '04:45' },
  { value: '05:00', label: '05:00' },
  { value: '05:15', label: '05:15' },
  { value: '05:30', label: '05:30' },
  { value: '05:45', label: '05:45' },
  { value: '06:00', label: '06:00' },
  { value: '06:15', label: '06:15' },
  { value: '06:30', label: '06:30' },
  { value: '06:45', label: '06:45' },
  { value: '07:00', label: '07:00' },
  { value: '07:15', label: '07:15' },
  { value: '07:30', label: '07:30' },
  { value: '07:45', label: '07:45' },
  { value: '08:00', label: '08:00' },
  { value: '08:15', label: '08:15' },
  { value: '08:30', label: '08:30' },
  { value: '08:45', label: '08:45' },
  { value: '09:00', label: '09:00' },
  { value: '09:15', label: '09:15' },
  { value: '09:30', label: '09:30' },
  { value: '09:45', label: '09:45' },
  { value: '10:00', label: '10:00' },
  { value: '10:15', label: '10:15' },
  { value: '10:30', label: '10:30' },
  { value: '10:45', label: '10:45' },
  { value: '11:00', label: '11:00' },
  { value: '11:15', label: '11:15' },
  { value: '11:30', label: '11:30' },
  { value: '11:45', label: '11:45' },
  { value: '12:00', label: '12:00' },
  { value: '12:15', label: '12:15' },
  { value: '12:30', label: '12:30' },
  { value: '12:45', label: '12:45' },
  { value: '13:00', label: '13:00' },
  { value: '13:15', label: '13:15' },
  { value: '13:30', label: '13:30' },
  { value: '13:45', label: '13:45' },
  { value: '14:00', label: '14:00' },
  { value: '14:15', label: '14:15' },
  { value: '14:30', label: '14:30' },
  { value: '14:45', label: '14:45' },
  { value: '15:00', label: '15:00' },
  { value: '15:15', label: '15:15' },
  { value: '15:30', label: '15:30' },
  { value: '15:45', label: '15:45' },
  { value: '16:00', label: '16:00' },
  { value: '16:15', label: '16:15' },
  { value: '16:30', label: '16:30' },
  { value: '16:45', label: '16:45' },
  { value: '17:00', label: '17:00' },
  { value: '17:15', label: '17:15' },
  { value: '17:30', label: '17:30' },
  { value: '17:45', label: '17:45' },
  { value: '18:00', label: '18:00' },
  { value: '18:15', label: '18:15' },
  { value: '18:30', label: '18:30' },
  { value: '18:45', label: '18:45' },
  { value: '19:00', label: '19:00' },
  { value: '19:15', label: '19:15' },
  { value: '19:30', label: '19:30' },
  { value: '19:45', label: '19:45' },
  { value: '20:00', label: '20:00' },
  { value: '20:15', label: '20:15' },
  { value: '20:30', label: '20:30' },
  { value: '20:45', label: '20:45' },
  { value: '21:00', label: '21:00' },
  { value: '21:15', label: '21:15' },
  { value: '21:30', label: '21:30' },
  { value: '21:45', label: '21:45' },
  { value: '22:00', label: '22:00' },
  { value: '22:15', label: '22:15' },
  { value: '22:30', label: '22:30' },
  { value: '22:45', label: '22:45' },
  { value: '23:00', label: '23:00' },
  { value: '23:15', label: '23:15' },
  { value: '23:30', label: '23:30' },
  { value: '23:45', label: '23:45' },
  { value: '23:59', label: '23:59' },
];

export const daysList = (t) => ({
  0: t('Mo'),
  1: t('Tu'),
  2: t('We'),
  3: t('Th'),
  4: t('Fr'),
  5: t('Sa'),
  6: t('Su'),
});

export const dayList = {
  'fi-fi': {
    0: 'Ma',
    1: 'Ti',
    2: 'Ke',
    3: 'To',
    4: 'Pe',
    5: 'La',
    6: 'Su',
  },
  'en-gb': {
    0: 'Mon',
    1: 'Tue',
    2: 'Wed',
    3: 'Thu',
    4: 'Fri',
    5: 'Sat',
    6: 'Sun',
  },
  'swe-swe': {
    0: 'Mån',
    1: 'Tis',
    2: 'Ons',
    3: 'Tors',
    4: 'Fre',
    5: 'Lör',
    6: 'Sön',
  },
  'da-da': {
    0: 'Man',
    1: 'Tirs',
    2: 'Ons',
    3: 'Tors',
    4: 'Fre',
    5: 'Lør',
    6: 'Søn',
  },
  'no-no': {
    0: 'Ma',
    1: 'Ti',
    2: 'On',
    3: 'To',
    4: 'Fr',
    5: 'Lø',
    6: 'Sø',
  },
  de: {
    0: 'Mo',
    1: 'Di',
    2: 'Mi',
    3: 'DO',
    4: 'Fr',
    5: 'Sa',
    6: 'So',
  },
};
export const devicesSortOptions = (t) => [
  { value: 0, object: { name: 1 }, label: t('A-Z') },
  { value: 1, object: { name: -1 }, label: t('Z-A') },
];

export const contractTypeList = (t) => [
  { value: 'default', label: t('default') },
  { value: 'fetch', label: t('fetch') },
  { value: 'return', label: t('return') },
];
export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getHourAndMinute = (time) => {
  if (time === 0) return { hours: 0, minutes: 0 };
  console.log('Time', time);
  const hours = ('0' + (Math.floor(time) % 24)).slice(-2);
  console.log('hours', hours);
  const minutes = ((time % 1) * 60 + '0').slice(0, 2);
  console.log('minutes', minutes);
  return { hours, minutes };
};
