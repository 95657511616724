import React, { Component, createContext } from 'react';
import firebase from './firebase';

import AuthContext from './auth-context';
import AuthProvider from './auth-provider';
import { withAuth, withRouteAuth } from './withAuth';
import Auth from './auth';

const FirebaseContext = createContext({});

let initialised = window.Cypress && window.useMockedFirebase;
// let fBase = firebase;
// if (window.Cypress) {
//   fBase = require('./mocked-firebase');
// }

class FirebaseProvider extends Component {
  constructor(props) {
    super(props);

    const config = this.getConfig(props);
    this.state = {
      config,
    };

    if (!initialised) {
      firebase.initializeApp(config);
      initialised = true;
    }
  }

  getConfig = (props) => {
    return {
      apiKey: props.apiKey,
      authDomain: props.authDomain,
      databaseURL: props.databaseURL,
      projectId: props.projectId,
      storageBucket: props.storageBucket,
      messagingSenderId: props.messagingSenderId,
    };
  };

  render() {
    return (
      <FirebaseContext.Provider value={this.state}>
        {this.props.children}
      </FirebaseContext.Provider>
    );
  }
}

export {
  FirebaseContext,
  FirebaseProvider,
  AuthContext,
  AuthProvider,
  withAuth,
  withRouteAuth,
  Auth,
};
