export const ERROR = 'error/API_ERROR';
const initialState = {
  errors: [],
};
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ERROR:
      return {
        ...state,
        errors: action.data,
      };

    default:
      return state;
  }
}
