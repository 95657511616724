// import { injectGlobal } from 'styled-components';
import c from 'color';

const background = '#fff';
export const primaryThemeColor = '#467285';
export const secondaryThemeColor = '#565656';
export const tertiaryThemeColor = '#53779A';

const theme = {
  //breakpoints: ['40em', '52em', '64em'],
  /**
   * Global stylenames/styles, used in various atoms
   */
  primaryFont: 'Open Sans, sans-serif',
  primaryThemeColor,
  secondaryThemeColor,
  tertiaryThemeColor,
  background,

  /**
   * Block styles
   */
  blockBackground: 'none',
  blockColor: secondaryThemeColor,
  blockPadding: '0 0 0 0',

  /**
   * Caption styles
   */
  captionColor: primaryThemeColor,
  captionFontWeight: '500',
  captionLineHeight: 'normal',
  captionFontSize: '1.15em',
  captionTextTransform: 'none',
  captionTextAlign: 'left',
  captionPadding: '0.8em 0 0.8em 0',
  captionMargin: '0',

  /**
   * Button styles
   */
  primaryBtnColor: '#fff',
  primaryBtnBackgroundColor: primaryThemeColor,
  secondaryBtnColor: secondaryThemeColor,
  secondaryBtnBackgroundColor: '#fff',
  secondaryBtnBorderColor: '#979797',
  secondaryBtnHoverBackgroundColor: '#BECDD6',
  white: '#ffffff',
  /**
   * CircleButton styles
   */
  circleButtonBackground: primaryThemeColor,
  circleButtonDisabledBackground: '#CECECE',
  circleButtonDisabledColor: '#9C9C9C',
  circleButtonTextColor: '#fff',
  circleButtonActiveBackground: '#688C8B',
  circleButtonFontSize: '1.35em',
  circleLetterSpacing: 'normal',

  /**
   * ShortCut styles
   */
  shortCutTitleColor: '#4a4a4a',
  shortCutTitleFontSize: '36px',
  shortCutTitleFontWeight: 300,
  shortCutTitleFontLineHeight: '40px',

  shortCutSubTitleColor: '#4A4A4A',
  shortCutDividerColor: '#D3D3D3',

  /**
   * DeviceStatus styles
   */
  deviceConnectedStatusColor: '#56ca31',
  deviceWithAlarmStatusColor: '#fb3535',
  deviceDisconnectedStatusColor: '#7c7c7c',
  deviceStateBarErrorColor: '#b1293a',
  deviceStateBarTitleColor: '#4A4A4A',

  /**
   * Heading styles
   */
  headingFontWeight: '500',
  headingColor: primaryThemeColor,
  headingPadding: '0.2em 0.2em 0.2em 0em',

  /**
   * HorizontalRule styles
   */
  horizontalRuleColor: '#EBEBEB',
  horizontalRuleBorder: '0 0 2',
  horizontalRuleMargin: '0',

  /**
   * Link styles
   */
  linkTextDecoration: 'underline',
  linkFontWeight: '500',
  linkTransform: 'none',
  linkColor: primaryThemeColor,

  /**
   * ListItem styles
   */
  listItemColor: primaryThemeColor,
  listItemDividerColor: '#D3D3D3',
  listItemBackgroundColor: '#F7F7F7',

  /**
   * Loader styles
   */
  loaderBackground: primaryThemeColor,

  /**
   * PrimaryButton styles
   */
  primaryBackground: primaryThemeColor,
  primaryColor: '#fff',
  primaryButtonBorder: 'none',
  primaryBorderRadius: '15px',
  primaryButtonMargin: '0.5em 0',
  primaryButtonTextPadding: '12px 6px',
  primaryButtonTextTransform: 'uppercase',
  primaryHoverBackground: c(primaryThemeColor).lighten(0.3).hex(),
  primaryActiveBackground: primaryThemeColor,
  primaryDisabledBackground: '#467285b3',
  primaryDisabledColor: '#fff',
  primaryButtonFontSize: '0.95em',
  primaryButtonMinWidth: '0',
  primaryButtonMinHeight: '0',
  primaryButtonFontWeight: '400',
  primaryLetterSpacing: '0.045em',
  primaryButtonTextAlign: 'center',

  /**
   * SecondaryButton styles
   */
  secondaryBackground: '#fff',
  secondaryColor: secondaryThemeColor,
  secondaryBorderRadius: '15px',
  secondaryButtonBorder: `1px solid #979797`,
  secondaryButtonTextPadding: '12px 6px',
  secondaryButtonTextTransform: 'uppercase',
  secondaryHoverBackground: c('#BECDD6').lighten(0.1).hex(),
  secondaryActiveBackground: secondaryThemeColor,
  secondaryDisabledColor: '#bbbbbb',
  secondaryButtonFontSize: '0.95em',
  secondaryButtonMinWidth: '0',
  secondaryButtonMinHeight: '0',
  secondaryButtonFontWeight: '400',
  secondaryLetterSpacing: '0.045em',
  secondaryButtonTextAlign: 'center',

  /**
   * Table components start-->
   */

  /**
   * TableHead styles
   */

  tableHeadBackground: 'rgba(247, 247, 247, 0.8)',
  tableHeadColor: secondaryThemeColor,
  tableHeadFontWeight: '500',
  tableHeadLineHeight: 'normal',
  tableHeadFontSize: '0.8rem',
  tableHeadTextTransform: 'uppercase',
  tableHeadTextAlign: 'left',
  tableHeadPadding: '1em 1em 1em 1em',
  tableHeadMargin: '0',
  tableHeadTopBorder: 'solid 4px #EBEBEB',

  /**
   * TableCell styles
   */
  tableCellColor: secondaryThemeColor,
  tableCellFontWeight: '400',
  tableCellLineHeight: 'normal',
  tableCellFontSize: '0.95em',
  tableCellTextTransform: 'none',
  tableCellTextAlign: 'left',
  tableCellPadding: '0.5em 0.5em 0.5em 0.5em',
  tableCellMargin: '0',
  tableCellTopBorder: 'solid 2px #E0E0E0',

  /**
   * TableRow styles
   */
  tableRowPadding: '0',
  tableRowlMargin: '0',
  tableRowBackground: 'none',
  tableFirstRowBg: 'rgba(230, 230, 230, 0.8)',
  tableSecondRowBg: 'rgba(247, 247, 247, 0.8)',

  /**
   * Input(field) styles
   */
  inputPlaceholderColor: '#c7c7c7',
  inputPlaceholderWeight: '400',
  inputFontWeight: '400',
  inputHeight: '2.75em',
  inputFontSize: '1em',
  inputFontColor: '#717171',
  inputBackground: '#fff',
  inputBorderRadius: '40px',
  inputBorderFocus: 'none',
  inputBoxShadow: '0 1px 1px 0 rgba(0,0,0,0.5)',
  inputBorder: primaryThemeColor,
  inputBorderError: '#C54747',
  inputPadding: '15px',
  inputBorderWidth: '1px',
  inputLabelColor: primaryThemeColor,
  inputIconFill: '#c7c7c7',
  /**
   * Field error
   */
  fieldError: '#C54747',

  /**
   *  <-- Table components end
   */

  /**
   * Label styles
   */
  labelColor: primaryThemeColor,
  labelTransform: 'uppercase',

  /**
   * Navigation syles
   */
  navigationFontWeigt: '700',
  navigationPadding: '0',
  navigationFontSize: '1.05em',
  navigationFontColor: '#4A4A4A',
  navigationActiveColor: primaryThemeColor,

  /**
   * DropDown (menu) styles
   */
  dropdownBackground: '#ffffff',
  dropdownTextColor: '#595959',
  dropdownIconColor: '#595959',
  dropdownShadow: '0 1px 1px 0 rgba(0,0,0,0.17)',
  dropdownBorderRadius: '0 0 5px 5px',
  dropdownPadding: '0.75em',
  dropdownWidth: '200px',
  dropdownItemPadding: '0.5em',
  dropdownMenuHover: '#f8f8f8',

  /**
   * Card styles
   */
  cardBackground: '#ffffff',
  cardBoxShadow: '0 0.1em 0.2em 0 rgba(0,0,0,0.2)',
  cardBoxShadowHover: '0 0.2em 0.4em 0 rgba(0,0,0,0.2)',
  cardPadding: '0',
  cardColor: primaryThemeColor,

  /**
   * Header styles
   */
  headerBackground: '#fff',

  /**
   * Logo styles
   */
  logoFill: primaryThemeColor,

  /**
   * Panel styles
   */
  subtitleColor: '#9d9d9d',
  panelBackground: '#d8d8d8',

  /**
   * Toast styles
   */
  toastBackground: '#ffffff',
  toastBorder: '1px solid #E7E7E7',
  toastPadding: '0.5em 0.5em 1em 1em',
  toastShadow: '0px 14px 27px -16px rgba(0,0,0,0.75);',
  toastSuccessColor: '#65B62F',
  toastErrorColor: '#C54747',
  toastWarningColor: '#F8E71C',
  toastTitleColor: primaryThemeColor,
  toastMessageColor: secondaryThemeColor,

  /**
   * Tree styles
   */
  treeItemBackground: 'none',
  treeItemBackgroundSelected: secondaryThemeColor,
  nodeFontSize: '0.95rem',
  toggleArrowSize: '0.65rem',
  treeTitleColor: primaryThemeColor,
  treeSubtitleColor: secondaryThemeColor,
  actionIconColor: '#4A4A4A',
  toggleColor: primaryThemeColor,

  /**
   * Select styles
   */
  selectPadding: '0 .333em 0 1.25em',
  selectDisabledBackground: '#CECECE',
  selectDisabledColor: '#9C9C9C',
  selectMultiBackground: '#e6e6e6',
  selectMultiBorder: '0',
  selectMenuRadius: '5px',
  selectMenuBorder: `1px solid #eee`,

  /**
   * Mobile menu
   */
  mobileMenuIconColor: '#595959',
  drawerBackground: '#fff',
  drawerProfileColor: '#5B5B5B',
  drawerItemColor: '#5B5B5B',
  drawerItemBackgroundColor: '#fff',
  drawerItemActiveColor: '#fff',
  drawerItemActiveBackground: primaryThemeColor,
};

export default theme;
