import gql from 'graphql-tag';
import {
  commonTagInfoFragment,
  commonTagTreeInfoFragment,
} from '../fragments/tags';
import { pageInfoFragment } from '../fragments/common';

export const GET_TAGS = gql`
  query tags(
    $filter: TagFilter
    $limit: Int
    $sort: JSON = "_id"
    $after: String
  ) {
    tags(filter: $filter, sort: $sort, limit: $limit, after: $after) {
      edges {
        ...commonTagInfo
      }
      pageInfo {
        ...pageInfo
      }
    }
  }
  ${commonTagInfoFragment}
  ${pageInfoFragment}
`;
export const GET_TAG = gql`
  query tag($id: String!) {
    tag(id: $id) {
      ...commonTagInfo
    }
  }
  ${commonTagInfoFragment}
`;

export const GET_TAG_WITH_NESTED_CHILDREN = (levels = 3) => {
  let nested = ``;
  const buildNested = (levels) => {
    levels--;
    return (nested += `
      children(limit: 0) {
        edges {
          id
          name
          type
          hasChildren
          ${(levels > 0 && buildNested(levels)) || ``}
        }
   
      }
    `);
  };
  buildNested(levels);
  return gql`
  query tag($id: String!) {
    tag(id: $id) {
      ...commonTagInfo
      ${nested}
    }
  }
  ${commonTagInfoFragment}
`;
};

export const GET_ALL_TAGS = gql`
  query {
    tags {
      edges {
        id
      }
    }
  }
`;

export const GET_TAGSTREE = gql`
  query tagsTree(
    $id: String
    $limit: Int
    $after: String
    $filter: TagFilter
    $type: String
  ) {
    tagsTree(id: $id) {
      ...commonTagTreeInfo
      children(limit: $limit, after: $after, filter: $filter) {
        edges {
          ...commonTagTreeInfo
        }
        pageInfo {
          ...pageInfo
        }
      }
    }
  }
  ${commonTagTreeInfoFragment}
  ${pageInfoFragment}
`;
