import gql from 'graphql-tag';
import { reset as resetForm } from 'redux-form';

const USER_FETCHED = 'user/FETCHED';
const USER_LOADING = 'user/LOADING';
const USER_FAILED = 'user/FAILED';

const USER_DELETING = 'user/DELETING';
const USER_DELETED = 'user/DELETED';

const TAG_FETCHED = 'tag/FETCHED';
const TAG_LOADING = 'tag/LOADING';
const TAG_FAILED = 'tag/FAILED';

const TAGS_FETCHED = 'tags/FETCHED';
const TAGS_LOADING = 'tags/LOADING';
const TAGS_FAILED = 'tags/FAILED';

const USER_SAVING = 'user/SAVING';
const USER_SAVED = 'user/SAVED';
const USER_SAVEFAIL = 'user/SAVEFAIL';

const USER_RESET = 'user/RESET';
const USER_RESET_ERROR = 'user/RESET_ERROR';

export const reset = () => (dispatch) => dispatch({ type: USER_RESET });

export const resetError = () => (dispatch) =>
  dispatch({ type: USER_RESET_ERROR });

export const createUser = (client, input) => async (dispatch) => {
  dispatch({ type: USER_LOADING });
  try {
    const res = await client.mutate({
      mutation: gql`
        mutation createUser($input: UserCreateInput!) {
          createUser(input: $input) {
            id
            email
            language
            displayName
            phoneNumber
            role
            uiNotifications
            acl {
              role
              path
              name
            }
          }
        }
      `,
      variables: { input },
    });

    dispatch({ type: USER_SAVED, data: res.data.createUser });
    dispatch(resetForm('user'));
  } catch (err) {
    dispatch({ type: USER_FAILED, data: err.message });
    throw err;
  }
};

export const getUser = (client, id) => async (dispatch) => {
  dispatch({ type: USER_LOADING });
  try {
    const res = await client.query({
      query: gql`
        query user($id: String!) {
          user(id: $id) {
            id
            email
            displayName
            language
            phoneNumber
            role
            uiNotifications
            acl {
              role
              path
              name
            }
          }
        }
      `,
      variables: { id },
    });
    dispatch({ type: USER_FETCHED, data: res.data.user });
  } catch (err) {
    dispatch({ type: USER_FAILED, data: err.message });
    throw err;
  }
};

export const updateUserAndPassword = (client, id, input, password) => async (
  dispatch
) => {
  dispatch({ type: USER_SAVING });
  try {
    const res = await client.mutate({
      mutation: gql`
        mutation updateUser(
          $id: String!
          $input: UserUpdateInput!
          $password: String!
        ) {
          updateUser(id: $id, input: $input) {
            id
            email
            language
            displayName
            phoneNumber
            role
            uiNotifications
            acl {
              role
              path
              name
            }
          }
          changePassword(id: $id, password: $password) {
            email
            language
            displayName
            phoneNumber
            role
            uiNotifications
            acl {
              role
              path
              name
            }
          }
        }
      `,
      variables: { id, input, password },
    });

    dispatch({ type: USER_SAVED, data: res.data.updateUser });
    dispatch(resetForm('user'));
  } catch (err) {
    dispatch({ type: USER_FAILED, data: err.message });
    throw err;
  }
};

export const updateUser = (client, id, input) => async (dispatch) => {
  dispatch({ type: USER_SAVING });
  try {
    const res = await client.mutate({
      mutation: gql`
        mutation updateUser($id: String!, $input: UserUpdateInput!) {
          updateUser(id: $id, input: $input) {
            id
            email
            language
            displayName
            phoneNumber
            role
            uiNotifications
            acl {
              role
              path
              name
            }
          }
        }
      `,
      variables: { id, input },
    });

    dispatch({ type: USER_SAVED, data: res.data.updateUser });
    dispatch(resetForm('user'));
  } catch (err) {
    dispatch({ type: USER_FAILED, data: err.message });
    throw err;
  }
};

export const delUser = (client, id) => async (dispatch) => {
  dispatch({ type: USER_DELETING });
  try {
    const res = await client.mutate({
      mutation: gql`
        mutation deleteUser($id: String!) {
          deleteUser(id: $id)
        }
      `,
      variables: { id },
    });
    dispatch({ type: USER_DELETED, data: res.data.deleteUser });
  } catch (err) {
    dispatch({ type: USER_FAILED, data: err.message });
    throw err;
  }
};

export const getTags = (client) => async (dispatch) => {
  dispatch({ type: TAGS_LOADING });
  try {
    const res = await client.query({
      query: gql`
        query tags($filter: TagFilter, $after: String, $limit: Int) {
          tags(filter: $filter, after: $after, limit: $limit) {
            edges {
              id
              name
              type
              alarmReceivers {
                mail
                sms
                language
                activation
                deactivation
                rules {
                  id
                  sendEmail
                  sendSms
                }
              }
              notificationReceivers {
                mail
                sms
                language
                activation
                deactivation
                rules {
                  id
                  sendEmail
                  sendSms
                }
              }
            }
          }
        }
      `,
      variables: { filter: {}, limit: 0 },
    });
    console.log('tags fetched', res);

    dispatch({
      type: TAGS_FETCHED,
      data: res.data.tags.edges.filter((t) => t.type !== 'apartment'),
    });
  } catch (err) {
    dispatch({ type: TAGS_FAILED, data: (err && err.message) || err });
    throw err;
  }
};
export const getTag = (client, id) => async (dispatch) => {
  dispatch({ type: TAG_LOADING });
  try {
    const res = await client.query({
      query: gql`
        query tag($id: String!) {
          tag(id: $id) {
            id
            name
            type
            meta
            alarmReceivers {
              mail
              sms
              language
              activation
              deactivation
              rules {
                id
                sendEmail
                sendSms
              }
            }
          }
        }
      `,
      variables: { id },
    });
    console.log('tag fetched', res);

    dispatch({ type: TAG_FETCHED, data: res.data.tag });
  } catch (err) {
    dispatch({ type: TAG_FAILED, data: (err && err.message) || err });
    throw err;
  }
};

const initialState = {
  user: null,
  tags: [],
  loading: false,
  deleting: false,
  apiError: null,
  saving: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case USER_RESET_ERROR:
      return {
        ...state,
        apiError: null,
      };
    case USER_LOADING:
      return {
        ...state,
        loading: true,
      };
    case USER_FETCHED:
      return {
        ...state,
        user: action.data,
        loading: false,
      };
    case USER_FAILED:
      return {
        ...state,
        apiError: action.data,
        loading: false,
        saving: false,
      };
    case USER_DELETED:
      return {
        ...state,
        deleting: false,
      };
    case USER_DELETING:
      return {
        ...state,
        deleting: true,
      };
    case USER_RESET:
      return {
        ...state,
        user: null,
        tags: [],
        loading: false,
        apiError: null,
        saving: false,
      };
    case TAGS_LOADING:
    case TAG_LOADING:
      return {
        ...state,
        loading: true,
      };
    case TAGS_FETCHED:
      return {
        ...state,
        tags: action.data,
        loading: false,
      };
    case TAG_FETCHED:
      return {
        ...state,
        tag: action.data,
        loading: false,
      };
    case TAG_FAILED:
    case TAGS_FAILED:
      return {
        ...state,
        apiError: action.data,
        loading: false,
        saving: false,
      };
    case USER_SAVING:
      return {
        ...state,
        saving: true,
      };
    case USER_SAVED:
      return {
        ...state,
        user: action.data,
        saving: false,
      };
    case USER_SAVEFAIL:
      return {
        ...state,
        apiError: action.data,
        loading: false,
        saving: false,
      };
    default:
      return state;
  }
}
