import axios from 'axios';
import gql from 'graphql-tag';

const INSTALLING = 'install/INSTALLING';

const INSTALL_SUCCESS = 'install/INSTALL_SUCCESS';
const INSTALL_FAIL = 'install/INSTALL_FAIL';
const INSTALL_RESET = 'install/INSTALL_RESET';
const INSTALL_TAG_META_FETCHED = 'install/INSTALL_TAG_META_FETCHED';

const USERLOCATION_FETCHED = 'install/USERLOCATION_FETCHED';

const SETUP_SAVING = 'install/SETUP_SAVING';
const SETUP_SUCCESS = 'install/SETUP_SUCCESS';
//const SETUP_FAIL = "install/SETUP_FAIL";

const ALARMRULES_LOADING = 'install/ALARMRULES_LOADING';
const ALARMRULES_FETCHED = 'install/ALARMRULES_FETCHED';
const ALARMRULES_FAILED = 'install/ALARMRULES_FAILED';
const NOTIFICATIONRULES_LOADING = 'install/NOTIFICATIONRULES_LOADING';
const NOTIFICATIONRULES_FETCHED = 'install/NOTIFICATIONRULES_FETCHED';
const NOTIFICATIONRULES_FAILED = 'install/NOTIFICATIONRULES_FAILED';

export const getUserLocation = (lat, lon) => (dispatch) => {
  const base_URL = 'https://nominatim.openstreetmap.org/reverse?format=json';

  return axios
    .get(`${base_URL}&lat=${lat}&lon=${lon}`)
    .then((res) => {
      dispatch({
        type: USERLOCATION_FETCHED,
        data: {
          country: res.data.address.country,
          country_code: res.data.address.country_code,
          postcode: res.data.address.postcode,
          road: res.data.address.road,
          state: res.data.address.state,
          town: res.data.address.town,
          lat,
          lon,
        },
      });
    })
    .catch((error) => {
      throw error;
    });
};

export const setupDevice = (id, reqData) => (dispatch) => {
  dispatch({ type: SETUP_SAVING });
  dispatch({ type: SETUP_SUCCESS });
};

export const installDevice = (client, id, deviceInfo) => async (dispatch) => {
  dispatch({ type: INSTALLING });

  try {
    await client.mutate({
      mutation: gql`
        mutation installDevice(
          $id: String!
          $newState: String!
          $input: DeviceInput!
        ) {
          changeLifecycleState(id: $id, newState: $newState)
          updateDevice(id: $id, input: $input) {
            id
            name
            tag
            virtual
            socketServer
            meta
            settings {
              data
            }
            connectionState
            lifecycleState
            alarmReceivers {
              mail
              sms
              language
              activation
              deactivation
              rules {
                id
                sendEmail
                sendSms
              }
            }
            notificationReceivers {
              mail
              sms
              language
              activation
              deactivation
              rules {
                id
                sendEmail
                sendSms
              }
            }
          }
        }
      `,
      variables: { id, newState: 'active', input: { ...deviceInfo } },
    });
    dispatch({ type: INSTALL_SUCCESS });
  } catch (err) {
    dispatch({ type: INSTALL_FAIL, data: err.message });
  }
};

export const findTagMeta = (client, tag) => async (dispatch) => {
  // console.log('getting meta from', tag);

  dispatch({ type: INSTALLING });
  try {
    const res = await client.query({
      query: gql`
        query findOrganizationMeta($id: String!) {
          findOrganizationMeta(id: $id)
        }
      `,
      variables: { id: tag },
    });

    dispatch({
      type: INSTALL_TAG_META_FETCHED,
      data: res.data.findOrganizationMeta,
    });
    return res.data.findOrganizationMeta;
  } catch (err) {
    dispatch({ type: INSTALL_FAIL, data: err.message });
    throw err;
  }
};

export const getAlarmRules = (
  client,
  products = ['key30', 'keyX'],
  tag,
  t
) => async (dispatch) => {
  dispatch({ type: ALARMRULES_LOADING });
  try {
    const res = await client.query({
      query: gql`
        query publicAlarmRules($filter: AlarmRuleFilter) {
          publicAlarmRules(filter: $filter, limit: 0) {
            edges {
              id
              alarmName
            }
          }
        }
      `,
      variables: {
        filter: tag
          ? {
              products,
              isNotification: false,
              categories: ['customer'],
              tag,
            }
          : {
              products,
              isNotification: false,
              categories: ['customer'],
            },
      },
    });

    const ruleList = res.data.publicAlarmRules.edges.map((r) => ({
      value: r.id,
      label: t(r.alarmName),
    }));
    dispatch({ type: ALARMRULES_FETCHED, data: ruleList });
    return ruleList;
  } catch (err) {
    dispatch({ type: ALARMRULES_FAILED, data: err.message });
    throw err;
  }
};
export const getNotificationRules = (
  client,
  products = ['key30', 'keyX'],
  tag,
  t
) => async (dispatch) => {
  console.log('getNotificationRules');
  dispatch({ type: NOTIFICATIONRULES_LOADING });
  try {
    const res = await client.query({
      query: gql`
        query publicAlarmRules($filter: AlarmRuleFilter) {
          publicAlarmRules(filter: $filter, limit: 0) {
            edges {
              id
              alarmName
            }
          }
        }
      `,
      variables: {
        filter: tag
          ? {
              products,
              isNotification: true,
              categories: ['customer'],
              tag,
            }
          : {
              products,
              isNotification: true,
              categories: ['customer'],
            },
      },
    });
    console.log('NOTIFICATIONRULES_FETCHED', res.data.publicAlarmRules.edges);
    const notificationList = res.data.publicAlarmRules.edges.map((r) => ({
      value: r.id,
      label: t(r.alarmName),
    }));
    dispatch({ type: NOTIFICATIONRULES_FETCHED, data: notificationList });
    return notificationList;
  } catch (err) {
    dispatch({ type: NOTIFICATIONRULES_FAILED, data: err.message });
    throw err;
  }
};

export const installReset = () => (dispatch) => {
  dispatch({ type: INSTALL_RESET });
};

const initialState = {
  keyBoxInfo: {},
  loading: null,
  rulesLoading: null,
  notificationsLoading: null,
  userLocation: '',
  deviceSettings: {},
  tagMeta: null,
  installError: null,
  ruleList: [],
  notificationList: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case USERLOCATION_FETCHED:
      return {
        ...state,
        userLocation: action.data,
        loading: false,
      };
    case ALARMRULES_FETCHED:
      return {
        ...state,
        ruleList: action.data,
        loading: false,
      };
    case NOTIFICATIONRULES_FETCHED:
      return {
        ...state,
        notificationList: action.data,
        loading: false,
      };
    case INSTALL_FAIL:
    case ALARMRULES_FAILED:
    case NOTIFICATIONRULES_FAILED:
      return {
        ...state,
        installError: action.data,
        loading: false,
      };
    case INSTALLING:
      return {
        ...state,
        loading: true,
      };
    case ALARMRULES_LOADING:
      return {
        ...state,
        rulesLoading: true,
      };
    case NOTIFICATIONRULES_LOADING:
      return {
        ...state,
        notificationsLoading: true,
      };
    case INSTALL_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case INSTALL_TAG_META_FETCHED:
      return {
        ...state,
        tagMeta: action.data,
        loading: false,
      };

    case INSTALL_RESET:
      return {
        ...state,
        installError: null,
        deviceSettings: {},
        userLocation: '',
        keyBoxInfo: {},
        loading: null,
        rulesLoading: null,
        notificationsLoading: null,
      };
    default:
      return state;
  }
}
