import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './locales/en.json';
import fi from './locales/fi.json';
import se from './locales/se.json';
import no from './locales/no.json';
import de from './locales/de.json';

const languageDetector = new LanguageDetector(null, {
  // order and from where user language should be detected
  order: ['localStorage', 'sessionStorage', 'navigator'],

  // keys or params to lookup language from

  lookupLocalStorage: 'i18nextLng',
  lookupSessionStorage: 'i18nextLng',

  // cache user language on
  caches: ['localStorage'],
  excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

  // optional expire and domain for set cookie
  /*cookieMinutes: 10,
  cookieDomain: 'myDomain',
*/
  // optional htmlTag with lang attribute, the default is:
  //htmlTag: document.documentElement,

  // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
  //cookieOptions: { path: '/', sameSite: 'strict' }
});

i18n.use(languageDetector).init({
  // we init with resources
  resources: {
    en,
    fi,
    se,
    no,
    de,
  },

  fallbackLng: 'en',
  debug: false,

  // have a common namespace used around the full app
  ns: ['translations'],
  defaultNS: 'translations',

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ',',
  },

  react: {
    wait: true,
  },
});

export default i18n;
