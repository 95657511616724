import React from 'react';
import styled, { keyframes } from 'styled-components';

const skFoldCubeAngle = keyframes`
    0%, 10% {
      transform: perspective(140px) rotateX(-180deg);
      opacity: 0;
    }
    25%, 75% {
      transform: perspective(140px) rotateX(0deg);
      opacity: 1;
    }
    90%, 100% {
      transform: perspective(140px) rotateY(180deg);
      opacity: 0;
    }`;

const StyledMessage = styled.div`
  text-align: center;
  font-size: 16px;
  color: #53779a;
  @media (max-width: 768px): {
      padding: 0px 10px;
      font-size: 13px;
    }
  }
`;
const StyledFolding = styled.div`
  margin: ${({ mini }) => (mini ? '10px auto' : '100px auto')};
  width: ${({ mini }) => (mini ? '12px' : '40px')};
  height: ${({ mini }) => (mini ? '12px' : '40px')};
  position: relative;
  transform: rotateZ(45deg);
`;
const StyledCube = styled.div`
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  transform: scale(1.1);
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #53779a;
    animation: ${skFoldCubeAngle} 2.4s infinite linear both;
    transform-origin: 100% 100%;
  }
`;
const StyledCube2 = styled(StyledCube)`
    transform: scale(1.1) rotateZ(90deg);
    &:before {
      animation-delay: 0.3s;
    },`;
const StyledCube3 = styled(StyledCube)`
    transform: scale(1.1) rotateZ(180deg);
    &:before {
      animation-delay: 0.6s;
    },`;
const StyledCube4 = styled(StyledCube)`
    transform: scale(1.1) rotateZ(270deg);
    &:before {
      animation-delay: 0.9s;
    },`;

const Loader = ({ mini, message }) => {
  return (
    <React.Fragment>
      {message && <StyledMessage>{message}</StyledMessage>}
      <StyledFolding mini={mini}>
        <StyledCube />
        <StyledCube2 />
        <StyledCube4 />
        <StyledCube3 />
      </StyledFolding>
    </React.Fragment>
  );
};

export default Loader;
