/* eslint-disable no-underscore-dangle */
import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import { reducer as formReducer } from 'redux-form';

// Middleware
import thunk from 'redux-thunk';
import multi from 'redux-multi';
import { batchedSubscribe } from 'redux-batched-subscribe';

import { unstable_batchedUpdates } from 'react-dom';

import auth from './Auth';
import ui from './Ui';
import devices from './Devices';
import users from './Users';
import user from './User';
import keys from './Keys';
import contracts from './Contracts';
import logs from './Logs';
import timeline from './Timeline';
import install from './Install';
import error from './Error';

import { CONTRACTS_SAVED, CONTRACTS_RESET } from './Contracts';
import { KEYS_SETKEY_SUCCESS, KEYS_SETKEY_RESET } from './Keys';
const reducer = combineReducers({
  auth,
  error,
  ui,
  devices,
  users,
  user,
  keys,
  contracts,
  logs,
  timeline,
  install,
  form: formReducer.plugin({
    reserve: (state, action) => {
      switch (action.type) {
        case CONTRACTS_SAVED:
        case CONTRACTS_RESET:
          console.log('resetting contract form');
          return undefined;
        default:
          return state;
      }
    },
    setkey: (state, action) => {
      switch (action.type) {
        case KEYS_SETKEY_SUCCESS:
        case KEYS_SETKEY_RESET:
          console.log('resetting setkey form');
          return undefined;
        default:
          return state;
      }
    },
  }),
});

const rootReducer = (state, action) => {
  return reducer(state, action);
};

const enchancer = compose(
  applyMiddleware(thunk, multi),
  batchedSubscribe(unstable_batchedUpdates)
);

const store = () =>
  createStore(
    rootReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
      window.__REDUX_DEVTOOLS_EXTENSION__(),
    enchancer
  );

export default store;
