import firebase from 'firebase/app';
// These imports load individual services into the firebase namespace.
import 'firebase/auth';
import 'firebase/database';
import 'firebase/analytics';

let fbase;
if (window.Cypress && window.useMockedFirebase) {
  console.log('USING FIREBASE FROM CYPRESS');
  fbase = window.firebase;
} else {
  console.log('USING REAL FIREBASE');
  fbase = firebase;
}

export default fbase;
