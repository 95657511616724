import React from 'react';
import styled from 'styled-components';

const StyledTitleIcon = styled.img`
  cursor: pointer;
  width: ${(props) => (props.size && `${props.size}px`) || '36px'};
  height: ${(props) => (props.height && `${props.height}px`) || '40px'};
`;
const StyledOpaque = styled.div`
  opacity: 0.2;
`;

const IconSvg = ({ label, size, height, svg, disabled }) => {
  //variable used to remove invalid prop warning
  if (!disabled) {
    return (
      <StyledTitleIcon size={size} height={height} src={svg} alt={label} />
    );
  } else {
    return (
      <StyledOpaque>
        <StyledTitleIcon src={svg} alt={label} />
      </StyledOpaque>
    );
  }
};

export default IconSvg;
