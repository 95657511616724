export const debugLabels = [
  'device:3g-signal',
  'device:atmel',
  'device:atmel-updater',
  'device:check-code',
  'device:code-entered',
  'device:detection',
  'device:device-functions',
  'device:device-update',
  'device:door',
  'device:door-driver',
  'device:firestore',
  'device:front-camera',
  'device:front-camera-stub',
  'device:front-led',
  'device:hw-init',
  'device:info',
  'device:keyboard-test-mode',
  'device:keycode',
  'device:locker-debug',
  'device:locker-led',
  'device:network-traffic-monitor',
  'device:open-box',
  'device:rotator',
  'device:serial-port-stub',
  'device:settings',
  'device:take-photo',
  'device:tamper-monitor',
  'device:text-to-speech',
  'device:timeline',
  'device:timeline-buffer',
  'device:timezone',
  'device:tone-player',
  'device:tone-player-stub',
  'device:update-key-status',
  'device:usb-camera-avconv',
  'device:usb-camera-opencv',
  'device:usb-camera-opencv-stub',
  'device:variable',
  'device:voltage-monitor',
  'device:wifi-configurator',
];
