import { LivionKeyApi } from '../utils/networkService';
// import Cookies from 'universal-cookie';
//
// import ConnectDevice from '@livion/connect-communication/dist/index';
import cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import { reset } from 'redux-form';
import { GET_APARTMENT, GET_APARTMENTS } from '../api/queries/apartments';

export const KEYS_FETCHED = 'keys/FETCHED';
const KEYS_SAVING = 'keys/SAVING';
const KEYS_LOADING = 'keys/LOADING';
const KEYS_FAILED = 'keys/FAILED';

const KEYS_UPSERTFAILED = 'keys/KEYS_UPSERTFAILED';
const KEYS_DELKEY_SUCCESS = 'keys/KEYS_DELKEY_SUCCESS';
const KEYS_DELKEY_FAILED = 'keys/KEYS_DELKEY_FAILED';

const KEYS_UPDATE_BACKUPCODES_SUCCESS = 'keys/KEYS_UPDATE_BACKUPCODES_SUCCESS';
const KEYS_UPDATE_BACKUPCODES_FAILED = 'keys/KEYS_UPDATE_BACKUPCODES_FAILED';

const KEYS_STATUS_LOADING = 'keys/KEYS_STATUS_LOADING';
export const KEYS_STATUS_FETCHED = 'keys/KEYS_STATUS_FETCHED';
const KEYS_STATUS_FAILED = 'keys/KEYS_STATUS_FAILED';

const KEY_STATUS_LOADING = 'keys/KEY_STATUS_LOADING';
export const KEY_STATUS_FETCHED = 'keys/KEY_STATUS_FETCHED';
const KEY_STATUS_FAILED = 'keys/KEY_STATUS_FAILED';
const KEYS_KEYSTATUS_RESET = 'keys/KEYS_KEYSTATUS_RESET';

export const KEYS_UPDATEKEY_SUCCESS = 'keys/KEYS_UPDATEKEY_SUCCESS';
export const KEYS_SETKEY_SUCCESS = 'keys/KEYS_SETKEY_SUCCESS';
const KEYS_SETKEY_FAILED = 'keys/KEYS_SETKEY_FAILED';
const KEYS_UPDATEKEY_FAILED = 'keys/KEYS_UPDATEKEY_FAILED';
export const KEYS_SETKEY_RESET = 'keys/KEYS_SETKEY_RESET';

const KEY_LOADING = 'keys/KEY_LOADING';
const KEY_GET_SUCCESS = 'keys/KEY_GET_SUCCESS';
const KEY_GET_FAILED = 'keys/KEY_GET_FAILED';

const KEYS_CLEAR_ERROR = 'keys/KEYS_CLEAR_ERROR';
const KEYS_RESET = 'keys/KEYS_RESET';

const KEY_APARTMENT_LOADING = 'keys/KEY_APARTMENT_LOADING';
const KEY_APARTMENT_FETCHED = 'keys/KEY_APARTMENT_FETCHED';
const KEY_APARTMENT_NOT_FOUND = 'keys/KEY_APARTMENT_NOT_FOUND';
const KEY_APARTMENT_FAILED = 'keys/KEY_APARTMENT_FAILED';
const KEY_APARTMENT_RESET = 'keys/KEY_APARTMENT_RESET';

const KEY_APARTMENTS_LOADING = 'keys/KEY_APARTMENTS_LOADING';
const KEY_APARTMENTS_FETCHED = 'keys/KEY_APARTMENTS_FETCHED';
const KEY_APARTMENTS_FAILED = 'keys/KEY_APARTMENTS_FAILED';

const populateStatus = (arr, res) => {
  let r = [];

  for (var k = 0; k < arr.length; k++) {
    if (arr[k].keyId) {
      arr[k].keyStatus = {
        value: res[k] && res[k].data ? res[k].data.action : '',
        timestamp: res[k] && res[k].data ? res[k].data.timestamp : '',
      };
    }
    r.push(arr[k]);
  }
  return r;
};

export const getKey = (deviceId, keyId) => (dispatch) => {
  dispatch({ type: KEY_LOADING });
  console.log('getkey : ', keyId, deviceId);
  return LivionKeyApi.get(`/keys/${keyId}`, { params: { deviceId } })
    .then((res) => {
      console.log('Key redux KEY_GET_SUCCESS', res.data);
      dispatch({ type: KEY_GET_SUCCESS, data: res.data[0] });
    })
    .catch((err) => {
      console.log('Key redux KEY_GET_FAILED', err.response);
      dispatch({ type: KEY_GET_FAILED, data: err.response || err });
      throw err;
    });
};
export const setKey = (
  device,
  lockerIndex,
  keyId,
  keyName,
  keyCode,
  shared
) => (dispatch) => {
  dispatch({ type: KEYS_SAVING });

  let deviceId = device.id;
  return LivionKeyApi.post(`/keys`, {
    keyId,
    keyCode,
    deviceId,
    keyName,
    lockerIndex,
    shared,
  })
    .then((res) => {
      console.log('Key redux KEYS_SETKEY_SUCCESS', res.data);
      dispatch({ type: KEYS_SETKEY_SUCCESS, data: res.data });
      dispatch(reset('setkey'));
    })
    .catch((err) => {
      console.log('Key redux KEYS_SETKEY_FAILED', err.response);
      dispatch({ type: KEYS_SETKEY_FAILED, data: err.response || err });
      throw err;
    });
};
export const updateKey = (
  device,
  lockerIndex,
  keyId,
  keyName,
  keyCode,
  shared
) => (dispatch) => {
  dispatch({ type: KEYS_SAVING });

  let deviceId = device.id;
  console.log(
    'updatekey : ',
    keyId,
    keyCode,
    deviceId,
    keyName,
    lockerIndex,
    shared
  );
  return LivionKeyApi.post(`/keys/${encodeURIComponent(keyId)}`, {
    keyCode,
    deviceId,
    keyName,
    lockerIndex: parseInt(lockerIndex, 10),
    shared,
  })
    .then((res) => {
      console.log('Key redux KEYS_UPDATEKEY_SUCCESS', res.data);
      dispatch({ type: KEYS_UPDATEKEY_SUCCESS, data: res.data });
    })
    .catch((err) => {
      console.log('Key redux KEYS_SETKEY_FAILED', err.response);
      dispatch({ type: KEYS_SETKEY_FAILED, data: err.response || err });
      throw err;
    });
};

export const extractKeys = (settings, product) => async (dispatch) => {
  console.log('extracting keys');
  let newSettings = cloneDeep(settings);

  dispatch({ type: KEYS_LOADING });
  try {
    let data = [];

    if (product === 'keypad' && !newSettings.data.keys.length) {
      console.log('empty keypad keys');
      data.push({
        keyId: 'keyPadKey',
        keyName: 'keyPadKey',
        shared: true,
        contracts: [],
      });
    } else {
      if (
        product !== 'keypad' &&
        newSettings &&
        newSettings.data &&
        newSettings.data.keys &&
        newSettings.data.keys.length > 0
      ) {
        data = newSettings.data.keys;
      } else {
        console.log('filling keys');
        const lockerCount = _get(settings, 'data.features.lockerCount', 30);
        console.log('filling keys');
        for (let i = 0; i < lockerCount; i++) {
          data.push({
            lockerStatus: { value: 'lockerFree', timestamp: '' },
          });
        }
      }
    }

    dispatch({
      type: KEYS_FETCHED,
      data,
    });
    return data.map((k) => {
      if (k.contracts)
        k.contracts = k.contracts.sort((a, b) => {
          return new Date(a.start) - new Date(b.start);
        });
      return k;
    });
  } catch (err) {
    dispatch({ type: KEYS_FAILED, data: err });
    throw err;
  }
};

export const getKeysStatus = (keys, deviceId) => async (dispatch) => {
  dispatch({ type: KEYS_STATUS_LOADING });
  try {
    let allKeys = keys.map(
      (key) =>
        key.keyId &&
        LivionKeyApi.get(`keys/${encodeURIComponent(key.keyId)}/status`, {
          params: { deviceId },
        })
    );
    let keyStatuses = await Promise.all(allKeys);
    console.log(
      'populateStatus(keys, keyStatuses)',
      populateStatus(keys, keyStatuses)
    );
    dispatch({
      type: KEYS_STATUS_FETCHED,
      data: populateStatus(keys, keyStatuses),
    });
  } catch (err) {
    console.error('there was problem loading statuses');
    dispatch({ type: KEYS_STATUS_FAILED, data: err });
    throw err;
  }
};
export const getKeyStatus = (key, deviceId) => async (dispatch) => {
  dispatch({ type: KEY_STATUS_LOADING });
  try {
    setTimeout(() => console.log(''), 5 * 1000);
    let keyStatus = await LivionKeyApi.get(
      `keys/${encodeURIComponent(key.keyId)}/status`,
      {
        params: { deviceId },
      }
    );
    dispatch({
      type: KEY_STATUS_FETCHED,
      data: {
        value: keyStatus && keyStatus.data ? keyStatus.data.action : '',
        timestamp: keyStatus && keyStatus.data ? keyStatus.data.timestamp : '',
      },
    });
  } catch (err) {
    console.error('there was problem loading key status');
    dispatch({ type: KEY_STATUS_FAILED, data: err });
    throw err;
  }
};
export const resetKeys = () => (dispatch) => {
  dispatch({ type: KEYS_RESET });
};
export const removeKey = (keyId, deviceId) => (dispatch) => {
  dispatch({ type: KEYS_LOADING });
  console.log('removeKey : ', keyId);
  return LivionKeyApi.delete(`/keys/${encodeURIComponent(keyId)}`, {
    params: { deviceId },
  })
    .then((res) => {
      console.log('Key redux KEYS_DELKEY_SUCCESS', res.data);
      dispatch({ type: KEYS_DELKEY_SUCCESS, data: res.data });
    })
    .catch((err) => {
      console.log('Key redux KEYS_DELKEY_FAILED', err);
      dispatch({ type: KEYS_DELKEY_FAILED, data: err });
      throw err;
    });
};

export const setKeyReset = () => (dispatch) =>
  dispatch({ type: KEYS_SETKEY_RESET });
export const resetKeyStatus = () => (dispatch) =>
  dispatch({ type: KEYS_KEYSTATUS_RESET });

export const updateBackupCodes = (keyId, deviceId) => (dispatch) => {
  dispatch({ type: KEYS_LOADING });
  console.log('updateBackupCodes : ', keyId, deviceId);
  return LivionKeyApi.post(`/keys/${encodeURIComponent(keyId)}/backupcodes`, {
    deviceId,
  })
    .then((res) => {
      console.log('Key redux KEYS_UPDATE_BACKUPCODES_SUCCESS', res.data);
      dispatch({ type: KEYS_UPDATE_BACKUPCODES_SUCCESS, data: res.data });
    })
    .catch((err) => {
      console.log('Key redux KEYS_UPDATE_BACKUPCODES_FAILED', err);
      dispatch({ type: KEYS_UPDATE_BACKUPCODES_FAILED, data: err });
      throw err;
    });
};

//export const calibrate = (device, index) => takePhoto()
export const getApartments = (client, filter) => async (dispatch) => {
  dispatch({ type: KEY_APARTMENTS_LOADING });
  try {
    const res = await client.query({
      query: GET_APARTMENTS,
      variables: { filter },
    });
    let apartments = cloneDeep(res.data.apartments.edges);
    // if no apartment add error

    dispatch({
      type: KEY_APARTMENTS_FETCHED,
      data: apartments,
    });
    return apartments;
    // if reservation format
  } catch (err) {
    dispatch({ type: KEY_APARTMENTS_FAILED, data: err.message });
    throw err;
  }
};
export const getApartment = (client, id, device, t, contracts) => async (
  dispatch
) => {
  dispatch({ type: KEY_APARTMENT_LOADING });
  try {
    const res = await client.query({
      query: GET_APARTMENT,
      variables: { id },
      skip: !id,
    });
    let apartment = cloneDeep(res.data.apartment);
    console.log('Apartment Fetched', apartment);
    // if no apartment add error
    if (!apartment) {
      dispatch({
        type: KEY_APARTMENT_NOT_FOUND,
        data: t('ApartmentNotFound'),
      });
      return null;
    } else {
      dispatch({
        type: KEY_APARTMENT_FETCHED,
        data: apartment,
      });
      return apartment;
    }
    // if reservation format
  } catch (err) {
    if (err.message === 'access denied') {
      dispatch({
        type: KEY_APARTMENT_NOT_FOUND,
        data: t('ApartmentAccessDenied'),
      });
    } else {
      dispatch({ type: KEY_APARTMENT_FAILED, data: err.message });
      throw err;
    }
  }
};

export const resetApartment = () => async (dispatch) =>
  dispatch({ type: KEY_APARTMENT_RESET });

const initialState = {
  status: null,
  saving: false,
  device: {},
  keys: [],
  filteredKeys: [],
  loading: false,
  connecting: false,
  connected: false,
  showToast: false,
  toastMessage: '',
  toastError: false,
  toastWarning: false,
  deviceError: null,
  apiError: null,
  locker: 0,
  targetLocker: 0,
  testMode: 0,
  insertMode: 0,
  lockerStatus: [],
  pingTime: 0,
  traces: '',
  labels: [],
  //rssi: null
  keyStatus: null,
  fetchedKey: null,
  apartment: null,
  apartments: [],
  apartmentError: null,
  apartmentsLoading: false,
  apartmentLoading: false,
};

export default function reducer(state = initialState, action) {
  let err = '';
  switch (action.type) {
    case KEYS_CLEAR_ERROR:
      return {
        ...state,
        apiError: null,
      };
    case KEYS_UPDATE_BACKUPCODES_SUCCESS:
      return {
        ...state,
        loading: false,
        apiError: null,
      };
    case KEYS_LOADING:
      return {
        ...state,
        deviceError: null,
        apiError: null,
        loading: true,
      };
    case KEY_LOADING:
      return {
        ...state,
        deviceError: null,
        apiError: null,
        loading: true,
      };
    case KEY_STATUS_LOADING:
      return {
        ...state,
        deviceError: null,
        apiError: null,
        keyStatusLoading: true,
      };
    case KEYS_SAVING:
      state.error === 'Connection timeout' ? (err = state.error) : (err = null);
      return {
        ...state,
        deviceError: err,
        saving: true,
      };
    case KEYS_FETCHED:
      const keys = action.data;
      return {
        ...state,
        keys: keys.map((k) => {
          if (k.contracts)
            k.contracts = k.contracts.sort((a, b) => {
              return new Date(a.start) - new Date(b.start);
            });
          return k;
        }),
        deviceError: null,
        apiError: null,
        loading: false,
        savingReservation: false,
      };
    case KEYS_STATUS_FETCHED:
      const keyStatus = action.data;

      return {
        ...state,
        keys: keyStatus.map((k) => {
          if (k.contracts)
            k.contracts = k.contracts.sort((a, b) => {
              return new Date(a.start) - new Date(b.start);
            });
          return k;
        }),
        deviceError: null,
        apiError: null,
        loading: false,
        savingReservation: false,
      };
    case KEY_STATUS_FETCHED:
      return {
        ...state,
        keyStatus: action.data,
        deviceError: null,
        apiError: null,
        keyStatusLoading: false,
      };
    case KEYS_SETKEY_SUCCESS:
      state.error === 'Connection timeout' ? (err = state.error) : (err = null);
      return {
        ...state,
        deviceError: err,
        keySet: action.data,
        loading: false,
        saving: false,
      };
    case KEY_GET_SUCCESS:
      return {
        ...state,
        deviceError: null,
        apiError: null,
        fetchedKey: action.data,
        loading: false,
      };
    case KEYS_SETKEY_RESET:
      return {
        ...state,
        deviceError: null,
        apiError: null,
        keySet: null,
        loading: false,
        fetchedKey: null,
      };
    case KEYS_UPDATEKEY_SUCCESS:
      return {
        ...state,
        deviceError: err,
        apiError: null,
        loading: false,
        saving: false,
      };
    case KEYS_RESET:
      return {
        ...state,
        deviceError: null,
        apiError: null,
        keys: [],
        loading: false,
      };
    case KEYS_KEYSTATUS_RESET:
      return {
        ...state,
        keyStatusLoading: false,
        keyStatus: null,
      };

    case KEYS_SETKEY_FAILED:
    case KEYS_FAILED:
    case KEYS_UPSERTFAILED:
    case KEYS_DELKEY_FAILED:
    case KEYS_STATUS_FAILED:
    case KEY_STATUS_FAILED:
    case KEY_GET_FAILED:
    case KEYS_UPDATEKEY_FAILED:
    case KEYS_UPDATE_BACKUPCODES_FAILED:
      console.log('KEY FAILED', action);
      return {
        ...state,
        loading: false,
        keyStatusLoading: false,
        saving: false,
        apiError: action.data
          ? action.data.data ||
            action.data.message ||
            (action.data.error && action.data.error.message) ||
            action.data.error
          : 'KeyAPI Internal Error',
        savingReservation: false,
      };
    case KEY_APARTMENTS_FETCHED:
      return {
        ...state,
        apiError: null,
        apartmentsLoading: false,
        apartments: action.data,
      };
    case KEY_APARTMENTS_FAILED:
      return {
        ...state,
        apiError: action.data,
        apartmentsLoading: false,
        apartments: [],
      };
    case KEY_APARTMENT_FAILED:
      return {
        ...state,
        apiError: action.data,
        apartmentLoading: false,
        apartment: null,
      };
    case KEY_APARTMENT_NOT_FOUND:
      return {
        ...state,
        apiError: null,
        apartmentLoading: false,
        apartment: null,
        apartmentError: action.data,
      };
    case KEY_APARTMENT_LOADING:
      return {
        ...state,
        // apiError: null,
        apartmentLoading: true,
        apartmentError: null,
      };
    case KEY_APARTMENT_FETCHED:
      return {
        ...state,
        // apiError: null,
        apartmentLoading: false,
        apartment: action.data,
        apartmentError: null,
      };
    case KEY_APARTMENT_RESET:
      return {
        ...state,
        //apiError: null,
        apartmentLoading: false,
        apartment: null,
        apartmentError: null,
      };
    default:
      return state;
  }
}
