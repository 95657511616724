// import { EventApi, AlarmApi } from '../utils/networkService';
import gql from 'graphql-tag';
import cloneDeep from 'lodash/cloneDeep';
import {
  extractDeviceData,
  extractDevicesData,
  actions as actionList,
} from '../utils/deviceData';
import {
  alarmInfoFragment,
  alarmEventHistoryFragment,
  alarmRoutingHistoryFragment,
} from '../api/fragments/alarms';
import { pageInfoFragment } from '../api/fragments/common';
import { GET_ALARMS } from '../api/queries/alarms';

const USAGE_REPORT_LOADING = 'logs/USAGE_REPORT_LOADING';
const USAGE_REPORT_FETCHED = 'logs/USAGE_REPORT_FETCHED';
const USAGE_REPORT_FAILED = 'logs/USAGE_REPORT_FAILED';

const ACTIVE_ALARMS_LOADING = 'logs/ACTIVE_ALARMS_LOADING';
const ACTIVE_ALARMS_FETCHED = 'logs/ACTIVE_ALARMS_FETCHED';
const ACTIVE_ALARMS_FAILED = 'logs/ACTIVE_ALARMS_FAILED';

const ALARM_DEACTIVATED_SUCCEEDED = 'logs/ALARM_DEACTIVATED_SUCCEEDED';
const ALARM_DEACTIVATED_FAILED = 'logs/ALARM_DEACTIVATED_FAILED';

const EVENTS_RESET = 'logs/EVENTS_RESET';

export const eventInfoFragment = gql`
  fragment eventInfo on Event {
    id
    timestamp
    source
    sourceId
    tag
    action
    anonymized
  }
`;

export const eventDataFragment = gql`
  fragment eventData on Event {
    originator {
      id
      email
      name
      type
    }
  }
`;

export const eventOriginatorFragment = gql`
  fragment eventOriginator on Event {
    data {
      text
      sourceTime
      value
      before
      after
    }
  }
`;

export const GET_EVENTS = gql`
  query events($after: String, $limit: Int, $filter: EventFilter, $sort: JSON) {
    events(after: $after, limit: $limit, filter: $filter, sort: $sort) {
      edges {
        ...eventInfo
        ...eventData
        ...eventOriginator
      }
      pageInfo {
        ...pageInfo
      }
    }
  }
  ${eventInfoFragment}
  ${eventDataFragment}
  ${eventOriginatorFragment}
  ${pageInfoFragment}
`;

export const getUsageReport = (
  client,
  devices,
  actions,
  startDate,
  endDate,
  all,
  tag,
  translate
) => async (dispatch, getState) => {
  let usageReport = [];
  console.log('getUsageReport', devices, actions, startDate, endDate, tag);
  dispatch({ type: USAGE_REPORT_LOADING });
  try {
    const res = await client.query({
      query: gql`
        query keyUsageReport(
          $devices: [String]!
          $actions: [String]!
          $timerange: Timerange
        ) {
          keyUsageReport(
            devices: $devices
            actions: $actions
            timerange: $timerange
          ) {
            _id
            devices {
              deviceId
              actions {
                action
                count
              }
            }
          }
        }
      `,
      fetchPolicy: 'network-only',
      variables: {
        devices: devices.map((d) => d.id),
        actions,
        timerange: { gte: startDate, lte: endDate },
      },
    });
    console.log(
      'getUsageReport - USAGE_REPORT_FETCHED ',
      res.data.keyUsageReport
    );
    const rawUsageReport = res.data.keyUsageReport;
    const filledUsageReport = cloneDeep(rawUsageReport);
    //filling default data
    devices.forEach((dev) => {
      const tagIndex = filledUsageReport.findIndex((t) => t._id === dev.tag);
      if (tagIndex < 0) {
        filledUsageReport.push({
          _id: dev.tag,
          devices: [{ id: dev.id, name: dev.name }],
        });
      } else {
        if (
          !filledUsageReport[tagIndex].devices.some(
            (d) => d.deviceId === dev.id
          )
        ) {
          filledUsageReport[tagIndex].devices.push({
            id: dev.id,
            name: dev.name,
          });
        }
      }
      filledUsageReport.forEach((t) => {
        t.devices.forEach((d) => {
          if (d.deviceId === dev.id) {
            d.actions.forEach((a) => (d[a.action] = a.count));

            Object.assign(d, { id: d.deviceId, name: dev.name, ...d });
            delete d.actions;
            delete d.deviceId;
          }
          actionList(getState().auth.authenticated).forEach((action) => {
            if (!d.hasOwnProperty(action)) {
              d[action] = 0;
            }
          });
        });
      });
    });
    if (all) {
      usageReport = await extractDevicesData(filledUsageReport, translate);
    } else {
      usageReport = await extractDeviceData(filledUsageReport, tag);
    }
    dispatch({
      type: USAGE_REPORT_FETCHED,
      data: usageReport,
    });
    return usageReport;
  } catch (err) {
    console.log('getUsageReport - USAGE_REPORT_FAILED', err.message);
    dispatch({ type: USAGE_REPORT_FAILED, data: err.message });
    throw err;
  }
};

export const getActiveAlarms = (client, devices, tag, ruleList) => async (
  dispatch
) => {
  console.log('getActiveAlarms', devices);
  dispatch({ type: ACTIVE_ALARMS_LOADING });
  try {
    let promises = devices.map(async (d) => {
      const res = await client.query({
        query: GET_ALARMS,
        fetchPolicy: 'no-cache',
        variables: {
          filter: {
            sourceId: JSON.stringify({ $regex: `^${d}` }),
            end: null,
            ruleId: JSON.stringify({ $in: ruleList.map((r) => r.value) }),
            priority: 'high',
            tag,
          },
          after: '0',
          limit: 0,
          sort: { start: -1 },
        },
      });
      return res.data.alarms.edges;
    });
    const res = await Promise.all(promises);

    const activeAlarms = [];
    res.forEach((r) => r.forEach((e) => activeAlarms.push(e)));

    dispatch({
      type: ACTIVE_ALARMS_FETCHED,
      data: activeAlarms,
    });
  } catch (err) {
    console.log('getActiveAlarms - ACTIVE_ALARMS_FAILED', err.message);
    dispatch({ type: ACTIVE_ALARMS_FAILED, data: err.message });
    throw err;
  }
};
export const getLocationsActiveAlarms = (
  client,
  devices,
  tag,
  ruleList
) => async (dispatch) => {
  dispatch({ type: ACTIVE_ALARMS_LOADING });
  try {
    let promises = devices.map(async (d) => {
      const res = await client.query({
        query: GET_ALARMS,
        fetchPolicy: 'no-cache',
        variables: {
          filter: {
            sourceId: JSON.stringify({ $regex: `^${d}` }),
            end: null,
            ruleId:
              ruleList && JSON.stringify({ $in: ruleList.map((r) => r.value) }),
            priority: 'high',
          },
          after: '0',
          limit: 0,
          sort: { start: -1 },
        },
      });
      return res.data.alarms.edges;
    });
    const res = await Promise.all(promises);

    const activeAlarms = [];
    res.forEach((r) => r.forEach((e) => activeAlarms.push(e)));

    dispatch({
      type: ACTIVE_ALARMS_FETCHED,
      data: activeAlarms,
    });
  } catch (err) {
    console.log('getActiveAlarms - ACTIVE_ALARMS_FAILED', err.message);
    dispatch({ type: ACTIVE_ALARMS_FAILED, data: err.message });
    throw err;
  }
};
export const deactivateAlarm = (client, id, input) => async (dispatch) => {
  console.log('deactivateAlarm', id);

  try {
    const res = await client.mutate({
      mutation: gql`
        mutation deactivateAlarm(
          $filter: AlarmFilter!
          $input: AlarmAckInput!
        ) {
          deactivateAlarm(filter: $filter, input: $input) {
            ...alarmInfo
            ...alarmEventHistory
            ...alarmRoutingHistory
          }
        }
        ${alarmInfoFragment}
        ${alarmEventHistoryFragment}
        ${alarmRoutingHistoryFragment}
      `,
      variables: {
        filter: { id },
        input,
      },
    });
    dispatch({
      type: ALARM_DEACTIVATED_SUCCEEDED,
      data: res.data.deactivateAlarm,
    });
    return res.data.deactivateAlarm;
  } catch (err) {
    console.log('deactivateAlarm - ALARM_DEACTIVATED_FAILED', err.message);
    dispatch({ type: ALARM_DEACTIVATED_FAILED, data: err.message });
    throw err;
  }
};

export const resetEvents = () => (dispatch) => dispatch({ type: EVENTS_RESET });

const initialState = {
  usageReport: [],
  activeAlarms: [],
  loading: false,
  error: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ACTIVE_ALARMS_LOADING:
    case USAGE_REPORT_LOADING:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case USAGE_REPORT_FETCHED:
      return {
        ...state,
        usageReport: action.data,
        error: null,
        loading: false,
      };
    case ACTIVE_ALARMS_FETCHED:
      return {
        ...state,
        activeAlarms: action.data,
        error: null,
        loading: false,
      };
    case EVENTS_RESET: {
      return {
        ...state,
        events: [],
        usageReport: [],
        activeAlarms: [],
        loading: false,
        error: null,
      };
    }
    case ACTIVE_ALARMS_FAILED:
    case USAGE_REPORT_FAILED:
      return {
        ...state,
        error: action.data,
        loading: false,
      };

    default:
      return state;
  }
}
