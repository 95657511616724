import Cookies from 'universal-cookie';
// import { LcpApi } from '../utils/networkService';
// import axios from 'axios';

import {
  authUser,
  logout,
  fetchAndsetAuthTokenToCookie,
  getUserClaims,
} from '../components/connect-react-lib';
const AUTH_SUCCESS = 'auth/SUCCESS';
const AUTH_LOADING = 'auth/LOADING';
const AUTH_FAILED = 'auth/FAILED';
export const AUTH_LOGOUT = 'auth/LOGOUT';

const cookies = new Cookies();
export async function loginFirebase() {}
export async function logoutFirebase() {
  /*if (firebase.auth().currentUser) {
    //cookies.remove("fbtoken", { path: "/" });
    await firebase.auth().signOut();
  }*/
}

export const loginUser = () => async (dispatch) => {
  try {
    const user = authUser();
    const claims = await getUserClaims();
    await fetchAndsetAuthTokenToCookie();
    let idTokenResult = await user.getIdTokenResult();

    dispatch({
      type: AUTH_SUCCESS,
      id: user.uid,
      exp: idTokenResult.expirationTime,
      username: user.email,
      displayName: user.displayName,
      role: claims.role,
      userTag: claims.userTags && claims.userTags[0],
    });
  } catch (e) {
    console.log(e.message);
    throw e;
  }
};

export const fetchUser = () => async (dispatch) => {
  dispatch({ type: AUTH_LOADING });

  try {
    const user = authUser();
    if (user) {
      const claims = await getUserClaims();
      let idTokenResult = await user.getIdTokenResult();
      dispatch({
        type: AUTH_SUCCESS,
        id: user.uid,
        exp: idTokenResult.expirationTime,
        username: user.email,
        displayName: user.displayName,
        role: claims.role,
        userTag: claims.userTags && claims.userTags[0],
      });
    } else {
      dispatch({ type: AUTH_FAILED, data: 'user not found' });
    }
  } catch (e) {
    console.log(e.message);
    dispatch({ type: AUTH_FAILED, data: e.message });
    throw e;
  }
};

export const signOut = (reqData) => (dispatch) => {
  dispatch({ type: AUTH_LOGOUT });
  logout();
};

const username = cookies.get('username');
const role = cookies.get('role');
const userTag = cookies.get('userTag');
const userId = cookies.get('userId');
const userDisplayName = cookies.get('userDisplayName');
const initialState = {
  authenticated: username || null,
  role: role || null,
  userTag: userTag || null,
  userId: userId || null,
  userDisplayName: userDisplayName || null,
  loading: false,
  error: null,
};

//console.log("initialState", initialState);

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case AUTH_LOADING:
      return {
        ...state,
        loading: true,
      };

    case AUTH_SUCCESS:
      cookies.set('username', action.username, { path: '/' });
      cookies.set('role', action.role, { path: '/' });
      cookies.set('userTag', action.userTag, { path: '/' });
      cookies.set('userId', action.id, { path: '/' });
      cookies.set('userDisplayName', action.displayName, { path: '/' });
      return {
        ...state,
        authenticated: action.username,
        userId: action.id,
        userDisplayName: action.displayName,
        role: action.role,
        userTag: action.userTag,
        loading: false,
      };
    case AUTH_FAILED:
      return { ...state, error: action.data, loading: false };
    case AUTH_LOGOUT:
      return {
        ...state,
        authenticated: null,
        userId: null,
        userDisplayName: null,
        role: null,
        userTag: null,
        loading: false,
      };
    default:
      return state;
  }
}
