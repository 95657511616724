import gql from 'graphql-tag';

import { userInfoFragment } from '../fragments/users';

export const GET_USER = gql`
  query user($id: String!) {
    user(id: $id) {
      ...userInfo
    }
  }
  ${userInfoFragment}
`;
