import React from 'react';
import { Link } from 'react-router-dom';

export const actions = (username) =>
  username && username.indexOf('@livion.fi') > -1
    ? [
        'key-added',
        'key-fetched',
        'key-returned',
        'key-removed',
        'contract-added',
        'contract-modified',
        'contract-removed',
        'contract-sms-status',
        'notification-sms-status',
        'device-right-code',
        'device-wrong-code',
        'device-right-code-wrong-time',
        'device-right-code-wrong-time-early',
        'device-right-code-returned-late',
        'device-right-return-code',
      ]
    : [
        'key-fetched',
        'key-returned',
        'contract-added',
        'contract-modified',
        'contract-sms-status',
        'notification-sms-status',
        'device-right-code',
        'device-wrong-code',
        'device-right-code-wrong-time',
        'device-right-code-wrong-time-early',
        'device-right-return-code',
      ];

export const tagColumns = (username, t) =>
  username.indexOf('@livion.fi') > -1
    ? [
        {
          Header: '',
          accessor: 'tag',
          width: 250,
          resizable: false,
          style: { fontWeight: 600 },
        },
        {
          Header: '',
          accessor: 'name',
          width: 40,
          resizable: false,
          style: { textAlign: 'center' },
        },
        {
          Header: '',
          accessor: 'id',
          width: 55,
          resizable: false,
          style: { textAlign: 'center' },
        },
        {
          Header: t('keyAdded'),
          accessor: 'key-added',
          width: 70,
          resizable: false,
          style: { textAlign: 'center' },
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: t('keyFetched'),
          accessor: 'key-fetched',
          width: 70,
          resizable: false,
          style: { textAlign: 'center' },
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: t('keyReturned'),
          accessor: 'key-returned',
          width: 70,
          resizable: false,
          style: { textAlign: 'center' },
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: t('keyRemoved'),
          accessor: 'key-removed',
          width: 70,
          resizable: false,
          style: { textAlign: 'center' },
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: t('contractAdded'),
          accessor: 'contract-added',
          width: 70,
          resizable: false,
          style: { textAlign: 'center' },
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: t('contractModified'),
          accessor: 'contract-modified',
          width: 70,
          resizable: false,
          style: { textAlign: 'center' },
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: t('contractRemoved'),
          accessor: 'contract-removed',
          width: 70,
          resizable: false,
          style: { textAlign: 'center' },
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: t('smsSent'),
          accessor: 'sms-status-sent',
          width: 70,
          resizable: false,
          style: { textAlign: 'center' },
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: t('smsDelivered'),
          accessor: 'sms-status-delivered',
          width: 70,
          resizable: false,
          style: { textAlign: 'center' },
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: t('codeRight'),
          accessor: 'device-right-code',
          width: 70,
          resizable: false,
          style: { textAlign: 'center' },
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: t('codeWrong'),
          accessor: 'device-wrong-code',
          width: 70,
          resizable: false,
          style: { textAlign: 'center' },
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: t('wrongTime'),
          accessor: 'device-right-code-wrong-time',
          width: 70,
          resizable: false,
          style: { textAlign: 'center' },
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: t('tooEarly'),
          accessor: 'device-right-code-wrong-time-early',
          width: 70,
          resizable: false,
          style: { textAlign: 'center' },
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: t('returnedLate'),
          accessor: 'device-right-code-returned-late',
          width: 70,
          resizable: false,
          style: { textAlign: 'center' },
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: t('returnCodeRight'),
          accessor: 'device-right-return-code',
          width: 70,
          resizable: false,
          style: { textAlign: 'center' },
          Cell: (props) => (props.value ? props.value : 0),
        },
      ]
    : [
        {
          Header: '',
          accessor: 'tag',
          width: 250,
          resizable: false,
          style: { fontWeight: 600 },
        },
        {
          Header: '',
          accessor: 'name',
          width: 40,
          resizable: false,
          style: { textAlign: 'center' },
        },
        {
          Header: '',
          accessor: 'id',
          width: 55,
          resizable: false,
          style: { textAlign: 'center' },
        },
        {
          Header: t('keyFetched'),
          accessor: 'key-fetched',
          width: 70,
          resizable: false,
          style: { textAlign: 'center' },
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: t('keyReturned'),
          accessor: 'key-returned',
          width: 70,
          resizable: false,
          style: { textAlign: 'center' },
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: t('contractAdded'),
          accessor: 'contract-added',
          width: 70,
          resizable: false,
          style: { textAlign: 'center' },
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: t('contractModified'),
          accessor: 'contract-modified',
          width: 70,
          resizable: false,
          style: { textAlign: 'center' },
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: t('smsSent'),
          accessor: 'sms-status-sent',
          width: 70,
          resizable: false,
          style: { textAlign: 'center' },
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: t('smsDelivered'),
          accessor: 'sms-status-delivered',
          width: 70,
          resizable: false,
          style: { textAlign: 'center' },
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: t('codeRight'),
          accessor: 'device-right-code',
          width: 70,
          resizable: false,
          style: { textAlign: 'center' },
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: t('codeWrong'),
          accessor: 'device-wrong-code',
          width: 70,
          resizable: false,
          style: { textAlign: 'center' },
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: t('wrongTime'),
          accessor: 'device-right-code-wrong-time',
          width: 70,
          resizable: false,
          style: { textAlign: 'center' },
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: t('tooEarly'),
          accessor: 'device-right-code-wrong-time-early',
          width: 70,
          resizable: false,
          style: { textAlign: 'center' },
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: t('returnCodeRight'),
          accessor: 'device-right-return-code',
          width: 70,
          resizable: false,
          style: { textAlign: 'center' },
          Cell: (props) => (props.value ? props.value : 0),
        },
      ];

export const deviceColumns = (username, t) =>
  username.indexOf('@livion.fi') > -1
    ? [
        {
          Header: t('keyAdded'),
          accessor: 'key-added',
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: t('keyFetched'),
          accessor: 'key-fetched',
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: t('keyReturned'),
          accessor: 'key-returned',
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: t('keyRemoved'),
          accessor: 'key-removed',
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: t('contractAdded'),
          accessor: 'contract-added',
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: t('contractModified'),
          accessor: 'contract-modified',
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: t('contractRemoved'),
          accessor: 'contract-removed',
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: t('smsSent'),
          accessor: 'sms-status-sent',
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: t('smsDelivered'),
          accessor: 'sms-status-delivered',
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: t('codeRight'),
          accessor: 'device-right-code',
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: t('codeWrong'),
          accessor: 'device-wrong-code',
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: t('wrongTime'),
          accessor: 'device-right-code-wrong-time',
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: t('tooEarly'),
          accessor: 'device-right-code-wrong-time-early',
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: t('returnedLate'),
          accessor: 'device-right-code-returned-late',
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: t('returnCodeRight'),
          accessor: 'device-right-return-code',
          Cell: (props) => (props.value ? props.value : 0),
        },
      ]
    : [
        {
          Header: t('keyFetched'),
          accessor: 'key-fetched',
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: t('keyReturned'),
          accessor: 'key-returned',
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: t('contractAdded'),
          accessor: 'contract-added',
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: t('contractModified'),
          accessor: 'contract-modified',
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: t('smsSent'),
          accessor: 'sms-status-sent',
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: t('smsDelivered'),
          accessor: 'sms-status-delivered',
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: t('codeRight'),
          accessor: 'device-right-code',
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: t('codeWrong'),
          accessor: 'device-wrong-code',
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: t('wrongTime'),
          accessor: 'device-right-code-wrong-time',
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: t('tooEarly'),
          accessor: 'device-right-code-wrong-time-early',
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: t('returnCodeRight'),
          accessor: 'device-right-return-code',
          Cell: (props) => (props.value ? props.value : 0),
        },
      ];

export const devicesColumns = (username, t) =>
  username.indexOf('@livion.fi') > -1
    ? [
        {
          Header: t('deviceName'),
          accessor: 'name',
          width: 300,
          resizable: false,
          style: { fontWeight: 400 },
        },
        {
          Header: t('deviceId'),
          accessor: 'id',
          width: 70,
          resizable: false,
          style: { fontWeight: 400 },
          Cell: (props) => (
            <Link to={`/devices/${props.value}/stats`}>{props.value}</Link>
          ),
        },
        {
          Header: '',
          // Header: 'key-added',
          accessor: 'key-added',
          width: 70,
          resizable: false,
          style: { textAlign: 'center' },
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: '',
          // Header: 'key-fetched',
          accessor: 'key-fetched',
          width: 70,
          resizable: false,
          style: { textAlign: 'center' },
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: '',
          // Header: 'key-returned',
          accessor: 'key-returned',
          width: 70,
          resizable: false,
          style: { textAlign: 'center' },
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: '',
          // Header: 'key-removed',
          accessor: 'key-removed',
          width: 70,
          resizable: false,
          style: { textAlign: 'center' },
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: '',
          //Header: 'contract-added',
          accessor: 'contract-added',
          width: 70,
          resizable: false,
          style: { textAlign: 'center' },
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: '',
          //Header: 'contract-modified',
          accessor: 'contract-modified',
          width: 70,
          resizable: false,
          style: { textAlign: 'center' },
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: '',
          //Header: 'contract-removed',
          accessor: 'contract-removed',
          width: 70,
          resizable: false,
          style: { textAlign: 'center' },
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: '',
          //Header: 'sms sent',
          accessor: 'sms-status-sent',
          width: 70,
          resizable: false,
          style: { textAlign: 'center' },
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: '',
          //Header: 'sms delivered',
          accessor: 'sms-status-delivered',
          width: 70,
          resizable: false,
          style: { textAlign: 'center' },
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: '',
          //Header: 'right-code',
          accessor: 'device-right-code',
          width: 70,
          resizable: false,
          style: { textAlign: 'center' },
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: '',
          //Header: 'wrong-code',
          accessor: 'device-wrong-code',
          width: 70,
          resizable: false,
          style: { textAlign: 'center' },
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: '',
          //Header: 'wrong-time',
          accessor: 'device-right-code-wrong-time',
          width: 70,
          resizable: false,
          style: { textAlign: 'center' },
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: '',
          //Header: 'wrong-time-early',
          accessor: 'device-right-code-wrong-time-early',
          width: 70,
          resizable: false,
          style: { textAlign: 'center' },
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: '',
          //Header: 'returned-late',
          accessor: 'device-right-code-returned-late',
          width: 70,
          resizable: false,
          style: { textAlign: 'center' },
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: '',
          // Header: 'returned code right',
          accessor: 'device-right-return-code',
          width: 70,
          resizable: false,
          style: { textAlign: 'center' },
          Cell: (props) => (props.value ? props.value : 0),
        },
      ]
    : [
        {
          Header: t('deviceName'),
          accessor: 'name',
          width: 300,
          resizable: false,
          style: { fontWeight: 400 },
        },
        {
          Header: t('deviceId'),
          accessor: 'id',
          width: 70,
          resizable: false,
          style: { fontWeight: 400 },
          Cell: (props) => (
            <Link to={`/devices/${props.value}/stats`}>{props.value}</Link>
          ),
        },
        {
          Header: '',
          // Header: 'key-fetched',
          accessor: 'key-fetched',
          width: 70,
          resizable: false,
          style: { textAlign: 'center' },
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: '',
          // Header: 'key-returned',
          accessor: 'key-returned',
          width: 70,
          resizable: false,
          style: { textAlign: 'center' },
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: '',
          //Header: 'contract-added',
          accessor: 'contract-added',
          width: 70,
          resizable: false,
          style: { textAlign: 'center' },
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: '',
          //Header: 'contract-modified',
          accessor: 'contract-modified',
          width: 70,
          resizable: false,
          style: { textAlign: 'center' },
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: '',
          //Header: 'sms sent',
          accessor: 'sms-status-sent',
          width: 70,
          resizable: false,
          style: { textAlign: 'center' },
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: '',
          //Header: 'sms delivered',
          accessor: 'sms-status-delivered',
          width: 70,
          resizable: false,
          style: { textAlign: 'center' },
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: '',
          //Header: 'right-code',
          accessor: 'device-right-code',
          width: 70,
          resizable: false,
          style: { textAlign: 'center' },
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: '',
          //Header: 'wrong-code',
          accessor: 'device-wrong-code',
          width: 70,
          resizable: false,
          style: { textAlign: 'center' },
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: '',
          //Header: 'wrong-time',
          accessor: 'device-right-code-wrong-time',
          width: 70,
          resizable: false,
          style: { textAlign: 'center' },
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: '',
          //Header: 'wrong-time-early',
          accessor: 'device-right-code-wrong-time-early',
          width: 70,
          resizable: false,
          style: { textAlign: 'center' },
          Cell: (props) => (props.value ? props.value : 0),
        },
        {
          Header: '',
          // Header: 'returned code right',
          accessor: 'device-right-return-code',
          width: 70,
          resizable: false,
          style: { textAlign: 'center' },
          Cell: (props) => (props.value ? props.value : 0),
        },
      ];

export const extractDeviceData = (usageReport, tag) => {
  console.log('extractDeviceData', usageReport, tag);
  let stat = {};
  const arr = usageReport.filter((t) => t._id === tag);
  stat = arr.length && arr[0].devices && arr[0].devices[0];
  delete stat.__typename;
  console.log('extracted device data', stat);
  return [stat];
};

export const extractDevicesData = (usageReport, translate) => {
  console.log('extractDevicesData', usageReport);
  const devicesStats = [];
  let total = {};
  // count sum for each tag
  usageReport.forEach((ta) => {
    const actions = {};
    ta.devices.forEach((device) => {
      delete device.__typename;
      //sum action to tag actions
      Object.keys(device).forEach((key) => {
        if (key !== 'id' && key !== 'name' && key !== '__typename') {
          if (actions.hasOwnProperty(key)) {
            actions[key] += device[key];
          } else {
            actions[key] = device[key];
          }
          if (total.hasOwnProperty(key)) {
            total[key] += device[key];
          } else {
            total[key] = device[key];
          }
        }
      });
    });
    console.log(`actions for tag ${ta._id} is `, actions);
    devicesStats.push({ tag: ta._id, ...actions, devices: ta.devices });
  });
  devicesStats.unshift({ tag: translate('Total'), ...total });
  console.log('extracted usageReport', devicesStats);

  return devicesStats;
};
