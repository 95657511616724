import gql from 'graphql-tag';

export const apartmentInfoFragment = gql`
  fragment apartmentInfo on Apartment {
    id
    address
    postCode
    city
    countryCode
    timezone
    buildingId
    officeName
  }
`;

export const GET_APARTMENT = gql`
  query apartment($id: String!) {
    apartment(id: $id) {
      ...apartmentInfo
    }
  }
  ${apartmentInfoFragment}
`;
export const GET_APARTMENTS = gql`
  query apartments($filter: ApartmentFilter, $limit: Int) {
    apartments(filter: $filter, limit: $limit) {
      edges {
        ...apartmentInfo
      }
    }
  }
  ${apartmentInfoFragment}
`;
