import { LivionKeyApi } from '../utils/networkService';
import { reset } from 'redux-form';
import gql from 'graphql-tag';
import moment from 'moment-timezone';
import _get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import {
  times,
  stringTime,
  languageList,
  contractTypeList,
} from '../utils/utils';

const CONTRACTS_LOADING = 'contracts/LOADING';
const CONTRACTS_DEVICE_CONNECTING = 'contracts/DEVICE_CONNECTING';
const CONTRACTS_DEVICE_CONNECTED = 'contracts/DEVICE_CONNECTED';
const CONTRACTS_DEVICE_ERROR = 'contracts/DEVICE_ERROR';
const CONTRACTS_FETCHED = 'contracts/FETCHED';
const CONTRACT_FETCHED = 'contracts/CONTRACT_FETCHED';
const CONTRACTS_SAVING = 'contracts/SAVING';
const CONTRACT_CREATING = 'contract/CREATING';
const CONTRACT_CREATED = 'contract/CREATED';
const CONTRACT_UPDATING = 'contract/UPDATING';
const CONTRACT_UPDATED = 'contract/UPDATED';
const CONTRACT_DELETING = 'contract/DELETING';
const CONTRACT_DELETED = 'contract/DELETED';
export const CONTRACTS_SAVED = 'contracts/CONTRACT_SAVED';
export const CONTRACTS_ADDED = 'contracts/CONTRACTS_ADDED';
const CONTRACTS_DELETED = 'contracts/DELETED';
const CONTRACTS_FAILED = 'contracts/FAILED';
export const CONTRACTS_RESET = 'contract/RESET';
const CONTRACTS_TAG_FETCHED = 'contracts/TAG_FETCHED';
// const CONTRACTS_MAIL_SENT = "contract/CONTRACTS_MAIL_SENT";
const CONTRACTS_STATUS_CHANGED = 'contract/CONTRACTS_STATUS_CHANGED';
const CONTRACTS_CLEAR_ERROR = 'contract/CONTRACTS_CLEAR_ERROR';
const RESERVATION_LOADING = 'contracts/RESERVATION_LOADING';
const RESERVATION_FETCHED = 'contracts/RESERVATION_FETCHED';
const RESERVATION_DEFAULT_FETCHED = 'contracts/RESERVATION_DEFAULT_FETCHED';
const RESERVATION_NOT_FOUND = 'contracts/RESERVATION_NOT_FOUND';
const RESERVATION_OVERLAP = 'contracts/RESERVATION_OVERLAP';
const RESERVATION_FAILED = 'contracts/RESERVATION_FAILED';
const RESERVATION_RESET = 'contracts/RESERVATION_RESET';
const RESERVATIONS_RESET = 'contracts/RESERVATIONS_RESET';
const RESERVATIONS_LOADING = 'contracts/RESERVATIONS_LOADING';
const RESERVATIONS_FETCHED = 'contracts/RESERVATIONS_FETCHED';
const RESERVATIONS_FAILED = 'contracts/RESERVATIONS_FAILED';
const RESERVATIONS_SYNCING = 'contracts/RESERVATIONS_SYNCING';
const RESERVATIONS_SYNCED = 'contracts/RESERVATIONS_SYNCED';
export const resetContract = (device) => (dispatch) => {
  console.log('contracts - destroying device Connection');
  device.realTimeConnection && device.realTimeConnection.destroy();
  device.realTimeConnection && device.realTimeConnection.removeAllListeners();
  dispatch({ type: CONTRACTS_RESET });
};
export const resetLocationContract = () => (dispatch) => {
  dispatch({ type: CONTRACTS_RESET });
};

export const resetError = () => (dispatch) => {
  console.log('contracts - Clearing error');
  dispatch({ type: CONTRACTS_CLEAR_ERROR });
};

export const getContracts = (deviceId) => (dispatch) => {
  dispatch({ type: CONTRACTS_LOADING });
  dispatch(reset('reserve'));
  //getContracts and sort them
  return LivionKeyApi.get(`contracts/`, { params: { deviceId } })
    .then((res) => {
      dispatch({
        type: CONTRACTS_FETCHED,
        data: res.data,
      });
      return res.data;
    })
    .catch((err) => {
      dispatch({ type: CONTRACTS_FAILED, data: err.response });
      throw err;
    });
};

export const getAllContracts = (client, filter) => async (dispatch) => {
  // console.log('getDevices');
  dispatch({ type: CONTRACTS_LOADING });
  try {
    const res = await client.query({
      query: gql`
        query contracts(
          $filter: ContractFilter
          $sort: JSON
          $after: String
          $limit: Int
        ) {
          contracts(
            filter: $filter
            sort: $sort
            after: $after
            limit: $limit
          ) {
            edges {
              id
              devices {
                id
                name
                keyId
              }
              contractId
              person
              contacts {
                sendSms
                sendEmail
                language
                phoneNumber
                email
              }
              perm
              recurrent {
                startTime
                endTime
                days
              }
              start
              end
              pincode
              freeText
            }
          }
        }
      `,
      variables: { filter, limit: 0, sort: { start: -1 } },
    });

    dispatch({
      type: CONTRACTS_FETCHED,
      data: res.data.contracts.edges,
    });
  } catch (err) {
    dispatch({ type: CONTRACTS_FAILED, data: err.message });
    throw err;
  }
};

export const GET_CONTRACTS = gql`
  query contracts(
    $filter: ContractFilter
    $sort: JSON
    $after: String
    $limit: Int
  ) {
    contracts(filter: $filter, sort: $sort, after: $after, limit: $limit) {
      edges {
        id
        tag
        devices {
          id
          name
          keyId
        }
        contractId
        person
        contacts {
          sendSms
          sendEmail
          language
          phoneNumber
          email
        }
        perm
        recurrent {
          startTime
          endTime
          days
        }
        start
        end
        pincode
        freeText
      }
      pageInfo {
        start
        end
        hasNext
      }
    }
  }
`;

export const createContract = (client, input) => async (dispatch) => {
  dispatch({ type: CONTRACT_CREATING });
  console.log('adding: ', client, input);
  try {
    const res = await client.mutate({
      mutation: gql`
        mutation createContract($input: ContractCreateInput!) {
          createContract(input: $input) {
            id
            tag
            devices {
              id
              keyId
            }
            contractId
            person
            contacts {
              sendSms
              sendEmail
              language
              phoneNumber
              email
            }
            perm
            recurrent {
              startTime
              endTime
              days
            }
            start
            end
            pincode
            freeText
          }
        }
      `,
      variables: { input },
    });
    dispatch({
      type: CONTRACT_CREATED,
      data: res.data.createContract,
    });
  } catch (err) {
    dispatch({ type: CONTRACTS_FAILED, data: err.message });
    throw err;
  }
};
export const editContract = (client, id, input) => async (dispatch) => {
  dispatch({ type: CONTRACT_UPDATING });
  console.log('adding: ', client, input);
  try {
    const res = await client.mutate({
      mutation: gql`
        mutation updateContract($id: ID!, $input: ContractUpdateInput!) {
          updateContract(id: $id, input: $input) {
            id
            tag
            contractId
            devices {
              id
              keyId
            }
            start
            end
            perm
            recurrent {
              startTime
              endTime
              days
            }
            person
            contacts {
              email
              phoneNumber
              language
              sendSms
              sendEmail
            }
            pincode
            freeText
          }
        }
      `,
      variables: { id, input },
    });
    dispatch({
      type: CONTRACT_UPDATED,
      data: res.data.updateContract,
    });
  } catch (err) {
    dispatch({ type: CONTRACTS_FAILED, data: err.message });
    throw err;
  }
};
export const deleteContract = (client, id, cancellation) => async (
  dispatch
) => {
  console.log('deleting contract', id);
  dispatch({ type: CONTRACT_DELETING });
  try {
    const res = await client.mutate({
      mutation: gql`
        mutation deleteContract($id: ID!, $cancellation: Boolean) {
          deleteContract(id: $id, cancellation: $cancellation)
        }
      `,
      variables: { id, cancellation },
    });
    dispatch({
      type: CONTRACT_DELETED,
      data: res.data.deleteContract,
    });
  } catch (err) {
    dispatch({ type: CONTRACTS_FAILED, data: err.message });
    throw err;
  }
};
export const getContract = (client, id, postFormat, devices, t) => async (
  dispatch
) => {
  console.log('GET_CONTRACT', id, devices);
  dispatch({ type: CONTRACTS_LOADING });
  dispatch(reset('reserve'));
  //getContracts and sort them
  try {
    const res = await client.query({
      query: gql`
        query contract($id: ID!) {
          contract(id: $id) {
            id
            tag
            devices {
              id
              keyId
            }
            contractId
            person
            contacts {
              name
              sendSms
              sendEmail
              language
              phoneNumber
              email
            }
            recurrent {
              startTime
              endTime
              days
            }
            perm
            start
            end
            pincode
            freeText
            type
          }
        }
      `,
      variables: { id },
      fetchPolicy: 'no-cache',
    });
    console.log('contract fetched', res.data.contract);
    let languages = languageList(t);
    let types = contractTypeList(t);
    if (postFormat) {
      const formattedContract = cloneDeep(res.data.contract);
      formattedContract.start = moment(formattedContract.start);
      formattedContract.end = moment(formattedContract.end);
      delete formattedContract.tag;
      delete formattedContract.__typename;

      if (devices && devices.length) {
        formattedContract.devices = formattedContract.devices.map((d) => {
          const device = devices.filter((device) => device.id === d.id)[0];
          return { label: device.name, value: d.id, keyId: d.keyId };
        });
      }
      if (formattedContract.contacts)
        formattedContract.contacts.forEach((c) => {
          c.language = languages[c.language || 'en-gb'];
        });
      if (formattedContract.recurrent)
        formattedContract.recurrent.forEach((r) => {
          r.startTime = stringTime.find((s) => s.value === r.startTime);
          r.endTime = stringTime.find((s) => s.value === r.endTime);
        });
      if (formattedContract.type)
        formattedContract.type = types.find(
          (t) => t.value === formattedContract.type
        );
      dispatch({
        type: CONTRACT_FETCHED,
        data: formattedContract,
      });
    } else {
      dispatch({
        type: CONTRACT_FETCHED,
        data: res.data.contract,
      });
    }
  } catch (err) {
    dispatch({ type: CONTRACTS_FAILED, data: err.message });
    throw err;
  }
};

export const addContract = (contract) => (dispatch) => {
  dispatch({ type: CONTRACTS_SAVING });
  console.log('adding: ', contract);
  return LivionKeyApi.post(`contracts/`, contract)
    .then((res) => {
      dispatch({
        type: CONTRACTS_SAVED,
        data: res.data,
      });
      dispatch(reset('reserve'));
      return res.data;
    })
    .catch((err) => {
      dispatch({ type: CONTRACTS_FAILED, data: err });
      throw err;
    });
};
export const syncReservations = (client, reservations) => async (dispatch) => {
  dispatch({ type: RESERVATIONS_SYNCING });
  console.log('syncing reservations: ', reservations);
  let contractStatus = {};
  for (const reservation of reservations) {
    const formattedReservation = {
      apartmentId: reservation.apartmentId,
      start: reservation.start,
      end: reservation.end,
      freeText: reservation.freeText,
      keys: reservation.keys || [],
      contacts: (reservation.contacts || []).map((c) => {
        delete c.__typename;
        return c;
      }),
    };
    try {
      const res = await client.mutate({
        mutation: gql`
          mutation upsertReservation(
            $id: ID!
            $input: ReservationUpdateInput!
            $cancel: Boolean
          ) {
            upsertReservation(id: $id, input: $input, cancel: $cancel) {
              id
              apartmentId
              keys
              start
              end
              contacts {
                name
                email
                phoneNumber
                sendSms
                sendEmail
                language
              }
              syncError
              pincode
              contracts
            }
          }
        `,
        variables: { id: reservation.id, input: formattedReservation },
      });

      contractStatus[reservation.id] = {
        error: null,
        contract: res.data.upsertReservation,
      };
    } catch (err) {
      contractStatus[reservation.id] = {
        error: err.message,
        contract: null,
      };
    }
  }

  console.log('adding contracts: ', contractStatus);
  dispatch({ type: RESERVATIONS_SYNCED, data: contractStatus });
  return contractStatus;
};

export const updateContract = (id, contract) => (dispatch) => {
  dispatch({ type: CONTRACTS_SAVING });
  //  console.log("updating: ", contract);
  return LivionKeyApi.post(`contracts/${id}`, contract)
    .then((res) => {
      dispatch({
        type: CONTRACTS_SAVED,
        data: res.data,
      });
      dispatch(reset('reserve'));
      return res;
    })
    .catch((err) => {
      dispatch({ type: CONTRACTS_FAILED, data: err });
      throw err;
    });
};

export const removeContract = (id, cancellation) => (dispatch) => {
  dispatch({ type: CONTRACTS_LOADING });
  let params = cancellation
    ? {
        cancellation,
      }
    : {};
  return LivionKeyApi.delete(`contracts/${id}`, {
    params,
  })
    .then((res) => {
      dispatch({
        type: CONTRACTS_DELETED,
        data: res.data,
      });
      return res;
    })
    .catch((err) => {
      dispatch({ type: CONTRACTS_FAILED, data: err });
      return err;
    });
};
export const getDefaultReservation = (device, t) => async (
  dispatch,
  getState
) => {
  dispatch({ type: RESERVATION_LOADING });
  console.log('getting default reservation: ');
  try {
    let defaultContract = null;

    if (device && device.meta) {
      const start = _get(device, 'meta.arrivalHour', '11');
      const end = _get(device, 'meta.departureHour', '16');
      defaultContract = {
        startHour: device.meta && {
          value: device.meta.arrivalHour,
          label: times
            .find((t) => t.value.toString() === start.toString())
            .label.toString(),
        },
        endHour: device.meta && {
          value: device.meta.departureHour,
          label: times
            .find((t) => t.value.toString() === end.toString())
            .label.toString(),
        },
        contacts: [
          {
            language: {
              value: `${
                device.meta && device.meta.language
                  ? device.meta.language
                  : 'en-gb'
              }`,
              label: `${
                device.meta && device.meta.language
                  ? t(device.meta.language)
                  : t('en-gb')
              }`,
            },
          },
        ],
        devices: [{ value: device.id, label: device.name }],
      };
      if (
        getState().ui.business === 'access' &&
        !device.meta.useBasicAccessRight
      ) {
        defaultContract.recurrent = [
          {
            startTime: stringTime.find((s) => s.value === '08:00'),
            endTime: stringTime.find((s) => s.value === '17:00'),
            days: {
              0: true,
              1: true,
              2: true,
              3: true,
              4: true,
              5: false,
              6: false,
            },
          },
        ];
      }
    }

    dispatch({
      type: RESERVATION_DEFAULT_FETCHED,
      data: defaultContract,
    });
  } catch (err) {
    dispatch({ type: RESERVATION_FAILED, data: err.message });
    throw err;
  }
};
export const getReservation = (client, id, device, t, contracts) => async (
  dispatch
) => {
  dispatch({ type: RESERVATION_LOADING });
  console.log('getting reservation: ', id);
  try {
    const res = await client.query({
      query: gql`
        query reservation($id: ID!) {
          reservation(id: $id) {
            id
            apartmentId
            keys
            start
            end
            contacts {
              name
              email
              phoneNumber
              sendSms
              sendEmail
              language
            }
            freeText
            contracts
          }
        }
      `,
      variables: { id },
    });
    let reservation = cloneDeep(res.data.reservation);
    let languages = languageList(t);
    // if no reservation add error
    if (!reservation) {
      dispatch({ type: RESERVATION_NOT_FOUND, data: t('ReservationNotFound') });
    } else {
      const startH = _get(device, 'meta.arrivalHour', '11');
      const endH = _get(device, 'meta.departureHour', '16');

      const formattedReservation = {
        contractId: reservation.id,
        devices: [{ value: device.id, label: device.name }],
        start: moment(reservation.start)
          .startOf('day')
          .add(parseFloat(startH), 'hours'),
        end: moment(reservation.end)
          .startOf('day')
          .add(parseFloat(endH), 'hours'),
        contacts: reservation.contacts.map((c) => {
          c.language = languages[c.language] || languages['en-gb'];
          delete c.__typename;
          return c;
        }),
        freeText: reservation.freeText,
        person: (_get(reservation, 'contacts', [])[0] || {}).name,
        perm: !reservation.end,
        recurrent: reservation.recurrent,
      };
      dispatch({
        type: RESERVATION_FETCHED,
        data: formattedReservation,
      });

      let reservationOverlap = false;
      let { start, end } = formattedReservation;
      start = new Date(start);
      end = end && moment(end).isValid() && new Date(end);
      contracts.forEach((c) => {
        if (c.contractId !== formattedReservation.contractId) {
          let oldStart = new Date(c.start);
          let oldEnd = c.end && new Date(c.end);

          if (!oldEnd) {
            console.log(
              'oldEnd null',
              (end &&
                moment(end).isValid() &&
                end.getTime() > oldStart.getTime()) ||
                !end
            );
            if (
              (end &&
                moment(end).isValid() &&
                end.getTime() > oldStart.getTime()) ||
              !end
            ) {
              console.log('end null');
              reservationOverlap = true;
            }
          } else if (!end && start.getTime() < oldEnd.getTime()) {
            reservationOverlap = true;
          } else if (
            start.getTime() < oldEnd.getTime() &&
            end.getTime() > oldStart.getTime()
          ) {
            reservationOverlap = true;
          }
        }
      });

      if (reservationOverlap) {
        dispatch({
          type: RESERVATION_OVERLAP,
          data: t('ReservationOverlapping'),
        });
      }
    }
    // if reservation format
  } catch (err) {
    if (err.message === 'access denied') {
      dispatch({
        type: RESERVATION_NOT_FOUND,
        data: t('ReservationAccessDenied'),
      });
    } else {
      dispatch({ type: RESERVATION_FAILED, data: err.message });
      throw err;
    }
  }
};

export const resetReservation = () => async (dispatch) =>
  dispatch({ type: RESERVATION_RESET });
export const resetReservations = () => async (dispatch) =>
  dispatch({ type: RESERVATIONS_RESET });

export const getReservations = (client, filter) => async (dispatch) => {
  dispatch({ type: RESERVATIONS_LOADING });
  console.log('getting reservations for appartment: ', filter);
  try {
    const res = await client.query({
      query: gql`
        query reservations($filter: ReservationFilter) {
          reservations(filter: $filter, limit: 0) {
            edges {
              id
              apartmentId
              keys
              start
              end
              contacts {
                name
                email
                phoneNumber
                sendSms
                sendEmail
                language
              }
              freeText
              contracts
            }
          }
        }
      `,
      variables: { filter },
    });
    console.log('RESERVATIONS FETCHED', res);
    dispatch({
      type: RESERVATIONS_FETCHED,
      data: res.data.reservations.edges,
    });
  } catch (err) {
    dispatch({ type: RESERVATIONS_FAILED, data: err.message });
    throw err;
  }
};

const initialState = {
  status: '',
  device: {},
  contracts: [],
  contract: {},
  keys: [],
  mailId: null,
  loading: false,
  connecting: false,
  connected: false,
  saving: false,
  deviceError: null,
  apiError: null,
  reservationLoading: false,
  reservationsLoading: false,
  reservation: null,
  reservations: [],
  reservationsError: null,
  defaultReservation: null,
  reservationsSyncing: false,
  reservationsStatus: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CONTRACTS_CLEAR_ERROR:
      return {
        ...state,
        apiError: null,
        reservationError: null,
      };
    case CONTRACTS_LOADING:
      return {
        ...state,
        deviceError: null,
        apiError: null,
        loading: true,
      };
    case CONTRACTS_STATUS_CHANGED:
      if (action.data.status === 'codeTimeout') {
        delete state.keypad;
      }
      return {
        ...state,
        ...action.data,
        loading: false,
      };
    case CONTRACTS_DEVICE_CONNECTING:
      return {
        ...state,
        deviceError: null,
        connected: false,
        status: 'connecting',
        connecting: true,
      };
    case CONTRACTS_DEVICE_CONNECTED:
      const device = state.device;
      device.realTimeConnection = action.data;
      return {
        ...state,
        deviceError: null,
        status: 'connected',
        connecting: false,
        connected: true,
        device: device,
      };
    case CONTRACTS_DEVICE_ERROR:
      return {
        ...state,
        deviceError: action.data,
        status: '',
        connected: false,
        connecting: false,
      };
    case CONTRACTS_FETCHED:
      return {
        ...state,
        contracts: action.data,
        apiError: null,
        loading: false,
      };
    case CONTRACT_FETCHED:
      return {
        ...state,
        contract: action.data,
        apiError: null,
        loading: false,
      };

    case CONTRACTS_SAVING:
    case CONTRACT_CREATING:
    case CONTRACT_UPDATING:
    case CONTRACT_DELETING:
      return {
        ...state,
        apiError: null,
        saving: true,
      };
    case CONTRACTS_SAVED:
    case CONTRACT_CREATED:
    case CONTRACT_UPDATED:
    case CONTRACT_DELETED: {
      return {
        ...state,
        apiError: null,
        saving: false,
      };
    }
    case RESERVATIONS_SYNCING: {
      return {
        ...state,
        apiError: null,
        reservationsSyncing: true,
      };
    }
    case RESERVATIONS_SYNCED: {
      return {
        ...state,
        apiError: null,
        reservationsSyncing: false,
        reservationsStatus: action.data,
      };
    }
    case CONTRACTS_DELETED: {
      return {
        ...state,
        apiError: null,
        loading: false,
      };
    }
    case CONTRACTS_RESET: {
      return {
        ...state,
        device: {},
        devices: [],
        contracts: [],
        contract: {},
        keys: [],
        mailId: null,
        loading: false,
        connecting: false,
        connected: false,
        saving: false,
        apiError: null,
        deviceError: null,
        reservationError: null,
        defaultReservation: null,
        status: '',
      };
    }
    case CONTRACTS_FAILED:
      console.log('contracts error', action.data);
      return {
        ...state,
        apiError: action.data
          ? (action.data.response && action.data.response.data) ||
            action.data.data ||
            action.data.message ||
            (action.data.error && action.data.error.message) ||
            action.data.error
          : 'KeyAPI Internal Error',
        saving: false,
      };
    case CONTRACTS_TAG_FETCHED:
      return {
        ...state,
        deviceTag: action.data,
        apiError: null,
        loading: false,
      };
    case RESERVATION_LOADING:
      return {
        ...state,
        //apiError: null,
        reservationLoading: true,
        reservationError: null,
      };
    case RESERVATION_DEFAULT_FETCHED:
      return {
        ...state,
        // apiError: null,
        reservationLoading: false,
        defaultReservation: action.data,
        reservationError: null,
      };
    case RESERVATION_FETCHED:
      return {
        ...state,
        // apiError: null,
        reservationLoading: false,
        reservation: action.data,
        reservationError: null,
      };
    case RESERVATION_FAILED:
      return {
        ...state,
        apiError: action.data,
        reservationLoading: false,
        reservation: null,
      };
    case RESERVATION_NOT_FOUND:
      return {
        ...state,
        apiError: null,
        reservationLoading: false,
        reservation: null,
        reservationError: action.data,
      };
    case RESERVATION_OVERLAP:
      return {
        ...state,
        apiError: null,
        reservationLoading: false,
        reservationError: action.data,
      };
    case RESERVATION_RESET:
      return {
        ...state,
        //apiError: null,
        reservationLoading: false,
        reservationError: null,
        reservation: null,
      };
    case RESERVATIONS_LOADING:
      return {
        ...state,
        //apiError: null,
        reservationsLoading: true,
        reservationsError: null,
      };

    case RESERVATIONS_FETCHED:
      return {
        ...state,
        // apiError: null,
        reservationsLoading: false,
        reservations: action.data,
        reservationsError: null,
      };
    case RESERVATIONS_FAILED:
      return {
        ...state,
        apiError: action.data,
        reservationsError: action.data,
        reservationsLoading: false,
        reservations: null,
      };
    case RESERVATIONS_RESET:
      return {
        ...state,
        //apiError: null,
        reservationLoading: false,
        reservationError: null,
        reservation: null,
        reservationsStatus: {},
        reservationsSyncing: false,
      };

    default:
      return state;
  }
}
