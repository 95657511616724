import { InMemoryCache } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import { setContext } from 'apollo-link-context';
import { createHttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { history } from '../../utils/history';
import { ERROR } from '../../redux/Error';
import { setupConnectApi } from '../../utils/utils';
import Cookies from 'universal-cookie';

import { authLib } from './authentication';
import { xAuthMode } from './AppRunMode';
import { setAuthTokenToCookie } from './utils';
// let fb = firebase;
// if (window.Cypress) {
//   // Firebase needed in Cypress context
//   fb = require('../contexts/FirebaseContext/mocked-firebase');

//   //firebase = window.firebase;
// }

const cookies = new Cookies();
let xTag = cookies.get('x-tag');
let xRole = cookies.get('x-role');
let xAdmin = cookies.get('x-admin');

let api = setupConnectApi();

try {
  if (!api && window.Cypress) {
    /**Cypress configuration, used only when in Cypress context**/
    api = window.Cypress.env().REACT_APP_CONNECTAPI;
  }
} catch (e) {
  // Do nothing
}

const uri = `${api}/graphql`;

const httpLink = createHttpLink({
  uri: uri,
});

const authLink = setContext(({ operationName }, { headers }) => {
  if (operationName === 'refreshIdToken') return headers;
  return authLib.getIdToken(false).then((idToken) => {
    // Make sure we save cookie here only when it exist, signOut is handled in common-apps
    if (idToken) setAuthTokenToCookie(idToken);

    let authHeaders = {
      'x-auth': idToken ? idToken : '',
      'x-auth-type': 'user',
      'x-app': 'livionkey',
    };
    if (xAuthMode === 'localAuth') {
      authHeaders['x-local-auth'] = '1';
    }
    if (xTag) {
      authHeaders['x-tag'] = xTag;
      if (xRole) authHeaders['x-role'] = xRole;
      if (xAdmin) {
        authHeaders['x-admin'] = '1';
      }
    }
    return {
      headers: {
        ...authHeaders,
        ...headers,
      },
    };
  });
});

const errorLink = (store) =>
  onError(({ graphQLErrors, networkError }) => {
    let errors = [];
    let forbidden = false;
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        if (message === 'forbidden') {
          errors.push({ code: 403, message: 'Access forbiden' });
          cookies.remove('x-tag', {
            path: '/',
          });
          cookies.remove('x-role', {
            path: '/',
          });
          forbidden = true;
        } else {
          errors.push({ code: 500, message });
        }
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        );
      });
    }
    if (networkError && networkError.result && networkError.result.errors) {
      console.log(`[Network error]: ${networkError}`);
      networkError.result.errors.forEach(({ message }) =>
        errors.push({ code: networkError.statusCode, message })
      );
    }
    if (errors.length) {
      store.dispatch({ type: ERROR, data: errors });
      if (forbidden) {
        window.location.replace('/forbidden');
      } else {
        history.push('/error');
      }
    }
  });

const apolloClient = (store) =>
  new ApolloClient({
    link: ApolloLink.from([errorLink(store), authLink, httpLink]),
    cache: new InMemoryCache(),
  });

const setTag = (newTag, role) => {
  if (!newTag) {
    cookies.remove('x-tag', {
      path: '/',
    });
    cookies.remove('x-role', {
      path: '/',
    });
  } else {
    cookies.set('x-tag', newTag, { path: '/' });
    if (role) {
      cookies.set('x-role', role, { path: '/' });
    }
  }
  xTag = newTag;
  xRole = role;
};

const getTag = () => {
  return xTag;
};

export { apolloClient, setTag, getTag };
