import React, { Component } from 'react';
import {
  getIdToken,
  getCurrentUser,
  logout,
  getUserNotification,
  setUserNotification,
} from '../../connect-react-lib';
import has from 'lodash/has';
import set from 'lodash/set';
import connectComm from '@livion/connect2-communication';
//let connectComm;
const { Provider, Consumer } = React.createContext(null);

const devices = {};

export class LivionConnectProvider extends Component {
  state = {
    user: getCurrentUser(),
    // In here we modify pages that user sees
    navigationItems: [],
    device: null,
  };

  render() {
    return (
      <Provider
        value={{
          state: { ...this.state, ...this.props.state },
          actions: {
            ...this.props.actions,
            refreshUser: () => this.setState({ user: getCurrentUser() }),
            logout: logout,
            connectDevice: async (device, user, business) => {
              //if (!connectComm)
              //connectComm = await import('@livion/connect2-communication/src');
              const authFunc = async () => {
                return await getIdToken();
              };
              console.log('connecting device', device.id);
              const token = await getIdToken();
              if (!devices[device.id]) {
                const source = business === 'key' ? 'key30-ui' : 'access-ui';
                devices[device.id] = new connectComm(
                  token,
                  device.id,
                  device.socketServer,
                  authFunc,
                  source,
                  user
                );
              }
              await devices[device.id].connect();
              const v = await devices[device.id].version();
              devices[device.id].appName = v.appName;
              devices[device.id].appVersion = v.appVersion;
              this.setState({ device: devices[device.id] });
              return devices[device.id];
            },
            disconnectDevice: async (id) => {
              //if (!connectComm)
              //connectComm = await import('@livion/connect2-communication/src');
              console.log('disconnecting device', id);
              if (devices[id]) {
                devices[id].destroy();
                devices[id] = null;
              }
              this.setState({ device: null });
            },
            decodeQueryString: (queryString) => {
              const splitedQueryString = queryString.split('&');
              const decodedQueryString = {};

              for (const param of splitedQueryString) {
                let [key, value] = param.split('=');
                value = value ? (value === 'null' ? null : value) : '';
                if (has(decodedQueryString, key)) {
                  const currentValueForKey = decodedQueryString[key];
                  if (!Array.isArray(currentValueForKey)) {
                    set(decodedQueryString, key, [currentValueForKey, value]);
                  } else {
                    currentValueForKey.push(value);
                  }
                } else {
                  set(decodedQueryString, key, value);
                }
              }

              return decodedQueryString[''] === '' ? null : decodedQueryString;
            },
            encodeQueryString: function encode(queryObj, nesting = '') {
              const pairs = Object.entries(queryObj).map(([key, val]) => {
                if (!val) {
                  return [nesting + key, 'null'].map(escape).join('=');
                } else if (Array.isArray(val)) {
                  return val
                    .map((subVal) =>
                      [nesting + key, subVal].map(escape).join('=')
                    )
                    .join('&');
                } else if (typeof val === 'object') {
                  return encode(val, nesting + `${key}.`);
                } else {
                  return [nesting + key, val].map(escape).join('=');
                }
              });
              return pairs.join('&');
            },
            setQueryString: (history, queryString) => {
              history.replace(`${history.location.pathname}?${queryString}`);
            },
            getUserNotification: getUserNotification,
            setUserNotification: setUserNotification,
          },
        }}
      >
        {this.props.children}
      </Provider>
    );
  }
}

export const withLivionConnect = (Component) =>
  class AppProvider extends React.Component {
    render() {
      return (
        <Consumer>
          {(value) => <Component {...this.props} {...value} />}
        </Consumer>
      );
    }
  };
