import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import IconSvg from '../Icon/IconSvg';
import info from '../../images/info-banner.svg';
import news from '../../images/news-banner.svg';
import update from '../../images/update-banner.svg';
import warning from '../../images/warning-banner.svg';
import incidentNetwork from '../../images/incidentNetwork-banner.svg';
import incidentMaintenance from '../../images/incidentMaintenance-banner.svg';
import incidentBroken from '../../images/incidentBroken-banner.svg';
import resolution from '../../images/resolution-banner.svg';
import infoW from '../../images/info-banner-white.svg';
import newsW from '../../images/news-banner-white.svg';
import updateW from '../../images/update-banner-white.svg';
import warningW from '../../images/warning-banner-white.svg';
import incidentNetworkW from '../../images/incidentNetwork-banner-white.svg';
import incidentMaintenanceW from '../../images/incidentMaintenance-banner-white.svg';
import incidentBrokenW from '../../images/incidentBroken-banner-white.svg';
import resolutionW from '../../images/resolution-banner-white.svg';

export function duration(duration) {
  const milliseconds = parseInt((duration % 1000) / 100, 10),
    seconds = parseInt((duration / 1000) % 60, 10),
    minutes = parseInt((duration / (1000 * 60)) % 60, 10),
    hours = parseInt((duration / (1000 * 60 * 60)) % 24, 10);

  if (minutes === 0 && hours === 0 && seconds === 0) return `${milliseconds}ms`;
  if (minutes === 0 && hours === 0) return `${seconds}s`;
  if (hours === 0) return `${minutes}m ${seconds}s`;

  return `${hours}h ${minutes}m ${seconds}s`;
}

export const getIconFromType = (type, white) => {
  switch (type) {
    case 'info':
      return <IconSvg size={32} svg={white ? infoW : info} wide />;
    case 'news':
      return <IconSvg size={32} svg={white ? newsW : news} />;
    case 'update':
      return <IconSvg size={32} svg={white ? updateW : update} />;
    case 'warning':
      return <IconSvg size={32} svg={white ? warningW : warning} />;
    case 'incident-network':
      return (
        <IconSvg size={32} svg={white ? incidentNetworkW : incidentNetwork} />
      );
    case 'incident-maintenance':
      return (
        <IconSvg
          size={32}
          svg={white ? incidentMaintenanceW : incidentMaintenance}
        />
      );
    case 'incident-broken':
      return (
        <IconSvg size={32} svg={white ? incidentBrokenW : incidentBroken} />
      );
    case 'resolution':
      return <IconSvg size={32} svg={white ? resolutionW : resolution} />;

    default:
      return <IconSvg size={32} svg={info} />;
  }
};

export const getPeripheralType = (type) => {
  switch (type) {
    case 'Ble220VACPowerControlUnit':
      return 'LockControl';
    case 'BleLockController':
      return 'LockControl';
    case 'BleLockController2':
      return 'LockControl';
    case 'BleWirelessKeypadWithConstantPowerUnit':
      return 'KeyPad';
    case 'BleWirelessKeypad':
      return 'KeyPad';
    case 'BleThinKeypad':
      return 'KeyPad';
    default:
      return 'Not supported';
  }
};

export const getReadableBleRssi = (rssi, t) => {
  const isBad = rssi <= -85;
  const isAverage = rssi > -85 && rssi <= -75;
  const isGood = rssi > -75 && rssi < 0;
  const rssiBool = { isBad, isAverage, isGood };
  if (isBad) {
    return { ...rssiBool, rssiMessage: t('BadSignal'), rssiColor: '#fb3535' };
  }
  if (isAverage) {
    return {
      ...rssiBool,
      rssiMessage: t('AverageSignal'),
      rssiColor: '#ffbb78',
    };
  }
  if (isGood) {
    return { ...rssiBool, rssiMessage: t('GoodSignal'), rssiColor: '#56CA31' };
  }
  return { ...rssiBool, rssiMessage: t('N/A'), rssiColor: '#7C7C7C' };
};

export const getReadableMobileRssi = (value, t) => {
  let rssi = value;
  if (rssi && typeof rssi === 'string' && rssi.includes('=')) {
    rssi = rssi.split('=')[1];
  }
  console.log('rssi', rssi);
  const isBad = rssi <= -95;
  const isAverage = rssi > -95 && rssi <= -70;
  const isGood = rssi > -70 && rssi < 0;
  const rssiBool = { isBad, isAverage, isGood };
  if (isBad) {
    return { ...rssiBool, rssiMessage: t('BadSignal'), rssiColor: '#fb3535' };
  }
  if (isAverage) {
    return {
      ...rssiBool,
      rssiMessage: t('AverageSignal'),
      rssiColor: '#ffbb78',
    };
  }
  if (isGood) {
    return { ...rssiBool, rssiMessage: t('GoodSignal'), rssiColor: '#56CA31' };
  }
  return { ...rssiBool, rssiMessage: t('N/A'), rssiColor: '#7C7C7C' };
};

export const getLanguageForCommonApps = (i18n) => {
  if (!i18n) return 'en';
  return i18n.language.substring(0, 2); // always return 2 chars
};

export const getContractLabel = (contract, t) => {
  return (
    <Row
      style={{
        marginBottom: 5,
      }}
      start="xs"
    >
      <Col xs={12}>
        {contract?.contacts && contract?.contacts[0]?.name
          ? `${t('contractId')}: ${contract.contractId} - ${
              contract?.contacts[0]?.name
            }`
          : `${t('contractId')}: ${contract.contractId} - ${t('pin')}: ${
              contract?.pincode
            }`}
      </Col>
      <Col xs={12}>
        {!contract.perm
          ? `${contract.start.format(
              'DD/MM/YYYY HH:mm'
            )} - ${contract.end.format('DD/MM/YYYY HH:mm')}`
          : `${contract.start.format('DD/MM/YYYY HH:mm')} - ${t('permanent')}`}
      </Col>
    </Row>
  );
};
