import { LivionKeyApi } from '../utils/networkService';
import axios from 'axios';
import gql from 'graphql-tag';
import _get from 'lodash/get';
// import Cookies from 'universal-cookie';
// import ConnectDevice from '@livion/connect-communication/dist/index';
import cloneDeep from 'lodash/cloneDeep';
import virtualLocker from '../images/virtualLocker.jpeg';
import virtualKeyXLocker from '../images/virtualKeyXLocker.jpeg';
import virtualFront from '../images/virtualFront.jpeg';
import { getIdToken } from '../components/connect-react-lib';
import connectComm from '@livion/connect2-communication';
import { convertContractsToDeviceLocalTime } from '../utils/utils';
import { debugLabels } from '../utils/debugLabels';
import { sleep } from '../utils/utils';
import moment from 'moment-timezone';
import { KEYS_STATUS_FETCHED } from './Keys';

let takingPhoto = false;
const OPERATION_TIMEOUT = 30000; //30 secs

// const cookies = new Cookies();
const DEVICES_FETCHED = 'devices/FETCHED';
const DEVICE_FETCHED = 'devices/DEVICE_FETCHED';
const DEVICE_FEATURES_FOUND = 'devices/DEVICE_FEATURES_FOUND';
const DEVICES_RULES_FETCHED = 'devices/DEVICES_RULES_FETCHED';
const DEVICES_LOADING = 'devices/LOADING';
const DEVICES_FAILED = 'devices/FAILED';
const DEVICE_CONNECTED = 'devices/DEVICE_CONNECTED';
const DEVICE_CONNECTING = 'devices/DEVICE_CONNECTING';
const DEVICE_DISCONNECTING = 'devices/DEVICE_DISCONNECTING';
const DEVICE_DISCONNECTED = 'devices/DEVICE_DISCONNECTED';
const DEVICE_CONNECTION_CHANGED = 'devices/DEVICE_CONNECTION_CHANGED';
const DEVICE_ERROR = 'devices/DEVICE_ERROR';
const DEVICE_DESTROY = 'devices/DEVICE_DESTROY';
const DEVICES_TAG_FETCHED = 'devices/TAG_FETCHED';
const DEVICEPING_SUCCESS = 'devices/DEVICEPING_SUCCESS';
const DEVICEPING_FAILED = 'devices/DEVICEPING_FAILED';
const STATUS_CHANGED = 'devices/STATUS_CHANGED';
const STATUS_FETCHED = 'status/FETCHED';

const STATUS_FAILED = 'status/FAILED';
const DEVICE_RESET = 'devices/RESET';
const DEVICES_RESET = 'devices/DEVICES_RESET';
const CLEAR_ERROR = 'devices/CLEAR_ERROR';

const DEVICES_SAVING = 'devices/DEVICES_SAVING';
const DEVICES_SAVED = 'devices/DEVICES_SAVED';

const TOAST_SHOW = 'devices/TOAST_SHOW';
const TOAST_HIDE = 'devices/TOAST_HIDE';

const TRACE_CHANGED = 'devices/TRACE_CHANGED';

const SENDCOMMAND_SUCCESS = 'devices/SENDCOMMAND_SUCCESS';
const SENDCOMMAND_FAILED = 'devices/SENDCOMMAND_FAILED';

const PHOTO_LOADING = 'devices/PHOTO_LOADING';
const PHOTO_FETCHED = 'devices/PHOTO_FETCHED';
const PHOTO_FAILED = 'devices/PHOTO_FAILED';
const RESETPHOTO = 'devices/RESETPHOTO';
const OPENLOCKER_SUCCESS = 'devices/OPENLOCKER_SUCCESS';
const OPENLOCKER_FAILED = 'devices/OPENLOCKER_FAILED';
const ENTERPINCODE_SUCCESS = 'devices/ENTERPINCODE_SUCCESS';
const ENTERPINCODE_FAILED = 'devices/ENTERPINCODE_FAILED';
const LOCK_SUCCESS = 'devices/LOCK_SUCCESS';
const LOCK_FAILED = 'devices/LOCK_FAILED';
const UNLOCK_SUCCESS = 'devices/UNLOCK_SUCCESS';
const UNLOCK_FAILED = 'devices/UNLOCK_FAILED';

const CLOSELOCKER_SUCCESS = 'devices/CLOSELOCKER_SUCCESS';
const CLOSELOCKER_FAILED = 'devices/CLOSELOCKER_FAILED';
const SENDNUMPADKEY_SUCCESS = 'devices/SENDNUMPADKEY_SUCCESS';
const SENDNUMPADKEY_FAILED = 'devices/SENDNUMPADKEY_FAILED';

const TRACE_SUBSCRIBED = 'devices/TRACE_SUBSCRIBED';
const TRACE_CLEARED = 'devices/TRACE_CLEARED';
const TRACE_LABELS_CHANGED = 'devices/TRACE_LABELS_CHANGED';
const TRACE_LABELS_FETCHED = 'devices/TRACE_LABELS_FETCHED';

const DETECTLOCKER_SUCCESS = 'devices/DETECTLOCKER_SUCCESS';
const DETECTLOCKER_FAILED = 'devices/DETECTLOCKER_FAILED';

const FORCELOCKER_SUCCESS = 'devices/FORCELOCKER_SUCCESS';
const FORCELOCKER_FAILED = 'devices/FORCELOCKER_FAILED';

const UPDATEKEYSTATUS_LOADING = 'devices/UPDATEKEYSTATUS_LOADING';
const UPDATEKEYSTATUS_SUCCESS = 'devices/UPDATEKEYSTATUS_SUCCESS';
const UPDATEKEYSTATUS_FAILED = 'devices/UPDATEKEYSTATUS_FAILED';

const UPDATEREFERENCEIMAGE_LOADING = 'devices/UPDATEREFERENCEIMAGE_LOADING';
const UPDATEREFERENCEIMAGE_SUCCESS = 'devices/UPDATEREFERENCEIMAGE_SUCCESS';
const UPDATEREFERENCEIMAGE_FAILED = 'devices/UPDATEREFERENCEIMAGE_FAILED';

const DOTEMPLATE_SUCCESS = 'devices/DOTEMPLATE_SUCCESS';
const DOTEMPLATE_FAILED = 'devices/DOTEMPLATE_FAILED';

const TESTMODE_SUCCESS = 'devices/TESTMODE_SUCCESS';
const TESTMODE_FAILED = 'devices/TESTMODE_FAILED';

const INSERTMODE_SUCCESS = 'devices/INSERTMODE_SUCCESS';
const INSERTMODE_FAILED = 'devices/INSERTMODE_FAILED';

const REBOOT_SUCCESS = 'devices/REBOOT_SUCCESS';
const REBOOT_FAILED = 'devices/REBOOT_FAILED';

const OPENLOCKERKEYINSERTMODE_SUCCESS =
  'devices/OPENLOCKERKEYINSERTMODE_SUCCESS';
const OPENLOCKERKEYINSERTMODE_FAILED = 'devices/OPENLOCKERKEYINSERTMODE_FAILED';

const DEVICE_SKIP_SETUP = 'devices/DEVICE_SKIP_SETUP';
const DEVICE_RESET_SETUP = 'devices/DEVICE_RESET_SETUP';

const LOCATIONS_FETCHED = 'locations/FETCHED';
const LOCATIONS_LOADING = 'locations/LOADING';
const LOCATIONS_FAILED = 'locations/FAILED';

const devices = [];
let showSlowMessage = false;
let maintenance = false;
let hideToastTimer;
let showToastTimer;

const toast = (message, error, warning, timeout, dispatch) => {
  clearTimeout(hideToastTimer);
  clearTimeout(showToastTimer);

  showToastTimer = setTimeout(
    () => dispatch({ type: TOAST_SHOW, data: { message, error, warning } }),
    50
  );
  hideToastTimer = setTimeout(() => dispatch({ type: TOAST_HIDE }), timeout);
};

const populateStatus = (arr, res) => {
  let r = [];

  for (var k = 0; k < arr.length; k++) {
    if (arr[k].keyId) {
      arr[k].keyStatus = {
        value: res[k] && res[k].data ? res[k].data.action : '',
        timestamp: res[k] && res[k].data ? res[k].data.timestamp : '',
      };
    }
    r.push(arr[k]);
  }
  console.log('populated status', r);
  return r;
};

const mergeArrays = (arr1, arr2) => {
  let r = [];
  arr2.forEach((i) => {
    arr1.forEach((key) => {
      if (i.index === key.index) r.push(i);
      else {
        r.push(key);
      }
    });
  });
  return r;
};

export const skipSetup = (id) => (dispatch) =>
  dispatch({ type: DEVICE_SKIP_SETUP, data: id });
export const resetSetup = () => (dispatch) =>
  dispatch({ type: DEVICE_RESET_SETUP });

export const reset = () => (dispatch) => dispatch({ type: DEVICE_RESET });
export const resetDevices = () => (dispatch) =>
  dispatch({ type: DEVICES_RESET });

export const GET_DEVICES_COUNT = gql`
  query counts($tag: String, $products: [String]) {
    devicesCount(tag: $tag, products: $products) {
      total
      connected
      disconnected
      stored
      delivered
      active
      deactivated
      returned
    }
  }
`;
export const GET_ACTIVE_ALARMS_COUNT = gql`
  query activeAlarmsCount($filter: AlarmCountFilter) {
    activeAlarmsCount(filter: $filter)
  }
`;
export const GET_DEVICES = gql`
  query devices(
    $filter: DeviceFilter
    $after: String
    $limit: Int
    $sort: JSON
    $alarmAfter: String
    $alarmLimit: Int
    $alarmSort: JSON
  ) {
    devices(filter: $filter, after: $after, limit: $limit, sort: $sort) {
      edges {
        id
        name
        product
        tag
        product
        socketServer
        virtual
        settings {
          data
        }
        meta
        connectionState
        lifecycleState
        syncGroup {
          id
          devices {
            id
            name
            product
            serial
            relays {
              id
              name
            }
            type
          }
          config {
            keypadStartEarlyAccess
            keypadEndLateAccess
          }
        }
        alarmReceivers {
          mail
          sms
          language
          activation
          deactivation
          rules {
            id
            sendEmail
            sendSms
          }
        }
        notificationReceivers {
          mail
          sms
          language
          activation
          deactivation
          rules {
            id
            sendEmail
            sendSms
          }
        }
        activePublicAlarms(
          limit: $alarmLimit
          after: $alarmAfter
          sort: $alarmSort
        ) {
          edges {
            id
          }
          pageInfo {
            end
          }
        }
      }
      pageInfo {
        hasNext
        start
        end
      }
    }
  }
`;
const connectionStateChange = async (state, d, dispatch) => {
  console.log('Connection change detected', state);
  dispatch({ type: DEVICE_CONNECTION_CHANGED, data: state });
  if (d && state) {
    let lockerStatus;
    try {
      const status = await d.getVariable('status');
      const locker = await d.getVariable('locker');
      const targetLocker = await d.getVariable('targetLocker');
      const testMode = await d.getVariable('testMode');
      const insertMode = await d.getVariable('keyInsertMode');
      const lockerVariable = await d.getVariable('lockerStatus');
      const rssi = await d.getVariable('rssi');
      const wifiStatus = await d.getVariable('wifi');
      lockerStatus = lockerVariable && JSON.parse(lockerVariable);
      dispatch({
        type: STATUS_CHANGED,
        data: {
          status,
          locker,
          targetLocker,
          testMode,
          insertMode,
          lockerStatus,
          rssi,
          wifiStatus,
        },
      });
    } catch (err) {
      dispatch({
        type: DEVICE_ERROR,
        data: err,
      });

      throw err;
    }
  }
};

export const connectDevice = (device, user) => async (dispatch, getState) => {
  let lockerStatus;
  dispatch({ type: DEVICE_CONNECTING });
  try {
    const authFunc = async () => {
      return await getIdToken();
    };
    const token = await getIdToken();
    if (!devices[device.id]) {
      if (!device.virtual) {
        console.log('connecting device', device.id);
        devices[device.id] = new connectComm(
          token,
          device.id,
          device.socketServer,
          authFunc,
          getState().ui.business === 'key' ? 'key30-ui' : 'access-ui',
          user.email
        );
        console.log('realtime connection', devices[device.id]);

        await devices[device.id].connect();
        const v = await devices[device.id]?.version();
        console.log('**************Version', v);
        devices[device.id].appName = v.appName;
        devices[device.id].appVersion = v.appVersion;
        dispatch({
          type: DEVICE_CONNECTED,
          data: devices[device.id],
        });

        devices[device.id].on('connection-state-changed', (state) =>
          connectionStateChange(state, devices[device.id], dispatch)
        );
        devices[device.id].attach();
      } else {
        devices[device.id] = {
          virtual: true,
          appName:
            device.product === 'keyX'
              ? 'keyx-device'
              : device.product === 'keypad'
              ? 'wirepad-device'
              : 'livionkey30',
          appVersion: 'x.x.xx',
          connected: true,
          product: device.product,
        };
        dispatch({
          type: DEVICE_CONNECTED,
          data: devices[device.id],
        });
        dispatch({ type: STATUS_CHANGED, data: { rssi: -67 } });
        return devices[device.id];
      }
    }
    await devices[device.id].connect();
    const v = await devices[device.id].version();
    devices[device.id].appName = v.appName;
    devices[device.id].appVersion = v.appVersion;

    dispatch({
      type: DEVICE_CONNECTED,
      data: devices[device.id],
    });

    devices[device.id].on('connection-state-changed', (state) =>
      connectionStateChange(state, devices[device.id], dispatch)
    );
    devices[device.id].attach();
    //}
  } catch (err) {
    dispatch({
      type: DEVICE_ERROR,
      data: err,
    });
    console.log(`DEVICE_ERROR`, err);
    toast(
      err.message ||
        (err.error && err.error.message) ||
        (typeof err === 'string' && err) ||
        'Device error',
      true,
      false,
      5000,
      dispatch
    );
    throw err;
  }
  if (devices[device.id] && !device.virtual) {
    try {
      const status = await devices[device.id].getVariable('status');
      const locker = await devices[device.id].getVariable('locker');
      const targetLocker = await devices[device.id].getVariable('targetLocker');
      const testMode = await devices[device.id].getVariable('testMode');
      const insertMode = await devices[device.id].getVariable('keyInsertMode');
      const lockerVariable = await devices[device.id].getVariable(
        'lockerStatus'
      );
      const rssi = await devices[device.id].getVariable('rssi');
      const wifiStatus = await devices[device.id].getVariable('wifi');
      lockerStatus = lockerVariable && JSON.parse(lockerVariable);
      dispatch({
        type: STATUS_CHANGED,
        data: {
          status,
          locker,
          targetLocker,
          testMode,
          insertMode,
          lockerStatus,
          rssi,
          wifiStatus,
        },
      });
    } catch (err) {
      dispatch({
        type: DEVICE_ERROR,
        data: err,
      });

      throw err;
    }
    try {
      devices[device.id].on('trace', (t) => {
        dispatch({
          type: TRACE_CHANGED,
          data: { ...t, timestamp: Date.now() },
        });
      });
      devices[device.id].on('variable', (v) => {
        // console.log('new variable', v);
        if (v.name === 'status') {
          dispatch({ type: STATUS_CHANGED, data: { status: v.value } });
        }
        if (v.name === 'locker') {
          dispatch({ type: STATUS_CHANGED, data: { locker: v.value } });
        }
        if (v.name === 'targetLocker') {
          dispatch({
            type: STATUS_CHANGED,
            data: { targetLocker: v.value },
          });
        }
        if (v.name === 'testMode') {
          dispatch({ type: STATUS_CHANGED, data: { testMode: v.value } });
        }
        if (v.name === 'keyInsertMode') {
          dispatch({ type: STATUS_CHANGED, data: { insertMode: v.value } });
        }
        if (v.name === 'lockerStatus') {
          let ls = JSON.parse(v.value);
          console.log('lockerStatus change', ls);
          dispatch({ type: STATUS_CHANGED, data: { lockerStatus: ls } });
          if (lockerStatus && lockerStatus.length) {
            let changedKeyIds = [];
            let reducerKeys = getState().keys.keys;
            lockerStatus.forEach((l, i) => {
              if (l !== ls[i] && reducerKeys[i]) {
                changedKeyIds.push(reducerKeys[i]);
              }
            });
            setTimeout(async () => {
              try {
                const res = await axios.all(
                  changedKeyIds.map(
                    (key) =>
                      key.keyId &&
                      LivionKeyApi.get(`keys/${key.keyId}/status`, {
                        params: { deviceId: device.id },
                      })
                  )
                );

                changedKeyIds = populateStatus(changedKeyIds, res);

                let updatedKeys = mergeArrays(reducerKeys, changedKeyIds);

                dispatch({
                  type: KEYS_STATUS_FETCHED,
                  data: updatedKeys,
                });
              } catch (err) {
                console.error('there was problem loading statuses');
                dispatch({ type: STATUS_FAILED, data: err });
                throw err;
              }
            }, 1000);
          }
          lockerStatus = ls;
        }
        if (v.name === 'rssi') {
          dispatch({ type: STATUS_CHANGED, data: { rssi: v.value } });
        }
        if (v.name === 'wifi') {
          dispatch({ type: STATUS_CHANGED, data: { wifiStatus: v.value } });
        }
      });

      return devices[device.id];
    } catch (err) {
      dispatch({
        type: DEVICE_ERROR,
        data: err,
      });

      throw err;
    }
  }
};

export const disconnectDevice = (id) => async (dispatch) => {
  dispatch({ type: DEVICE_DISCONNECTING });
  try {
    console.log('disconnecting device', id);
    if (devices[id] && !devices[id].virtual) {
      //devices[id].destroy();
      devices[id].removeListener(
        'connection-state-changed',
        connectionStateChange
      );
    }
    devices[id] = null;
    dispatch({ type: DEVICE_DISCONNECTED });
  } catch (err) {
    dispatch({ type: DEVICE_ERROR, data: err });
    throw err;
  }
  //connectComm = await import('@livion/connect2-communication/src');
};

export const takePhoto = (
  realTimeConnection,
  index,
  steps,
  calibrate,
  user,
  forcedUpdatePhoto = false
) => async (dispatch) => {
  if (!takingPhoto) {
    takingPhoto = true;
  } else {
    return;
  }
  dispatch({ type: PHOTO_LOADING });

  try {
    toast('messageSent', false, false, 3000, dispatch);
    const arr = [];
    var start = new Date().getTime();
    const params = steps
      ? { steps, forcedUpdatePhoto }
      : { index, forcedUpdatePhoto };
    if (calibrate) {
      params.calibrate = true;
    }
    if (user) {
      params.user = user;
    }

    console.log('takePhoto', params);
    if (realTimeConnection.virtual) {
      dispatch({
        type: PHOTO_FETCHED,
        data: {
          src:
            index === 'frontcamera'
              ? virtualFront
              : realTimeConnection.product === 'keyX'
              ? virtualKeyXLocker
              : virtualLocker,
          realTimeConnection,
          connected: true,
        },
      });
      takingPhoto = false;
    } else {
      const stream = await realTimeConnection.requestStream(
        'streamPhoto',
        params,
        OPERATION_TIMEOUT
      );
      stream.on('data', (chunk) => {
        arr.push(chunk);
        console.log('chunk', chunk.length);
      });
      stream.on('end', async function () {
        console.log('stream ended:', new Date().getTime() - start, 'ms');
        var blob = new Blob(arr, {
          type: 'image/jpeg',
        });

        let src = window.URL.createObjectURL(blob);
        dispatch({ type: PHOTO_FETCHED, data: { src, realTimeConnection } });
        takingPhoto = false;
      });

      stream.on('error', function (err) {
        console.error(err);
        dispatch({ type: PHOTO_FAILED, data: err });
        takingPhoto = false;
      });
    }
  } catch (err) {
    takingPhoto = false;
    console.error('photo', err);
    if (err.message !== 'Device busy')
      dispatch({ type: PHOTO_FAILED, data: err });
  }
};

export const resetPhoto = () => (dispatch) => dispatch({ type: RESETPHOTO });

export const openLocker = (
  realTimeConnection,
  index,
  user,
  contract,
  iloq,
  keyId
) => (dispatch) => {
  toast('messageSent', false, false, 3000, dispatch);
  showSlowMessage = true;
  const slowTimeout = setTimeout(() => {
    if (showSlowMessage) toast('slowMessage', false, false, 3000, dispatch);
  }, 5000);

  if (realTimeConnection.virtual) {
    showSlowMessage = false;
    clearTimeout(slowTimeout);
    dispatch({ type: OPENLOCKER_SUCCESS, data: { index } });
  } else {
    realTimeConnection
      .sendCustom(
        'openBox',
        { index, user, contract, iloq, keyId },
        OPERATION_TIMEOUT
      )
      .then((res) => {
        console.log('openLocker', res);
        showSlowMessage = false;
        clearTimeout(slowTimeout);
        if (res.error) {
          throw res.error;
        }

        dispatch({ type: OPENLOCKER_SUCCESS, data: { index } });
      })
      .catch((err) => {
        console.log('openLocker err', err);
        const { error } = err;
        toast(
          error ? (error.message.length > 1 ? error.message : error) : err,
          true,
          false,
          5000,
          dispatch
        );
        dispatch({ type: OPENLOCKER_FAILED, data: err });
      });
  }
};
export const enterPincode = (realTimeConnection, pincode) => (dispatch) => {
  console.log('realTimeConnection:', realTimeConnection);
  console.log('enterPincode', pincode);
  toast('messageSent', false, false, 3000, dispatch);
  showSlowMessage = true;
  const slowTimeout = setTimeout(() => {
    if (showSlowMessage) toast('slowMessage', false, false, 3000, dispatch);
  }, 5000);

  if (realTimeConnection.virtual) {
    showSlowMessage = false;
    clearTimeout(slowTimeout);
    dispatch({ type: ENTERPINCODE_SUCCESS, data: { pincode } });
  } else {
    realTimeConnection
      .sendCustom('enterPincode', { pincode }, OPERATION_TIMEOUT)
      .then((res) => {
        console.log('enterPincode', res);
        showSlowMessage = false;
        clearTimeout(slowTimeout);
        if (res.error) {
          throw res.error;
        }

        dispatch({ type: ENTERPINCODE_SUCCESS, data: { pincode } });
      })
      .catch((err) => {
        console.log('enterPincode err', err);
        const { error } = err;
        toast(
          error ? (error.message.length > 1 ? error.message : error) : err,
          true,
          false,
          5000,
          dispatch
        );
        dispatch({ type: ENTERPINCODE_FAILED, data: err });
      });
  }
};

export const closeLocker = (realTimeConnection, index) => (dispatch) => {
  dispatch({ type: DEVICES_LOADING });

  console.log('closeLocker', index);

  realTimeConnection
    .sendCustom('closeBox', { index })
    .then((res) => {
      console.log('closeLocker', res);
      if (res.error) {
        throw res.error;
      }
      dispatch({ type: CLOSELOCKER_SUCCESS, data: { index } });
    })
    .catch((err) => {
      console.log('closeLocker err', err);
      dispatch({ type: CLOSELOCKER_FAILED, data: err });
    });
};

export const sendNumpadKey = (realTimeConnection, key) => (dispatch) => {
  console.log('sendNumpadKey', key);
  if (realTimeConnection.virtual) {
    dispatch({ type: SENDNUMPADKEY_SUCCESS, data: key });
  } else {
    realTimeConnection
      .sendCustom('sendKey', { key })
      .then((res) => {
        console.log('sendNumpadKey response', res);
        if (res.error) {
          throw res.error;
        }
        dispatch({ type: SENDNUMPADKEY_SUCCESS, data: key });
      })
      .catch((err) => {
        console.log('sendNumpadKey err', err);
        dispatch({ type: SENDNUMPADKEY_FAILED, data: err });
      });
  }
};

export const detectLocker = (realTimeConnection, index) => (dispatch) => {
  realTimeConnection
    .sendCustom('detectLocker', {})
    .then((res) => {
      console.log('detectLocker', res);
      if (res && res.error) {
        throw res.error;
      }
      dispatch({ type: DETECTLOCKER_SUCCESS, data: res });
    })
    .catch((err) => {
      console.log('detectLocker err', err);
      dispatch({ type: DETECTLOCKER_FAILED, data: err });
    });
};
export const updateKeyStatus = (realTimeConnection, index, user) => async (
  dispatch
) => {
  dispatch({ type: UPDATEKEYSTATUS_LOADING });
  realTimeConnection
    .sendCustom('updateKeyStatus', { index, user })
    .then((res) => {
      console.log('updateKeyStatus', res);
      if (res && res.error) {
        throw res.error;
      }
      setTimeout(
        () => dispatch({ type: UPDATEKEYSTATUS_SUCCESS, data: res }),
        45 * 1000
      );
    })
    .catch((err) => {
      console.log('updateKeyStatus err', err);
      dispatch({ type: UPDATEKEYSTATUS_FAILED, data: err });
    });
};

export const updateReferenceImage = (realTimeConnection, index) => async (
  dispatch
) => {
  dispatch({ type: UPDATEREFERENCEIMAGE_LOADING });
  realTimeConnection
    .sendCustom('updateReferenceImage', { index })
    .then((res) => {
      console.log('updateReferenceImage', res);
      if (res && res.error) {
        throw res.error;
      }
      setTimeout(
        () => dispatch({ type: UPDATEREFERENCEIMAGE_SUCCESS, data: res }),
        45 * 1000
      );
    })
    .catch((err) => {
      console.log('updateReferenceImage err', err);
      dispatch({ type: UPDATEREFERENCEIMAGE_FAILED, data: err });
    });
};

export const forceLocker = (realTimeConnection, index) => (dispatch) => {
  realTimeConnection
    .sendCustom('forceCurrentBox', { index })
    .then((res) => {
      console.log('forceCurrentBox', res);
      if (res && res.error) {
        throw res.error;
      }
      dispatch({ type: FORCELOCKER_SUCCESS });
    })
    .catch((err) => {
      console.log('forceCurrentBox err', err);
      dispatch({ type: FORCELOCKER_FAILED });
    });
};

export const doTemplate = (realTimeConnection, index) => (dispatch) => {
  realTimeConnection
    .sendCustom('doTemplate', {})
    .then((res) => {
      console.log('doTemplate', res);
      if (res && res.error) {
        throw res.error;
      }
      dispatch({ type: DOTEMPLATE_SUCCESS });
    })
    .catch((err) => {
      console.log('doTemplate err', err);
      dispatch({ type: DOTEMPLATE_FAILED });
    });
};

export const activateTestMode = (realTimeConnection, active) => (dispatch) => {
  console.log('activateTestMode', active);
  const method = active ? 'deactivateTestMode' : 'activateTestMode';
  //toast("messageSent", false, false, 3000, dispatch);
  realTimeConnection
    .sendCustom(method, {})
    .then((res) => {
      console.log(method, res);
      if (res.error) {
        throw res.error;
      }
      dispatch({ type: TESTMODE_SUCCESS });
    })
    .catch((err) => {
      console.log(method, 'err', err);
      dispatch({ type: TESTMODE_FAILED, data: err });
    });
};

export const activateKeyInsertMode = (realTimeConnection, active, user) => (
  dispatch
) => {
  console.log('activateKeyInsertMode', active);
  const method = active ? 'deactivateKeyInsertMode' : 'activateKeyInsertMode';
  //toast("messageSent", false, false, 3000, dispatch);
  realTimeConnection
    .sendCustom(method, { user })
    .then((res) => {
      console.log(method, res);
      if (res.error) {
        throw res.error;
      }
      dispatch({ type: INSERTMODE_SUCCESS });
    })
    .catch((err) => {
      console.log(method, 'err', err);
      dispatch({ type: INSERTMODE_FAILED, data: err });
    });
};

export const openLockerKeyInsertMode = (realTimeConnection, index) => (
  dispatch
) => {
  console.log('openLockerKeyInsertMode', index);
  toast('messageSent', false, false, 3000, dispatch);
  realTimeConnection
    .sendCustom('openBoxKeyInsertMode', { index }, OPERATION_TIMEOUT)
    .then((res) => {
      console.log('openLockerKeyInsertMode', res);
      if (res.error) {
        throw res.error;
      }
      dispatch({ type: OPENLOCKERKEYINSERTMODE_SUCCESS, data: { index } });
    })
    .catch((err) => {
      console.log('openLockerKeyInsertMode err', err);
      dispatch({ type: OPENLOCKERKEYINSERTMODE_FAILED, data: err });
    });
};

//Maintenance view

export const pingDevice = (realTimeConnection) => async (dispatch) => {
  if (maintenance) {
    const start = Date.now();
    let elapsed, end;
    try {
      await realTimeConnection.sendCustom('echo', {}, 2000);
      end = Date.now();
      elapsed = end - start;
      dispatch({ type: DEVICEPING_SUCCESS, data: elapsed });
      if (elapsed >= 2000) {
        dispatch(pingDevice(realTimeConnection));
      } else {
        await sleep(2000 - elapsed);
        dispatch(pingDevice(realTimeConnection));
      }
    } catch (err) {
      end = Date.now();
      elapsed = end - start;
      console.log('echo err', err);
      dispatch({
        type: DEVICEPING_FAILED,
        data: { pingTime: elapsed, err: err },
      });
      if (elapsed >= 1000) {
        dispatch(pingDevice(realTimeConnection));
      } else {
        await sleep(1000 - elapsed);
        dispatch(pingDevice(realTimeConnection));
      }
    }
  }
};

export const sendCommand = (command, realTimeConnection) => (dispatch) => {
  console.log('sendCommand', command);
  toast('messageSent', false, false, 3000, dispatch);
  realTimeConnection
    .sendCustom('atmelCommand', { command })
    .then((res) => {
      console.log('sendCommand response', res);
      if (res.error) {
        throw res.error;
      }
      dispatch({ type: SENDCOMMAND_SUCCESS, data: res });
    })
    .catch((err) => {
      console.log('sendCommand err', err);
      const { error } = err;
      toast(
        error ? (error.message.length > 1 ? error.message : error) : err,
        true,
        false,
        5000,
        dispatch
      );
      dispatch({ type: SENDCOMMAND_FAILED, data: err });
    });
};

export const activateTraces = (realTimeConnection) => async (dispatch) => {
  console.log('activateTraces');
  let labels;
  maintenance = true;
  try {
    labels = await realTimeConnection.sendCustom('debug-labels', {});
    dispatch({ type: TRACE_LABELS_FETCHED, data: labels });
    console.log('activateTraces - TRACE_LABELS_FETCHED', labels);
  } catch (e) {
    console.log('Using local labels', e);
    labels = debugLabels;
  }
  try {
    await realTimeConnection.sendCustom('subscribe', 'trace');
    dispatch({ type: TRACE_SUBSCRIBED });
    console.log('activateTraces - TRACE_SUBSCRIBED');
    await realTimeConnection.sendCustom('deactivate-trace', {
      identifiers: labels,
    });
  } catch (err) {
    console.log('activateTraces error', err);
    dispatch({
      type: DEVICE_ERROR,
      data: err,
    });
  }
};

export const deActivateTraces = (realTimeConnection, labels) => async (
  dispatch
) => {
  console.log('deactivating...');
  try {
    await realTimeConnection.sendCustom('deactivate-trace', {
      identifiers: labels,
    });
  } catch (err) {
    console.log('deActivateTraces error', err);
    dispatch({
      type: DEVICE_ERROR,
      data: err,
    });
  }
};

export const setLabels = (realTimeConnection, labels) => async (dispatch) => {
  try {
    await realTimeConnection.sendCustom('activate-trace', {
      identifiers: labels,
    });
    dispatch({ type: TRACE_LABELS_CHANGED, data: labels });
    console.log('setLabels - TRACE_LABELS_CHANGED', labels);
  } catch (err) {
    console.log('setLabels error', err);
    dispatch({
      type: DEVICE_ERROR,
      data: err,
    });
  }
};

export const clearTraces = () => (dispatch) => {
  console.log('clearing traces');
  dispatch({ type: TRACE_CLEARED });
};

export const unlock = (realTimeConnection, user) => (dispatch) => {
  console.log('realTimeConnection:', realTimeConnection);
  console.log('unlock');
  toast('messageSent', false, false, 3000, dispatch);
  showSlowMessage = true;
  const slowTimeout = setTimeout(() => {
    if (showSlowMessage) toast('slowMessage', false, false, 3000, dispatch);
  }, 5000);
  if (realTimeConnection.virtual) {
    showSlowMessage = false;
    clearTimeout(slowTimeout);
    dispatch({ type: UNLOCK_SUCCESS });
  } else {
    realTimeConnection
      .sendCustom('unlock', { user }, OPERATION_TIMEOUT)
      .then((res) => {
        console.log('unlock', res);
        showSlowMessage = false;
        clearTimeout(slowTimeout);
        if (res.error) {
          throw res.error;
        }

        dispatch({ type: UNLOCK_SUCCESS });
      })
      .catch((err) => {
        console.log('unlock err', err);
        const { error } = err;
        toast(
          error ? (error.message.length > 1 ? error.message : error) : err,
          true,
          false,
          5000,
          dispatch
        );
        dispatch({ type: UNLOCK_FAILED, data: err });
      });
  }
};

export const lock = (realTimeConnection, user) => (dispatch) => {
  console.log('realTimeConnection:', realTimeConnection);
  console.log('lock');
  toast('messageSent', false, false, 3000, dispatch);
  showSlowMessage = true;
  const slowTimeout = setTimeout(() => {
    if (showSlowMessage) toast('slowMessage', false, false, 3000, dispatch);
  }, 5000);
  realTimeConnection
    .sendCustom('lock', { user }, OPERATION_TIMEOUT)
    .then((res) => {
      console.log('lock', res);
      showSlowMessage = false;
      clearTimeout(slowTimeout);
      if (res.error) {
        throw res.error;
      }

      dispatch({ type: LOCK_SUCCESS });
    })
    .catch((err) => {
      console.log('lock err', err);
      const { error } = err;
      toast(
        error ? (error.message.length > 1 ? error.message : error) : err,
        true,
        false,
        5000,
        dispatch
      );
      dispatch({ type: LOCK_FAILED, data: err });
    });
};

export const resetError = () => (dispatch) => {
  console.log('Clearing error');
  dispatch({ type: CLEAR_ERROR });
};

export const getDevices = (client, products, tag) => async (dispatch) => {
  // console.log('getDevices');
  dispatch({ type: DEVICES_LOADING });
  try {
    const res = await client.query({
      query: gql`
        query devices($filter: DeviceFilter) {
          devices(limit: 0, filter: $filter) {
            edges {
              id
              name
              tag
              virtual
              activePublicAlarms {
                edges {
                  id
                }
              }
            }
          }
        }
      `,
      variables: {
        filter: {
          lifecycleState: 'active',
          product: products
            ? JSON.stringify({ $in: products })
            : JSON.stringify({ $in: ['key30', 'keyX'] }),
          tagExact: tag ? decodeURIComponent(tag) : null,
        },
        sort: { name: 1 },
      },
    });
    console.log('getDevices - DEVICES_FETCHED', res);
    dispatch({
      type: DEVICES_FETCHED,
      //TODO: Remove filtering when device is returned
      data: res.data.devices.edges,
    });
  } catch (err) {
    dispatch({ type: DEVICES_FAILED, data: err.message });
    throw err;
  }
};
export const getLocations = (client, products) => async (dispatch) => {
  // console.log('getDevices');
  dispatch({ type: LOCATIONS_LOADING });
  try {
    const res = await client.query({
      query: gql`
        query locations($lifecycleState: String, $products: [String]) {
          locations(lifecycleState: $lifecycleState, products: $products) {
            tag
            name
            count
            devices
          }
        }
      `,
      variables: { lifecycleState: 'active', products },
    });

    dispatch({
      type: LOCATIONS_FETCHED,
      data: res.data.locations,
    });
  } catch (err) {
    dispatch({ type: LOCATIONS_FAILED, data: err.message });
    throw err;
  }
};

export const getDevice = (client, id) => async (dispatch) => {
  dispatch({ type: DEVICES_LOADING });
  try {
    const res = await client.query({
      query: gql`
        query device($id: String!) {
          device(id: $id) {
            id
            name
            tag
            product
            virtual
            socketServer
            settings {
              data
              sensors
            }
            meta
            synced
            connectionState
            lifecycleState
            syncGroup {
              id
              devices {
                id
                name
                product
                serial
                relays {
                  id
                  name
                }
                type
              }
              config {
                keypadStartEarlyAccess
                keypadEndLateAccess
              }
            }
            alarmReceivers {
              mail
              sms
              language
              activation
              deactivation
              rules {
                id
                sendEmail
                sendSms
              }
            }
            notificationReceivers {
              mail
              sms
              language
              activation
              deactivation
              rules {
                id
                sendEmail
                sendSms
              }
            }
            activePublicAlarms {
              edges {
                id
              }
              pageInfo {
                end
              }
            }
            hasReservationSupport
            hasReservationSyncSupport
            hasSmartLockerSupport
            supportedLanguages
            anonymizationSettings
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: { id },
    });
    //console.log('device fecthed', res);
    let device = cloneDeep(res.data.device);
    if (device) {
      device.settings.data = convertContractsToDeviceLocalTime(
        device.settings.data
      );
      if (_get(device, 'settings.data.connect1', false)) {
        window.location.replace(
          `https://connect1.livionkey.com/dashboard/${device.id}`
        );
      }
      dispatch({
        type: DEVICE_FEATURES_FOUND,
        data: {
          lockerCount: _get(device, 'settings.data.features.lockerCount', 30),
          frontCamera: _get(device, 'settings.data.features.frontCamera', true),
          lockerCamera: _get(
            device,
            'settings.data.features.lockerCamera',
            true
          ),
          speaker: _get(device, 'settings.data.features.speaker', true),
        },
      });
      dispatch({ type: DEVICE_FETCHED, data: device });
      return device;
    }
    dispatch({ type: DEVICES_FAILED, data: 'Device not found' });

    //if (!noRealTimeConnection) connect(res.data.device, dispatch);
  } catch (err) {
    console.log('GET_DEVICE error: ', err);
    dispatch({ type: DEVICES_FAILED, data: err.message });
    throw err;
  }
};
export const updateDeviceSettings = (client, id, data) => async (dispatch) => {
  dispatch({ type: DEVICES_SAVING });
  try {
    const res = await client.mutate({
      mutation: gql`
        mutation updateDeviceSettings(
          $id: String!
          $input: JSON!
          $partialUpdate: Boolean
        ) {
          updateDeviceSettings(
            id: $id
            input: $input
            partialUpdate: $partialUpdate
          ) {
            id
            name
            tag
            product
            virtual
            socketServer
            meta
            settings {
              data
            }
            connectionState
            lifecycleState
            alarmReceivers {
              mail
              sms
              language
              activation
              deactivation
              rules {
                id
                sendEmail
                sendSms
              }
            }
          }
        }
      `,
      variables: {
        id,
        input: data,
        partialUpdate: true,
      },
    });
    dispatch({ type: DEVICES_SAVED, data: res.data });
  } catch (err) {
    dispatch({ type: DEVICES_FAILED, data: err.message });
    throw err;
  }
};
export const saveDeviceAndSettings = (
  client,
  id,
  deviceUpdate,
  settingsUpdate
) => async (dispatch) => {
  dispatch({ type: DEVICES_SAVING });
  try {
    const res = await client.mutate({
      mutation: gql`
        mutation updateDeviceAndSettings(
          $id: String!
          $deviceUpdate: DeviceInput!
          $settingsUpdate: JSON!
        ) {
          updateDevice(id: $id, input: $deviceUpdate) {
            id
            name
            tag
            product
            virtual
            socketServer
            meta
            settings {
              data
            }
            connectionState
            lifecycleState
            alarmReceivers {
              mail
              sms
              language
              activation
              deactivation
              rules {
                id
                sendEmail
                sendSms
              }
            }
            notificationReceivers {
              mail
              sms
              language
              activation
              deactivation
              rules {
                id
                sendEmail
                sendSms
              }
            }
          }
          updateDeviceSettings(id: $id, input: $settingsUpdate) {
            id
            name
            tag
            product
            virtual
            socketServer
            meta
            settings {
              data
            }
            connectionState
            lifecycleState
            alarmReceivers {
              mail
              sms
              language
              activation
              deactivation
              rules {
                id
                sendEmail
                sendSms
              }
            }
            notificationReceivers {
              mail
              sms
              language
              activation
              deactivation
              rules {
                id
                sendEmail
                sendSms
              }
            }
          }
        }
      `,
      variables: {
        id,
        deviceUpdate,
        settingsUpdate,
      },
    });
    dispatch({ type: DEVICES_SAVED, data: res.data });
  } catch (err) {
    dispatch({ type: DEVICES_FAILED, data: err.message });
    throw err;
  }
};

export const saveDevice = (client, id, input) => async (dispatch) => {
  console.log('saving device', id, input);
  dispatch({ type: DEVICES_SAVING });
  try {
    const res = await client.mutate({
      mutation: gql`
        mutation updateDevice($id: String!, $input: DeviceInput!) {
          updateDevice(id: $id, input: $input) {
            id
            name
            tag
            socketServer
            meta
            virtual
            settings {
              data
            }
            connectionState
            lifecycleState
            alarmReceivers {
              mail
              sms
              language
              activation
              deactivation
              rules {
                id
                sendEmail
                sendSms
              }
            }
            notificationReceivers {
              mail
              sms
              language
              activation
              deactivation
              rules {
                id
                sendEmail
                sendSms
              }
            }
          }
        }
      `,
      variables: { id, input },
    });
    dispatch({ type: DEVICES_SAVED, data: res.data.updateDevice });
  } catch (err) {
    dispatch({ type: DEVICES_FAILED, data: err.message });
    throw err;
  }
};

export const getDeviceTag = (client, tag) => async (dispatch) => {
  console.log('getting device tag', tag);
  tag = encodeURIComponent(tag);
  dispatch({ type: DEVICES_LOADING });
  try {
    const res = await client.query({
      query: gql`
        query tag($id: String!) {
          tag(id: $id) {
            id
            name
            meta
            type
            alarmReceivers {
              mail
              sms
              language
              activation
              deactivation
              rules {
                id
                sendEmail
                sendSms
              }
            }
            notificationReceivers {
              mail
              sms
              language
              activation
              deactivation
              rules {
                id
                sendEmail
                sendSms
              }
            }
          }
        }
      `,
      variables: { id: tag },
    });

    dispatch({ type: DEVICES_TAG_FETCHED, data: res.data.tag });
    return res.data.tag;
  } catch (err) {
    dispatch({ type: DEVICES_FAILED, data: err.message });
    throw err;
  }
};

export const getRules = (client) => async (dispatch) => {
  dispatch({ type: DEVICES_LOADING });
  console.log('getRules - getting rules');
  try {
    const res = await client.query({
      query: gql`
        query alarmRules(
          $after: String
          $limit: Int
          $filter: AlarmRuleFilter
          $sort: JSON
        ) {
          alarmRules(
            after: $after
            limit: $limit
            filter: $filter
            sort: $sort
          ) {
            edges {
              id
              alarmName
            }
            pageInfo {
              start
              end
            }
          }
        }
      `,
      variables: { limit: 0 },
    });
    console.log('getRules - rules fetched');
    dispatch({
      type: DEVICES_RULES_FETCHED,
      //TODO: Remove filtering when device is returned
      data: res.data.alarmRules.edges,
    });

    return res.data.alarmRules.edges;
  } catch (err) {
    dispatch({ type: DEVICES_FAILED, data: err.message });
    throw err;
  }
};

export const rebootDevice = (realTimeConnection, user) => async (dispatch) => {
  console.log('rebooting device...');
  toast('messageSent', false, false, 3000, dispatch);
  showSlowMessage = true;
  const slowTimeout = setTimeout(() => {
    if (showSlowMessage) toast('slowMessage', false, false, 3000, dispatch);
  }, 5000);
  try {
    if (realTimeConnection.virtual) {
      showSlowMessage = false;
      clearTimeout(slowTimeout);

      dispatch({ type: REBOOT_SUCCESS });
    } else {
      realTimeConnection
        .sendCustom('rebootDevice', { user }, OPERATION_TIMEOUT)
        .then((res) => {
          console.log('reboot', res);
          showSlowMessage = false;
          clearTimeout(slowTimeout);
          if (res.error) {
            throw res.error;
          }

          dispatch({ type: REBOOT_SUCCESS });
        })
        .catch((err) => {
          console.log('rebootDevice err', err);

          toast((err && err.message) || 'Error', true, false, 5000, dispatch);
          dispatch({ type: REBOOT_FAILED, data: err });
        });
    }
  } catch (err) {
    console.log('rebootDevice err', err);
    showSlowMessage = false;
    clearTimeout(slowTimeout);
    toast((err && err.message) || 'Error', true, false, 5000, dispatch);
    dispatch({ type: REBOOT_FAILED, data: err });
  }
};
const initialState = {
  devices: [],
  locations: [],
  loading: null,
  loadingLockerStatus: null,
  status: '',
  error: null,
  saving: false,
  deviceTag: {},
  tagMeta: null,
  device: {},
  realTimeConnection: null,
  connecting: false,
  connected: false,
  showToast: false,
  toastMessage: '',
  toastError: false,
  toastWarning: false,
  deviceError: null,
  apiError: null,
  locker: 0,
  targetLocker: 0,
  testMode: 0,
  insertMode: 0,
  lockerStatus: {},
  pingTime: 0,
  traces: '',
  labels: [],
  loadingPhoto: false,
  updatedKeys: [],
  skipSetup: false,
  rules: [],
  locked: true,
  rssi: null,
  wifiStatus: null,
  lockerCount: 30,
  frontCamera: true,
  lockerCamera: true,
  speaker: true,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case DEVICES_LOADING:
      return {
        ...state,
        loading: true,
      };
    case DEVICES_SAVING:
      return {
        ...state,
        saving: true,
      };
    case DEVICES_SAVED:
      return {
        ...state,
        saving: false,
      };
    case STATUS_CHANGED:
      if (action.data.status === 'codeTimeout') {
        delete state.keypad;
      }
      const isIdle =
        action.data.status && action.data.status === 'idle'
          ? null
          : state.deviceError;
      return {
        ...state,
        deviceError: isIdle,
        ...action.data,
        loading: false,
      };

    case STATUS_FETCHED:
      return {
        ...state,
        updatedKeys: action.data,
        status: '',
      };
    case STATUS_FAILED:
      return {
        ...state,
        error: action.data,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        apiError: null,
      };
    case DEVICE_RESET:
      maintenance = false;
      return {
        ...state,
        connecting: false,
        connected: false,
        loading: false,
        status: null,
        error: null,
        saving: false,
        deviceTag: {},
        device: {},
        devices: [],
        showToast: false,
        toastMessage: '',
        toastError: false,
        toastWarning: false,
        deviceError: null,
        apiError: null,
        locker: 0,
        targetLocker: 0,
        testMode: 0,
        insertMode: 0,
        lockerStatus: {},
        pingTime: 0,
        traces: '',
        labels: [],
        rssi: null,
        wifiStatus: null,
        locked: true,
        realTimeConnection: null,
        lockerCount: 0,
        frontCamera: false,
        lockerCamera: false,
        speaker: false,
      };
    case DEVICES_RESET:
      maintenance = false;
      return {
        ...state,
        loading: false,
        status: null,
        error: null,
        devices: [],
        showToast: false,
        toastMessage: '',
        toastError: false,
        toastWarning: false,
        deviceError: null,
        apiError: null,
        connecting: false,
        connected: false,
      };
    case DEVICES_FETCHED:
      return {
        ...state,
        devices: action.data,
        loading: false,
      };
    case DEVICE_FETCHED:
      return {
        ...state,
        device: action.data,
        loading: false,
      };
    case DEVICE_FEATURES_FOUND:
      return {
        ...state,

        lockerCount: action.data.lockerCount,
        frontCamera: action.data.frontCamera,
        lockerCamera: action.data.lockerCamera,
        speaker: action.data.speaker,
      };
    case DEVICES_RULES_FETCHED:
      return {
        ...state,
        loading: false,
        rules: action.data,
      };
    case DEVICES_FAILED:
      return {
        ...state,
        error: action.data,
        loading: false,
        saving: false,
      };
    case DEVICE_CONNECTING:
      return {
        ...state,
        error: null,
        connecting: true,
        status: 'connecting',
      };
    case DEVICE_CONNECTED:
      return {
        ...state,
        error: null,
        connected: true,
        status: 'connected',
        realTimeConnection: action.data,
        connecting: false,
      };
    case DEVICE_DISCONNECTING:
      return {
        ...state,
        error: null,
        connecting: true,
        status: 'disconnecting',
      };
    case DEVICE_DISCONNECTED:
      return {
        ...state,
        error: null,
        connected: false,
        status: 'disconnected',
        realTimeConnection: null,
        connecting: false,
        rssi: null,
        wifiStatus: null,
      };
    case DEVICE_CONNECTION_CHANGED:
      return {
        ...state,
        error: null,
        connected: action.data,
        status: action.data ? 'connected' : 'disconnected',
        connecting: false,
        rssi: action.data && 'disconnected' && null,
        wifiStatus: action.data && 'disconnected' && null,
      };
    case DEVICE_ERROR:
      return {
        ...state,
        deviceError:
          action.data.message ||
          (action.data.error && action.data.error.message) ||
          action.data.error ||
          action.data,
        connecting: false,
        loading: false,
        loadingPhoto: false,
      };
    case DEVICE_DESTROY:
      return {
        ...state,
      };
    case DEVICES_TAG_FETCHED:
      return {
        ...state,
        deviceTag: action.data,
        loading: false,
      };
    case TOAST_SHOW:
      return {
        ...state,
        showToast: true,
        toastMessage: action.data.message,
        toastError: action.data.error,
        toastWarning: action.data.warning,
      };
    case TOAST_HIDE:
      return {
        ...state,
        showToast: false,
        toastMessage: '',
        toastError: false,
        toastWarning: false,
      };
    case RESETPHOTO:
      delete state.src;
      return {
        ...state,
        loadingPhoto: false,
      };
    case DEVICEPING_SUCCESS:
      return {
        ...state,
        pingTime: action.data,
      };
    case DEVICEPING_FAILED:
      return {
        ...state,
        pingTime: action.data.pingTime,
        deviceError: 'Connection timeout',
      };
    case TRACE_CHANGED:
      let newTraces = state.traces;
      newTraces = newTraces.concat(
        `[${moment(action.data.timestamp).format('hh:mm:ss:SSS')}] ${
          action.data.identifier
        } \t ${action.data.trace} \t \n`
      );

      return {
        ...state,
        traces: newTraces,
      };
    case TRACE_LABELS_FETCHED:
      let labelsForSelect = [];
      const labels = action.data;
      labels.forEach((l) => labelsForSelect.push({ value: l, label: l }));
      return {
        ...state,
        labels: labelsForSelect,
      };

    case TRACE_CLEARED:
      return {
        ...state,
        traces: '',
      };
    case CLOSELOCKER_SUCCESS:
    case OPENLOCKER_SUCCESS:
      return {
        ...state,
        deviceError: null,
        opening: false,
        loading: false,
      };

    case LOCK_SUCCESS:
      return {
        ...state,
        deviceError: null,
        loading: false,
        locked: true,
      };
    case UNLOCK_SUCCESS:
      return {
        ...state,
        deviceError: null,
        loading: false,
        locked: false,
      };
    case ENTERPINCODE_SUCCESS:
      return {
        ...state,
        deviceError: null,
        loading: false,
      };
    case SENDNUMPADKEY_SUCCESS:
      let keypad = state.keypad || '';
      if (state.keypad && state.keypad.indexOf('#') > -1)
        keypad = '' + action.data;
      else keypad = keypad + action.data;
      return {
        ...state,
        keypad,
        deviceError: null,
        loading: false,
      };

    case LOCK_FAILED:
      const s = {
        ...state,
        deviceError:
          action.data.message ||
          (action.data.error && action.data.error.message) ||
          action.data.error ||
          action.data,
        loading: false,
        saving: false,
        savingReservation: false,
        locked: false,
      };

      if (
        action.data &&
        (action.data.message === 'Device busy' || action.data === 'Device busy')
      ) {
        delete s.error;
        s.status = 'busy';
      }

      return s;
    case UNLOCK_FAILED:
      const st = {
        ...state,
        deviceError:
          action.data.message ||
          (action.data.error && action.data.error.message) ||
          action.data.error ||
          action.data,
        loading: false,
        saving: false,
        savingReservation: false,
        locked: true,
      };

      if (
        action.data &&
        (action.data.message === 'Device busy' || action.data === 'Device busy')
      ) {
        delete st.error;
        st.status = 'busy';
      }

      return st;
    case REBOOT_FAILED:
      const sta = {
        ...state,
        deviceError:
          action.data.message ||
          (action.data.error && action.data.error.message) ||
          action.data.error ||
          action.data,
        loading: false,
        saving: false,
        savingReservation: false,
        locked: true,
      };

      if (
        action.data &&
        (action.data.message === 'Device busy' || action.data === 'Device busy')
      ) {
        delete sta.error;
        sta.status = 'busy';
      }

      return sta;
    case DETECTLOCKER_FAILED:
    case UPDATEKEYSTATUS_FAILED:
    case UPDATEREFERENCEIMAGE_FAILED:
    case SENDNUMPADKEY_FAILED:
    case CLOSELOCKER_FAILED:
    case OPENLOCKER_FAILED:
    case PHOTO_FAILED:
    case TESTMODE_FAILED:
    case OPENLOCKERKEYINSERTMODE_FAILED:
    case INSERTMODE_FAILED:
    case ENTERPINCODE_FAILED:
      const o = {
        ...state,
        deviceError:
          action.data.message ||
          (action.data.error && action.data.error.message) ||
          action.data.error ||
          action.data,
        loading: false,
        loadingPhoto: false,
        connecting: false,
        saving: false,
        savingReservation: false,
      };

      if (
        action.data &&
        (action.data.message === 'Device busy' || action.data === 'Device busy')
      ) {
        delete o.error;
        o.status = 'busy';
      }

      return o;
    case PHOTO_LOADING:
      return {
        ...state,
        loadingPhoto: true,
      };
    case PHOTO_FETCHED:
      return {
        ...state,
        src: action.data.src,
        detectedLocker: null,
        deviceError: null,
        loadingPhoto: false,
      };

    case DETECTLOCKER_SUCCESS:
      return {
        ...state,
        detectedLocker: action.data,
        loading: false,
      };
    case UPDATEKEYSTATUS_LOADING:
      return {
        ...state,
        loadingLockerStatus: true,
      };
    case UPDATEKEYSTATUS_SUCCESS:
      return {
        ...state,
        updatedKeyStatus: action.data,
        loading: false,
        loadingLockerStatus: false,
      };
    case UPDATEREFERENCEIMAGE_LOADING:
      return {
        ...state,
      };
    case UPDATEREFERENCEIMAGE_SUCCESS:
      return {
        ...state,
      };

    case TESTMODE_SUCCESS:
    case OPENLOCKERKEYINSERTMODE_SUCCESS:
    case INSERTMODE_SUCCESS:
      return {
        ...state,
        deviceError: null,
        apiError: null,
        filtering: true,
      };

    case DEVICE_SKIP_SETUP:
      return {
        ...state,
        skipSetup: true,
      };
    case DEVICE_RESET_SETUP:
      return {
        ...state,
        skipSetup: false,
      };

    case LOCATIONS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case LOCATIONS_FETCHED:
      return {
        ...state,
        locations: action.data,
        loading: false,
      };
    case LOCATIONS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.data,
      };
    default:
      return state;
  }
}
