// Import FirebaseAuth and firebase.
import firebase from '../contexts/FirebaseContext/firebase';
// These imports load individual services into the firebase namespace.

import { xAuthMode } from './AppRunMode';

import { EventEmitter } from 'events';
import { getAccessToken, setTokens, hasTokens } from './localAuthTokens';

import jwt_decode from 'jwt-decode';

const fbase = firebase;

export default fbase;

const emitter = new EventEmitter();

// TODO: We need to listen both in order to support google login with local email/pw auth
const onAuthStateChanged = (cb: any) => {
  if (xAuthMode === 'firebaseAuthOnly') {
    return fbase.auth().onAuthStateChanged(cb);
  } else {
    emitter.on('authStateChanged', cb);
    // at init, we check what is status
    getAccessToken()
      .then((idToken) => {
        if (!idToken) cb(null);
        else {
          const decoded: any = jwt_decode(idToken);
          const uid = decoded.userId;
          // call with some user data or is that needed
          cb({ uid });
        }
      })
      .catch((e) => {
        console.log(e);
        cb(null);
      });

    return () => {
      emitter.removeListener('authStateChanged', cb);
    };
  }
};

const signOut = async (): Promise<void> => {
  if (xAuthMode === 'firebaseAuthOnly') {
    return fbase.auth().signOut();
  } else {
    // TODO call api, do not care result because we can always singout from UI
    setTokens(undefined);
    emitter.emit('authStateChanged', null);
    return;
  }
};

export const getIdToken = async (
  reload: boolean
): Promise<string | undefined> => {
  // Even though we automode is localAuth, user still might have login with google -> need to use that
  if (xAuthMode === 'firebaseAuthOnly') {
    const currentUser = fbase.auth().currentUser;
    return currentUser ? currentUser.getIdToken(reload) : undefined;
  } else {
    const idToken = await getAccessToken();
    // we are not getting idToken, either it does not exist or refresh token is expired -> emitting authStateChanged with null will logout user
    if (!idToken) {
      emitter.emit('authStateChanged', null);
    }
    return idToken;
  }
};

const isAuthenticated = (): boolean => {
  if (xAuthMode === 'firebaseAuthOnly') {
    return !!fbase.auth().currentUser;
  } else {
    return hasTokens();
  }
};

export const getCurrentUser = async (): Promise<any> => {
  // Even though we automode is localAuth, user still might have login with google -> need to use that
  if (xAuthMode === 'firebaseAuthOnly') {
    return fbase.auth().currentUser;
  } else {
    const idToken = await getAccessToken();
    let uid: string;
    if (idToken) {
      const decoded: any = jwt_decode(idToken);
      uid = decoded.userId;
    }
    return { uid: uid };
  }
};

export const authLib = {
  onAuthStateChanged,
  signOut,
  getIdToken,
  getCurrentUser,
  isAuthenticated,
};
