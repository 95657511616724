import React, { Component } from 'react';
import AuthContext from './auth-context';
import { authLib } from '../../connect-react-lib/authentication';
import { getSelectedTag, logout, getUserRole } from '../../connect-react-lib';
import { GET_TAG } from '../../../api/queries/tags';
import { getLanguageForCommonApps } from '../../utils';

const extractTags = async (client, tag) => {
  try {
    const res = await client.query({
      query: GET_TAG,
      variables: { id: tag },
    });
    if (!res.data || !res.data.tag) return {};
    const tagData = res?.data?.tag || {};
    const tagParents = tagData.tagParents;

    let organization, country, area, property;
    // eslint-disable-next-line array-callback-return
    Object.entries(tagParents).map(([id, tagObj]) => {
      tagObj.id = id; // add id to tag object.
      // eslint-disable-next-line default-case
      switch (tagObj.type) {
        case 'organization':
          if (!organization) organization = tagObj; // only set organization if not already set. handles the case of multiple organizations.
          break;
        case 'country':
          country = tagObj;
          break;
        case 'area':
          area = tagObj;
          break;
        case 'property':
          property = tagObj;
          break;
      }
    });
    return { organization, country, area, property, selected: tagData };
  } catch (e) {
    if (e.message && e.message === 'GraphQL error: not authorized') {
      await logout();
      return {};
    } else {
      console.error(e);
    }
  }
};

const componentFn = (
  client,
  condition,
  loginRoute,
  selectOrganizationRoute,
  roles
) => (WrappedComponent) =>
  class WithAuth extends Component {
    constructor(props) {
      super(props);
      this.state = {
        tags: {
          organization: null,
          country: null,
          area: null,
          selected: null,
        },
      };
    }

    componentDidMount() {
      if (condition) {
        this.unsubscibe = authLib.onAuthStateChanged(async (authUser) => {
          const selectedTag = getSelectedTag();
          if (!condition(authUser)) {
            if (process.env.REACT_APP_COMMONAPPS_IN_USE === 'true') {
              console.log('going to apps', authUser);
              window.location.href = `/apps/`;
            } else this.props.history.push(loginRoute);
          } else {
            const userRole = await getUserRole(client, authUser);
            const aclRole =
              userRole && userRole.acl.find((a) => a.path === selectedTag);
            if (roles && !roles.includes(aclRole.role)) {
              console.log(
                'User role do not allow to acces this page',
                roles,
                userRole
              );
              this.props.history.push('/');
            } else {
              let tags;
              if (selectedTag) {
                tags = await extractTags(client, selectedTag);
                console.log('EXTRACTED TAGS', tags);
                this.setState({ tags });
              }
              const validTag = tags && tags.organization ? true : false;
              if (
                selectOrganizationRoute &&
                !validTag &&
                selectOrganizationRoute !== this.props.history.location.path
              ) {
                console.log(
                  'no valid organisation, forwarding to /selectorganization'
                );
                if (process.env.REACT_APP_COMMONAPPS_IN_USE === 'true') {
                  localStorage.removeItem('selectedOrganizationUnit');
                  localStorage.removeItem('isAdminMode');

                  const lng = getLanguageForCommonApps(this.props.i18n);
                  window.location.href = `/apps/${lng}/auth/select-organization`;
                } else {
                  this.props.history.push(selectOrganizationRoute);
                }
              }
            }
          }
        });
      }
    }
    componentWillUnmount() {
      if (this.unsubscibe) this.unsubscibe();
    }
    render() {
      return (
        <AuthContext.Consumer>
          {(value) =>
            value.ready ? (
              <WrappedComponent
                auth={value}
                {...this.props}
                tagObjects={this.state.tags}
                extractTags={extractTags}
              />
            ) : null
          }
        </AuthContext.Consumer>
      );
    }
  };

export const withRouteAuth = componentFn;

export const withAuth = (c) => componentFn(null, '')(c);
