import gql from 'graphql-tag';
const USERS_FETCHED = 'users/FETCHED';

const USERS_LOADING = 'users/LOADING';
const USERS_FAILED = 'users/FAILED';

export const getUsers = (client) => async (dispatch) => {
  dispatch({ type: USERS_LOADING });
  //Mocked Data
  try {
    const res = await client.query({
      query: gql`
        query users(
          $after: String
          $limit: Int
          $filter: UserFilter
          $sort: JSON
        ) {
          users(after: $after, limit: $limit, filter: $filter, sort: $sort) {
            edges {
              id
              email
              displayName
              language
              phoneNumber
              role
              acl {
                role
                path
                name
              }
            }
          }
        }
      `,
      fetchPolicy: 'network-only',
      variables: { limit: 0 },
    });
    dispatch({
      type: USERS_FETCHED,
      data: res.data.users.edges.filter((user) => user.acl),
    });
    return res.data.users.edges.filter((user) => user.acl);
  } catch (err) {
    dispatch({ type: USERS_FAILED, data: err.message });
    throw err;
  }
};

const initialState = {
  users: [],
  loading: false,
  error: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case USERS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case USERS_FETCHED:
      return {
        ...state,
        users: action.data,
        loading: false,
      };
    case USERS_FAILED:
      return {
        ...state,
        error: action.data,
        loading: false,
        saving: false,
      };
    default:
      return state;
  }
}
